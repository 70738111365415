import React, {useState} from 'react';
import {connect} from "react-redux";

import DeleteUserView from "./DeleteUserView";
import store from "../../store";
import {showToastMessage} from "../../appComponents/AppActions";
import {deleteUserData} from "../UserManagementAction";

const DeleteUser = (props) => {
    const [deleteUserState, setDeleteUserState] = useState({
        isShowLoader: false,
        responseError: '',
    });

    const {isCreateDeleteOpen, handleCloseDeleteModel, userDeletedModel} = props;


    const deleteUser = () => {
        const {refreshFullScreen, modelData} = props;
        if (!modelData){
            return
        }

        let id = modelData?.userId;
        setDeleteUserState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        deleteUserData(id, true)
            .then(res => {
                if (res?.success) {
                    setDeleteUserState(prevState => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    store.dispatch(showToastMessage('success', `User ${modelData?.userName} removed successfully`));
                    refreshFullScreen("isDeleteModalOpen")
                } else {
                    setDeleteUserState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseError: res?.__error
                    }));
                }
            })
    };

    return (
        <DeleteUserView
            {...props}
            {...deleteUserState}
            handleCloseDeleteModel={handleCloseDeleteModel}
            isCreateDeleteOpen={isCreateDeleteOpen}
            userDeletedModel={userDeletedModel}
            deleteUser={deleteUser}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(DeleteUser);
