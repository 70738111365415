import React from 'react';

import DLModalTitle from "../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModal from "../../../../core/components/DLModal/DLModal";
import DLModalContainer from "../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../../core/components/DLInputTextField/DLInputTextField";

const QASamplingModelView = (props) => {

    const {
        handleCloseModal,
        isQASamplingModalOpen,
        onQASamplingSave,
        onSelectThresholdValue,
        selectedLowerThreshold,
        selectedUpperThreshold
    } = props;

    const renderQASamplingModal = () => {
        return (
            <div>
                <DLModal
                    history={props.history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseModal('isQASamplingModalOpen')}
                    open={isQASamplingModalOpen}
                    id={'qa-sampling-modal'}>
                    <DLModalTitle
                        id={'qa-sampling-title'}
                        onClose={() =>  handleCloseModal('isQASamplingModalOpen')}
                        title={'QA Sampling %'}/>
                    <DLModalContainer
                        id={'qa-sampling-container'}>
                        <div>
                            <div className='mt-4 w-50' style={{minHeight: '100px'}}>

                                <DLInputTextField
                                    id={'lower-threshold-input'}
                                    label={'Lower Threshold (in %)'}
                                    labelPlacement={'top'}
                                    marginBottom={'none'}
                                    value={selectedLowerThreshold}
                                    isSearchable={false}
                                    onChangeValue={(thresholdValue) => onSelectThresholdValue('lowerThreshold', thresholdValue)}
                                    helperMessage={'Input value between 0-100'}
                                    placeholder={'Enter lower threshold value'}
                                    isRequired={true}
                                    disableHelperMessageHeight={true}
                                    isClearable={true}
                                />

                            </div>
                            <div className='mt-2 w-50' style={{minHeight: '100px'}}>

                                <DLInputTextField
                                    id={'upper-threshold-input'}
                                    label={'Upper Threshold (in %)'}
                                    labelPlacement={'top'}
                                    marginBottom={'none'}
                                    value={selectedUpperThreshold}
                                    isSearchable={false}
                                    onChangeValue={(thresholdValue) => onSelectThresholdValue('upperThreshold' , thresholdValue)}
                                    placeholder={'Enter upper threshold value'}
                                    helperMessage={'Input value between 0-100'}
                                    isRequired={true}
                                    disableHelperMessageHeight={true}
                                    isClearable={true}
                                />

                            </div>
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'qa-sampling-action'}>
                        <div className='d-flex gap-3 mt-3 mb-3'>
                            <DLButton
                                id={'qa-sampling-cancelBtn'}
                                history={props.history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"none"}
                                onClick={() =>  handleCloseModal('isQASamplingModalOpen')}/>
                            <DLButton
                                id={'qa-sampling-saveBtn'}
                                history={props.history}
                                label={'Save'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"none"}
                                onClick={() => onQASamplingSave()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    return (
        <div>
            {renderQASamplingModal()}
        </div>
    );
};

export default QASamplingModelView;
