export const userConst = {
    AUTH_STATUS_UNAUTHORIZED: 'AUTH_STATUS_UNAUTHORIZED',
    AUTH_STATUS_AUTHORIZED: 'AUTH_STATUS_AUTHORIZED',
    AUTH_STATUS_LOGGED_OUT: 'AUTH_STATUS_LOGGED_OUT',
    ACCESS_DENIED: 'ACCESS_DENIED',
};

export const loginConst = {
    LOGIN: 'LOGIN',
    USER_NOT_EXIST: 'USER_NOT_EXIST',
    LOGIN_FAILED: 'LOGIN_FAILED',
};

export const resetPasswordConst = {
    NEW_PASSWORD: 'NEW_PASSWORD',
    RESET_LINK_EXPIRED: 'RESET_LINK_EXPIRED',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILED:'RESET_PASSWORD_FAILED'
};
