import React from "react";

import {isEmpty} from "../../../../utils/validations";

import DLText from "../../../../core/components/DLText/DLText";
import DLButton from "../../../../core/components/DLButton/DLButton";
import appTheme from "../../../../assets/appTheme";
import AddError from "./addErrorModel/AddError";
import {capitalizeString} from "../../../../utils/helper";

const ProductListView = (props) => {
    const {openModel, productDetails, getColor} = props;

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between align-items-center px-3 pt-2'>
                <div>
                    <DLText id={'records-count'}
                            fontColor={'primary'}
                            fontSize={'md'}
                            marginBottom={'none'}
                            fontWeight={'semi-bold'}
                            text={'Level 3 Error Categories'}/>
                </div>
                <div>
                    <DLButton
                        id={'assign-assign-btn'}
                        label={'Add New Error'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        sideMargin={"none"}
                        onClick={() => openModel("openErrorModel", {...productDetails, productId: productDetails?.id})}
                    />
                </div>
            </div>
        )
    };

    const renderListing = () => {
        const {productsErrorDetails, listingCount} = props;

        return productsErrorDetails.map((item, index) => {
            return (
                <div className='py-2'
                     key={'error'+index}
                     style={{borderBottom: (index === listingCount - 1) ? "" : '1px solid ' + appTheme.appColor.tableBorder
                }}>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='px-3'>
                                <DLText id={'records-count'}
                                        fontSize={'xs'}
                                        marginBottom={'none'}
                                        text={item.reason}/>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='px-3'>
                                <ul className='unorderedList-class m-0' style={{paddingLeft: 15, color: getColor(item?.category)}}>
                                    <li>
                                        <DLText id={'records-count'}
                                                fontSize={'xs'}
                                                marginBottom={'none'}
                                                text={capitalizeString(item?.category)}/>

                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-3 text-center'>
                            <DLText id={'records-count'}
                                    fontSize={'xs'}
                                    isClickable={true}
                                    onClick={() => openModel("openErrorModel", {...item, edit: true})}
                                    marginBottom={'none'}
                                    text={'Edit'}/>
                        </div>
                    </div>
                </div>
            )
        })


    };

    const renderTable = () => {
        const {productsErrorDetails} = props;

        if (isEmpty(productsErrorDetails)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center pt-2'
                     style={{maxHeight: 'calc(100dvh - 185px)', minHeight: 'calc(100dvh - 185px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <div className='pt-2'>
                <div className='py-2' style={{
                    borderTop: '1px solid ' + appTheme.appColor.primary,
                    borderBottom: '1px solid ' + appTheme.appColor.primary
                }}>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='px-3'>
                                <DLText id={'records-count'}
                                        fontSize={'sm'}
                                        marginBottom={'none'}
                                        fontWeight={'semi-bold'}
                                        text={'Errors'}/>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='px-3'>
                                <DLText id={'records-count'}
                                        fontSize={'sm'}
                                        marginBottom={'none'}
                                        fontWeight={'semi-bold'}
                                        text={'Category'}/>
                            </div>
                        </div>
                        <div className='col-3 text-center'>
                            <DLText id={'records-count'}
                                    fontSize={'sm'}
                                    marginBottom={'none'}
                                    fontWeight={'semi-bold'}
                                    text={'Action'}/>
                        </div>
                    </div>
                </div>
                {renderListing()}
            </div>
        )
    };

    const renderErrorModel = () => {
        const {refreshFullScreen, openErrorModel, handleCloseModal} = props;

        if (openErrorModel) {
            return (
                <AddError {...props}
                          openErrorModel={openErrorModel}
                          refreshFullScreen={refreshFullScreen}
                          handleCloseModal={handleCloseModal}/>
            )
        }
    };

    return (
        <div className='h-100'>
            <div className='panel p-0 h-100'>
                {renderHeader()}
                {renderTable()}
                {renderErrorModel()}
            </div>
        </div>
    )
};

export default ProductListView;
