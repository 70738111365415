import React, {createRef} from "react";
import {isEmpty} from "../../utils/validations";
import DLInputTextField from "../../core/components/DLInputTextField/DLInputTextField";
import DLButton from "../../core/components/DLButton/DLButton";
import DLText from "../../core/components/DLText/DLText";

let scrollTo = {
    userName: createRef(),
    password: createRef(),
};

const ForgetPasswordView = (props) => {
    const {submitFormWithDelay, isSMView, checkKey, isXSView} = props;

    const renderLoginUI = () => {
        const {onFormDataChange, loginData} = props;
        const {formData} = loginData;

        return (
            <div>
                <div className='mb-3'>
                    <DLText id={'signin-title'}
                            marginBottom={'md'}
                            text={'Forgot Password'}
                            fontSize={"xxl"}
                            fontWeight={"semi-bold"}
                    />
                    <DLText id={'signin-title'}
                            text={'Provide your User Name and we’ll send you a link to reset your password.'}
                            fontSize={"sm"}
                            marginBottom={"none"}
                            fontColor={'grayDark'}
                            fontWeight={"normal"}
                    />

                </div>
                {!(isEmpty(loginData.responseError)) &&
                <div>
                    <DLText id={'signin-title'}
                            text={loginData.responseError}
                            fontSize={"sm"}
                            marginBottom={"none"}
                            fontColor={'danger'}
                            fontWeight={"normal"}
                    />
                </div>
                }

                <form onSubmit={(e) => submitFormWithDelay(e)}
                      onKeyPress={() => checkKey()}>
                    <div className=''>
                        <div ref={scrollTo['userName']}>
                            <div className='d-flex flex-column pb-4 pt-3'>
                                <DLInputTextField
                                    id={'user-login-name'}
                                    label={'User Name'}
                                    value={formData?.userName}
                                    isRequired={true}
                                    onChangeValue={(value) => onFormDataChange('userName', value)}
                                    isClearable={true}
                                    error={formData?.userNameError !== ''}
                                    helperMessage={formData?.userNameError}
                                    maxCharLength={51}
                                    disableHelperMessageHeight={true}
                                    autoFocus={true}
                                    marginBottom={"none"}
                                    hideCharLength={true}
                                    debounceTimer={100}
                                />
                            </div>
                        </div>
                        <div className='d-grid mx-auto text-center w-100 pt-2 pb-4'>
                            <DLButton
                                id={'sign-in-btn'}
                                history={props.history}
                                isApiInProgress={loginData.isLoginInProgress}
                                isLoaderButton={true}
                                isDisabled={loginData.isLoginInProgress}
                                label={'Submit'}
                                buttonSize={"sm"}
                                type={'primary'}
                                fontSize={"md"}
                                sideMargin={'none'}
                                onClick={() => {
                                }}
                            />
                        </div>

                    </div>
                </form>
            </div>

        )
    };

    const renderEmailText = () => {
        const {email} = props;
        if (email?.toLowerCase().includes('gmail')) {
            return (
                <DLText id={'email-link-sent-reset-text'}
                        fontSize={'sm'}
                        fontColor={'grayDark'}
                        marginBottom={'none'}
                        text={'Please check the promotions tab for the verification code.'}/>
            );
        }
        return (
            <DLText id={'email-link-sent-reset-text'}
                    fontSize={'sm'}
                    marginBottom={'none'}
                    fontColor={'grayDark'}
                    text={'Please check the promotions tab for the reset password email.'}/>
        );
    };

    const renderEmailLinkSentUI = () => {
        const {loginData} = props;
        const {formData} = loginData;

        return (
            <div className='d-flex flex-column'>
                <div className='mb-4 pb-2'>
                    <DLText id={'email-link-sent-link-text'}
                            marginBottom={"none"}
                            text={'Password Reset Link Sent'}
                            fontSize={"xxl"}
                            fontWeight={"semi-bold"}/>
                </div>
                <div id={'forgot-password-text'}
                     className='d-flex flex-wrap mb-3'>
                    <div className='me-1'>
                        <DLText id={'email-link-sent-link-sent-text'}
                                marginBottom={"none"}
                                fontSize={'sm'}
                                fontColor={'grayDark'}
                                text={'We have sent a reset password link to '}/>
                    </div>
                    <div className='me-1'>
                        <DLText id={'email-link-sent-link-sent-email'}
                                fontWeight={"semi-bold"}
                                fontSize={'sm'}
                                marginBottom={"none"}
                                fontColor={'grayDark'}
                                text={formData?.userName}/>
                    </div>
                    <div>
                        <DLText id={'email-link-sent-reset-text'}
                                fontSize={'sm'}
                                marginBottom={"none"}
                                fontColor={'grayDark'}
                                text={' username.'}/>
                    </div>
                </div>
                <div  className='mb-3'>
                    <DLText id={'email-link-sent-reset-text'}
                            fontSize={'sm'}
                            marginBottom={"none"}
                            fontColor={'grayDark'}
                            text={'This password link will be valid for 24 hours.'}/>
                </div>

                <div className='mb-4 pb-2'
                     id={'forgot-password-text1'}>
                    {renderEmailText()}
                </div>
                <div className='d-grid mx-auto text-center w-100'>
                    <DLButton id={'email-link-sent-sign-in'}
                              type={"primary"}
                              onClick={() => props.history.push('/user/login')}
                              fontSize={'md'}
                              sideMargin={"none"}
                              label={<span className='px-3'>{'Sign In'}</span>}
                    />
                </div>
            </div>
        );
    };

    const renderUI = () => {
        const {UIState} = props.loginData;

        if (UIState === 'RESET_LINK_SENT') {
            return renderEmailLinkSentUI()
        }

        return renderLoginUI();
    };

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-center'
             style={{backgroundColor: "#F0F0F0", height: '100vh'}}>
            <div className="text-center mb-2">
                <DLText id={''}
                        text={'CM App'}
                        fontSize={"xxxl"}
                        fontWeight={"semi-bold"}
                />
            </div>

            <div className={(isXSView || isSMView) ? "panel bg-white" : "panel bg-white"}
                 style={{
                     width: (isXSView || isSMView) ? '90%' : "450px",
                 }}>
                <i className="fa-solid fa-chevron-left pe-2 cursor header-icon"
                   onClick={() => props.history.goBack()}/>
                <div className="d-flex align-items-center">
                    <div className='w-100 d-flex flex-column justify-content-center'
                         style={{minHeight: 'calc(100dvh - 280px)'}}>
                        {renderUI()}
                    </div>
                </div>
            </div>
        </div>
    );
};

ForgetPasswordView.propTypes = {};

export default ForgetPasswordView;
