import React from "react";

import {cellConfig, headingData} from "./component/productListModal";

import {isEmpty} from "../../utils/validations";
import {convertUTCToLocalTime, getCountText} from "../../utils/helper";

import DLText from "../../core/components/DLText/DLText";
import DLTable from "../../core/components/DLTable/DLTable";
import DLInputTextField from "../../core/components/DLInputTextField/DLInputTextField";
import DLLoader from "../../core/components/DLLoader/DLLoader";

const ProductListView = (props) => {
    const {
        handleChangePage, handleChangeRowsPerPage, getPaginationNextButtonStatus, onChangeFilter, isShowLoader, keyword
    } = props;

    const getProductAdmin = (productAdmin) => {
        if (isEmpty(productAdmin)) {
            return '-'
        }
        return <div className='py-2'>
            <ul className='unorderedList-class mb-0 pb-0 ms-0'
                style={{paddingLeft: 15}}>
                {productAdmin.map((user, index) =>
                    <li key={index}>
                        <DLText id={'delete-message'}
                                text={user.userName}
                                fontSize={'xs'}
                                marginBottom={'none'}
                                customTextStyle={{
                                    color: '#000000de',
                                }}
                        />
                    </li>
                )}
            </ul>
        </div>
    };

    const getFormattedTableData = (products) => {
        let tableData = [];

        if (!products || products.length === 0) {
            return tableData;
        }

        products.map((selectedProduct) => {
            let productRecords = [];
            let productDetails = {};
            let productOwner = selectedProduct?.productAssignments?.find(user => user.roleId === 'PRODUCT_OWNER');
            let productAdmin = selectedProduct?.productAssignments?.filter(user => user.roleId === 'PRODUCT_ADMIN');
            let lowerThreshold = selectedProduct?.qaSamplingLowerThreshold;
            let upperThreshold = selectedProduct?.qaSamplingUpperThreshold;

            productRecords.push(isEmpty(selectedProduct?.name) ? '-' : selectedProduct?.name);
            productRecords.push(productOwner?.userName || '-');
            productRecords.push(getProductAdmin(productAdmin));
            productRecords.push(isEmpty(selectedProduct?.updatedAt) ? '-' : convertUTCToLocalTime(selectedProduct?.updatedAt, "DD-MM-YYYY"));
            productRecords.push(isEmpty(selectedProduct?.complexity) ? '-' : selectedProduct?.complexity);
            productRecords.push(getQASamplingValue(lowerThreshold, upperThreshold));

            productDetails.id = selectedProduct.id;
            productDetails.data = productRecords;
            tableData.push(productDetails);
        });

        return tableData;
    };

    const getQASamplingValue = (lowerThreshold, upperThreshold) => {
        if (isEmpty(lowerThreshold) || isEmpty(upperThreshold)) {
            return '-'
        } else {
            return lowerThreshold + '-' + upperThreshold;
        }

    };
    const renderHeader = () => {
        return (
            <div className='page-header pe-0 pt-1 pb-1 me-2'>
                <DLText
                    id={'Users'}
                    fontSize={'xl'}
                    fontColor={'primary'}
                    fontWeight={'semi-bold'}
                    text={'Products'}/>
            </div>
        )
    };

    const renderProductSearch = () => {
        return (
            <div className={'search-input ms-1 col-3 pt-1 ms-2'}>
                <DLInputTextField id={'leads-searchBox'}
                                  marginBottom={"none"}
                                  styleType={"search"}
                                  labelFontColor={'black'}
                                  value={keyword}
                                  placeholder={'Search by Product Name'}
                                  isSearchable={true}
                                  disableHelperMessageHeight={true}
                                  onChangeValue={(e) => {
                                      onChangeFilter('keyword', e)
                                  }}/>
            </div>
        )
    };

    const renderCount = () => {
        const {listingCount} = props;

        return (
            <div className='pb-2'>
                <div className={'d-flex align-items-center '}>
                    <DLText id={'records-count'}
                            fontColor={'grayDark'}
                            fontSize={'sm'}
                            marginBottom={'xs'}
                            fontWeight={'semi-bold'}
                            text={`Showing ${listingCount} ${getCountText("Record", listingCount)}`}/>
                </div>
            </div>
        )
    };

    const renderTable = () => {
        const {skipCount, page, listingCount, rowsPerPage, productsDetails} = props;
        const tableData = getFormattedTableData(productsDetails);

        if (isEmpty(tableData)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center pt-2'
                     style={{maxHeight: 'calc(100dvh - 206px)', minHeight: 'calc(100dvh - 206px)'}}>
                    No data found.
                </div>
            )
        }
        return (
            <DLTable id={'product-list-table'}
                     tableMaxHeight={'calc(100dvh - 255px)'}
                     tableMinHeight={'calc(100dvh - 255px)'}
                     isRowClickable={true}
                     cellConfig={cellConfig}
                     headerData={headingData}
                     tableData={tableData}
                     isShowPagination={true}
                     onRowClick={(productId) => {
                         props.history.push('/products/' + productId);
                     }}
                     pagination={{
                         totalPages: listingCount,
                         rowsPerPage: rowsPerPage,
                         currentPage: page,
                         onPageChange: handleChangePage,
                         onRowPerPageChange: handleChangeRowsPerPage,
                         enableNextButton: getPaginationNextButtonStatus(),
                         enablePrevButton: skipCount === 0
                     }}
            />
        )
    };

    const renderLoader = () => {
        return (
            <DLLoader isVisible={isShowLoader} type={'screen'}/>
        )
    };

    return (
        <div>
            <div className='d-flex align-items-center pb-2 pt-2'>
                {renderHeader()}
                {renderProductSearch()}
            </div>

            <div className={'page-container'}
                 style={{minHeight: 'calc(100dvh - 225px)'}}>
                <div className='panel p-3 pb-0'>
                    {renderCount()}
                    {renderTable()}
                </div>
            </div>
            {renderLoader()}
        </div>
    )
};

export default ProductListView;

