import React from 'react';
import {isEmpty} from "../../../../../utils/validations";

import DLModal from "../../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../../../core/components/DLInputTextField/DLInputTextField";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLRadioGroup from "../../../../../core/components/DLRadioGroup/DLRadioGroup";
import DLText from "../../../../../core/components/DLText/DLText";

const CreateTaskView = (props) => {

    const renderModalUI = () => {
        const {reason, onChangeValue, categoryOptions, category} = props;
        const {reasonError, categoryError} = props.errorObj;

        return (
            <div>
                {renderErrorUI()}
                <div>
                    <div className='mb-3'>
                        <DLInputTextField id={'first-name'}
                                          label={'Reason'}
                                          value={reason}
                                          placeholder={''}
                                          disableHelperMessageHeight={true}
                                          error={reasonError !== ''}
                                          maxCharLength={251}
                                          isRequired={true}
                                          rows={4}
                                          maxRows={4}
                                          isMulti={true}
                                          helperMessage={reasonError}
                                          onChangeValue={(value) => {
                                              onChangeValue('reason', value)
                                          }}/>
                    </div>
                    <div className=''>
                        <DLText id={'records-count'}
                                fontColor={categoryError !== "" ? 'danger' : 'grayDark'}
                                fontSize={'xs'}
                                marginBottom={'none'}
                                text={'Category'}/>
                        <div className='ps-2'>
                            <DLRadioGroup id={'assign-radio-btn'}
                                          options={categoryOptions}
                                          label={''}
                                          onChangeValue={(value) => {
                                              onChangeValue('category', value)
                                          }}
                                          value={category}
                                          type={'inline'}
                                          minHeight={"20px"}
                                          marginBottom={'none'}
                                          labelFontSize={'sm'}
                                          labelFontColor={'black'}
                            />
                        </div>
                        {categoryError !== '' &&
                            <div className='ps-3'>
                                <DLText id={'records-count'}
                                        fontColor={'danger'}
                                        fontSize={'xs'}
                                        marginBottom={'none'}
                                        text={categoryError}/>
                            </div>
                        }
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseError} = props.errorObj;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'py-2'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    const renderAddErrorModal = () => {
        const {openErrorModel, addErrorData, handleCloseModal, modelData} = props;
        return (
            <div>
                <DLModal
                    history={props.history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseModal("openErrorModel")}
                    open={openErrorModel}
                    id={'assign-modal'}>
                    <DLModalTitle
                        id={'assign-title'}
                        onClose={() => handleCloseModal("openErrorModel")}
                        title={modelData?.edit ? 'Edit' : 'Add New Error'}/>
                    <DLModalContainer
                        id={'assign-container'}>
                        {renderModalUI()}
                    </DLModalContainer>
                    <DLModalActions id={'assign-action'}>
                        <div className='d-flex gap-3 mt-3 mb-3'>
                            <DLButton
                                id={'assign-cancel-btn'}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"none"}
                                onClick={() => handleCloseModal("openErrorModel")}/>
                            <DLButton
                                id={'assign-assign-btn'}
                                label={modelData?.edit ? 'Save' : 'Add'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"none"}
                                onClick={() => addErrorData()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        const {isShowLoader} = props;
        return (
            <DLLoader type={"screen"} isVisible={isShowLoader}/>
        )
    };

    return (
        <div>
            {renderLoader()}
            {renderAddErrorModal()}
        </div>
    );
};

export default CreateTaskView;