import React from 'react';
import {connect} from "react-redux";
import {cellConfig, homepageTableHeaders,} from "./UserManagementModel";
import DLTable from "../core/components/DLTable/DLTable";
import DLButton from "../core/components/DLButton/DLButton";
import DLInputTextField from "../core/components/DLInputTextField/DLInputTextField";
import DLDropDown from "../core/components/DLDropdown/DLDropDown";
import DLText from "../core/components/DLText/DLText";
import appTheme from "../assets/appTheme";
import {isEmpty} from "../utils/validations";
import AssignRole from "./assignRole/AssignRole";
import DeleteUser from "./deleteUser/DeleteUser";
import AddUser from "./addUser/addUser";
import DLLoader from "../core/components/DLLoader/DLLoader";
import DLMenuOptions from "../core/components/DLMenuOptions/DLMenuOptions";
import DeleteRole from "./deleteRole/DeleteRole";
import {convertUTCToLocalTime} from "../utils/dateTimeHelper";
import {getCountText} from "../utils/helper";

const userManagementView = (props) => {
    const {
        skipCount, handleChangeRowsPerPage, rowsPerPage, getPaginationNextButtonStatus, page,
        handleChangePage, handleCloseModal, getValueItems, handleCloseNewUser, isNewUserOpen, listingCount
    } = props;

    const {isShowLoader} = props;

    const renderCountContainer = () => {
        const {summaryData} = props;
        return (
            <div className={''}>
                <div style={{height: '60px'}} className='d-flex align-items-center'>
                    <DLText
                        id={'Users'}
                        fontSize={'xl'}
                        fontColor={'primary'}
                        marginBottom={'none'}
                        fontWeight={'semi-bold'}
                        text={'Users'}/>
                </div>
                <div className='panel-without-padding p-3 overflow-y-auto' style={{
                    maxHeight: 'calc(100dvh - 140px)',
                    minHeight: 'calc(100dvh - 140px)'
                }}
                >
                    <div className='d-flex flex-column gap-3'>
                        <div className='user-summary'
                             style={{flex: 2, flexGrow: 1, backgroundColor: appTheme.appColor.lightBlue}}>
                            <div className='d-flex flex-column  border-rounded'>
                                <DLText
                                    id={'total'}
                                    fontSize={'xl'}
                                    fontWeight={'bold'}
                                    text={summaryData?.totalCount}/>
                                <DLText
                                    id={'total-label'}
                                    fontSize={'sm'}
                                    fontWeight={'normal'}
                                    text={'Total'}/>
                            </div>
                        </div>
                        <div className='user-summary'
                             style={{flex: 2, flexGrow: 1, backgroundColor: appTheme.appColor.lightBlue}}>
                            <div className='d-flex flex-column border-rounded'>
                                <DLText
                                    id={'curators'}
                                    fontSize={'xl'}
                                    fontWeight={'bold'}
                                    text={getValueItems('CURATOR')}/>
                                <DLText
                                    id={'curators-label'}
                                    fontSize={'sm'}
                                    fontWeight={'normal'}
                                    text={'Curators'}/>
                            </div>
                        </div>
                        <div className='user-summary'
                             style={{flex: 2, flexGrow: 1, backgroundColor: appTheme.appColor.lightBlue}}>
                            <div className='d-flex flex-column  border-rounded'>
                                <DLText
                                    id={'qa-personnel'}
                                    fontSize={'xl'}
                                    fontWeight={'bold'}
                                    text={getValueItems('QA')}/>
                                <DLText
                                    id={'qa-personnel-label'}
                                    fontSize={'sm'}
                                    fontWeight={'normal'}
                                    text={'QA Personnel'}/>
                            </div>
                        </div>
                        <div className='user-summary'
                             style={{flex: 2, flexGrow: 1, backgroundColor: appTheme.appColor.lightBlue}}>
                            <div className='d-flex flex-column border-rounded'>
                                <DLText
                                    id={'product-owners'}
                                    fontSize={'xl'}
                                    fontWeight={'bold'}
                                    text={getValueItems('PRODUCT_OWNER')}/>
                                <DLText
                                    id={'product-owners-label'}
                                    fontSize={'sm'}
                                    fontWeight={'normal'}
                                    text={'Product Owners'}/>
                            </div>
                        </div>
                        <div className='user-summary'
                             style={{flex: 2, flexGrow: 1, backgroundColor: appTheme.appColor.lightBlue}}>
                            <div className='d-flex flex-column border-rounded'>
                                <DLText
                                    id={'product-admins'}
                                    fontSize={'xl'}
                                    fontWeight={'bold'}
                                    text={getValueItems('PRODUCT_ADMIN')}/>
                                <DLText
                                    id={'product-admins-label'}
                                    fontSize={'sm'}
                                    fontWeight={'normal'}
                                    text={'Product Admins'}/>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    };

    const renderFilterData = () => {
        const {onChangeFilter, openModel, keyword, roleOptions, role} = props;

        return (
            <div className={'d-flex mt-1 justify-content-between w-100'}>
                <div className='d-flex flex-row'>
                    <div className='me-2 search-input' style={{width: '300px'}}>
                        <DLInputTextField id={'searchBox'}
                                          marginBottom={"none"}
                                          rows={1}
                                          styleType={"search"}
                                          labelFontColor={'black'}
                                          value={keyword}
                                          placeholder={'Search by User Name, Email '}
                                          isSearchable={true}
                                          size="small"
                                          inputHeight={'sm'}
                                          inputType={"text"}
                                          disableHelperMessageHeight={true}
                                          onChangeValue={(value) => {
                                              onChangeFilter('keyword', value)
                                          }}/>
                    </div>
                    <div className='select-filter ms-1 filter'>
                        <DLDropDown
                            id={"role-filter"}
                            label={''}
                            labelFontColor={'black'}
                            labelFontSize={'sm'}
                            value={role}
                            options={roleOptions}
                            onSelect={(value) => {
                                onChangeFilter('role', value)
                            }}
                            disableHelperMessageHeight={true}
                            marginBottom={"none"}
                        />
                    </div>
                </div>
                <div>
                    <DLButton
                        id={'add-new-user'}
                        sideMargin={'none'}
                        type={"primary"}
                        label={'Add New User'}
                        onClick={() => openModel("isNewUserOpen")}/>
                </div>
            </div>
        )
    };

    const renderMenuOptions = (selectedTransaction) => {
        const {getActionOptions, handleMenuClick} = props;
        return (
            <DLMenuOptions id={'menu-opt'}
                           onSelect={(value) => {
                               handleMenuClick(value, selectedTransaction)
                           }}
                           options={getActionOptions(selectedTransaction?.roles)}
            />
        )
    };

    const renderUserNameUI = (selectedTransaction) => {
        return (
            <div className='d-flex align-items-center'>
                <DLText id={'delete-message'}
                        text={selectedTransaction.userName}
                        fontSize={'xs'}
                        customTextStyle={{
                            color: '#000000de'
                        }}

                />
                {selectedTransaction?.isTrainee &&
                <div className="d-flex align-items-center badge-green ms-2">
                    <span style={{color: appTheme.appColor.white}}>Trainee</span>
                </div>
                }
            </div>
        )
    };

    const getProductName = (item) => {
        if (!item || !item.products || item.products.length === 0) {
            return ''
        }
        return (
                <DLText id={'delete-message'}
                        text={`(${item.products[0].name})`}
                        fontSize={'xs'}
                        isInline={true}
                        customWrapperStyle={{
                            marginLeft: 4
                        }}
                        marginBottom={'none'}
                        fontColor={'grayDark'}
                />
        )
    };

    const renderRoles = (selectedTransaction) => {
        if (isEmpty(selectedTransaction) || isEmpty(selectedTransaction.roles)) {
            return '-'
        }

        return <ul className='unorderedList-class mb-0 pt-2 pb-1 ms-0' style={{paddingLeft: 15}}> {
            selectedTransaction.roles?.map((item, index) => {
                return (
                    <li key={index}
                        style={{marginBottom: 5}}>
                        <DLText id={'delete-message'}
                                text={item?.name}
                                fontSize={'xs'}
                                isInline={true}
                                marginBottom={'none'}
                                customTextStyle={{
                                    color: '#000000de',
                                }}
                        />
                        {getProductName(item)}
                    </li>
                )
            })}
        </ul>
    };

    const getFormattedTableData = (userData) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(userData)) {
            return tableData
        }

        userData.map((selectedTransaction) => {
            let userRecords = [];
            let userDetails = {};
            if (!isXSView) {
                userRecords.push(renderUserNameUI(selectedTransaction));
                userRecords.push(selectedTransaction.email);
                userRecords.push(renderRoles(selectedTransaction));
                userRecords.push(convertUTCToLocalTime(selectedTransaction.createdAt, "DD-MM-YYYY"));
                userRecords.push(renderMenuOptions(selectedTransaction));
            } else {
                userRecords.push(renderUserNameUI(selectedTransaction));
                userRecords.push(selectedTransaction.email);
                userRecords.push(renderRoles(selectedTransaction));
                userRecords.push(convertUTCToLocalTime(selectedTransaction.createdAt, "DD-MM-YYYY"));
                userRecords.push(renderMenuOptions(selectedTransaction));
            }

            userDetails.id = selectedTransaction?.id;
            userDetails.data = userRecords;
            tableData.push(userDetails)
        });

        return tableData
    };

    const emptyList = () => {
        return <div className='d-flex justify-content-center align-items-center gray-lg-text'
                    style={{minHeight: 'calc(100dvh - 132px)', overflow: 'hidden', fontSize: '16px'}}>
            <div className='text-center '>
                <img className={'image-container'} src={''} alt={''}/>
                <div className={'pt-4'}>
                    <DLText id={'no-datafound'}
                            text={'No Data found.'}
                            marginBottom={'md'}
                    />
                </div>
            </div>
        </div>
    };

    const renderUserListTable = () => {
        const {userData} = props;
        if (isEmpty(userData)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center'
                     style={{maxHeight: 'calc(100dvh - 196px)'}}>
                    {emptyList()}
                </div>
            )
        }

        let tableData = getFormattedTableData(userData);
        return (
            <div className='w-100'>
                <DLTable
                    id={'user-list-table'}
                    tableMaxHeight={'calc(100dvh - 250px)'}
                    tableMinHeight={'calc(100dvh - 250px)'}
                    onRowClick={() => {
                    }}
                    isDoubleHeader={false}
                    cellConfig={cellConfig}
                    headerData={homepageTableHeaders}
                    tableData={tableData}
                    pagination={
                        {
                            totalPages: userData.length,
                            rowsPerPage: rowsPerPage,
                            currentPage: page,
                            onPageChange: handleChangePage,
                            onRowPerPageChange: handleChangeRowsPerPage,
                            enableNextButton: getPaginationNextButtonStatus(),
                            enablePrevButton: skipCount === 0

                        }
                    }
                />
            </div>
        )
    };

    const userModelDelete = () => {
        const {isDeleteModalOpen, refreshFullScreen} = props;
        if (isDeleteModalOpen) {
            return (
                <DeleteUser {...props}
                            isDeleteModalOpen={isDeleteModalOpen}
                            handleCloseModal={handleCloseModal}
                            refreshFullScreen={refreshFullScreen}/>
            )
        }
    };

    const deleteRoleModel = () => {
        const {isDeleteRoleModalOpen, refreshFullScreen} = props;
        if (isDeleteRoleModalOpen) {
            return (
                <DeleteRole {...props}
                            isDeleteRoleModalOpen={isDeleteRoleModalOpen}
                            handleCloseModal={handleCloseModal}
                            refreshFullScreen={refreshFullScreen}/>
            )
        }
    };

    const assignRoleModel = () => {
        const {isAssignRoleModalOpen, refreshFullScreen} = props;
        if (isAssignRoleModalOpen) {
            return (
                <AssignRole {...props}
                            isAssignRoleModalOpen={isAssignRoleModalOpen}
                            handleCloseModal={handleCloseModal}
                            refreshFullScreen={refreshFullScreen}/>
            )
        }
    };

    const addUserModel = () => {
        const {addUser, refreshFullScreen} = props;

        if (isNewUserOpen) {
            return (
                <AddUser {...props}
                         isNewUserOpen={isNewUserOpen}
                         refreshFullScreen={refreshFullScreen}
                         handleCloseNewUser={handleCloseNewUser}
                         addUser={addUser}/>
            )
        }
    };

    const renderLoader = () => {
        return (
            <DLLoader isVisible={isShowLoader} type={'screen'}/>
        )
    };

    return (
        <div>
            <div className={'page-container'}
                 style={{minHeight: 'calc(100dvh - 225px)'}}>
                <div className='d-flex gap-4'>
                    <div className='' style={{minWidth: "229px"}}>
                        {renderCountContainer()}
                    </div>
                    <div className='' style={{width: "calc(100vw - 286px)"}}>
                        <div className='d-flex align-items-center'
                             style={{height: '60px', width: '100%'}}>
                            {renderFilterData()}
                        </div>
                        <div className='panel p-3 pb-0' style={{width: "calc(100vw - 312px)"}}>
                            <div className='d-flex justify-content-between pb-2'>
                                <div className='d-flex align-items-center'>
                                    <DLText
                                        id={'showing-records'}
                                        fontWeight={"semi-bold"}
                                        marginBottom={"xs"}
                                        text={`Showing ${listingCount} ${getCountText("Record", listingCount)}`}
                                        fontColor={'grayDark'}/>
                                </div>
                            </div>
                            {renderUserListTable()}
                        </div>
                    </div>
                </div>
            </div>
            {renderLoader()}
            {assignRoleModel()}
            {userModelDelete()}
            {deleteRoleModel()}
            {addUserModel()}
        </div>
    );
};

export default connect()(userManagementView);