import React, {Suspense, useEffect, useState} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Drawer from '@mui/material/Drawer';
import {setCurrentPathname, showSystemErrorBlock} from "./AppActions";
import {getMyProfile} from "../userAuth/userActions";
import {setPrevLocation} from "./AppActions";
import {isEmpty} from "../utils/validations";
import {userConst} from "../userAuth/userConsts";
import {resetLoggedOutState, setUserAuthStatus, setUserProfile} from "../userAuth/userReducer";

import SiteUnderMaintenance from "./SiteUnderMaintenance";
import PageNotAccessible from "../core/components/pageNotAccessible/PageNotAccessible";
import LoggedOutUser from "../core/components/loggedOutUser/LoggedOutUser";
import ResAppBar from "./appResappBar/AppResAppBar";
import DLLoader from "../core/components/DLLoader/DLLoader";
import DLToastMessage from "../core/components/DLToastMessage/DLToastMessage";

import store from "../store"
import Login from "../userAuth/logIn/Login";
import HomePage from "../../src/home/HomePage";
import UserManagement from "../UserManagement/UserManagement";
import PostLoginRoute from "../core/webRouteWrapper/PostLoginRoute";
import PreLoginRoute from "../core/webRouteWrapper/PreLoginRoute";
import PrePostLoginRoute from "../core/webRouteWrapper/PrePostLoginRoute";
import {getAuthorizationToken} from "../utils/httpHelper";
import ForgetPassword from "../userAuth/forgetPassword/ForgetPassword";
import {refreshWindowScrollElement} from "../utils/helper";
import ProductList from "../products/ProductList/ProductList";
import ResetPassword from "../userAuth/resetPassword/ResetPassword";
import ProductDetails from "../products/ProductDetails/ProductDetails";
import ChangePassword from "../userAuth/changePassword/ChangePassword";


const AppNavigator = (props) => {
    const {isMobileView, userAuthStatus} = props;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isProfileSet, setIsProfileSet] = useState(true);
    const [isSiteUnderMaintenance, setIsSiteUnderMaintenance] = useState(false);
    const [isSettingOptionShow, setIsSettingOptionShow] = useState(false);
    let authResponse = getAuthorizationToken();

    const handleSettingIconClick = (value) => {
        setIsSettingOptionShow(value);
    };

    useEffect(() => {
        if (authResponse.status && !isEmpty(authResponse.token)) {
            getMyProfile().then((res) => {
                if (res.success) {
                    setIsProfileSet(true);
                } else {
                    if (res.statusCode === 401 || res.statusCode === 403) {
                        setIsProfileSet(true)
                    } else {
                        setIsProfileSet(false);
                        store.dispatch(showSystemErrorBlock());
                        setIsSiteUnderMaintenance(true)
                    }
                }
            })
        }
    }, [authResponse.status]);

    useEffect(() => {
        setCurrentPathname(props.location.pathname);
        resetReduxStates();
        refreshWindowScrollElement();
    }, [props.location.pathname, props.account, props.userAuthStatus]);

    useEffect(() => {
        if (props.userStatus === userConst.AUTH_STATUS_LOGGED_OUT) {
            props.history.replace('/session-expired');
            store.dispatch(resetLoggedOutState());
        }
        if (props.userStatus === userConst.ACCESS_DENIED) {
            props.history.push('/access-denied', {
                fullUrl: window.location.href,
                prevPath: props.location.pathname
            });
            store.dispatch(resetLoggedOutState());
        }
    }, [props.userStatus]);

    const resetReduxStates = () =>{
        setCurrentPathname(props.location.pathname);
        props.setPrevLocation(props.location);
    };

    const renderDrawer = () => {
        if (userAuthStatus === userConst.AUTH_STATUS_UNAUTHORIZED) {
            return null;
        }
        let contentUi = '';
        let mobileDrawerUi = (
            <Drawer
                anchor={'left'}
                open={isDrawerOpen}
                sx={{
                    width: '300px',
                    '& .MuiDrawer-paper': {
                        width: '300px',
                    },
                }}
                onClose={() => setIsDrawerOpen(false)}>
                <div style={{
                    background: '#E7ECF2',
                    height: '100vh'
                }}>
                    {contentUi}
                </div>
            </Drawer>
        );

        let desktopDrawerUi = (
            <div style={{
                width: 240,
                height: '100%',
                position: "fixed",
                background: '#E7ECF2'
            }}
                 className={"drawer-height"}>
                {contentUi}
            </div>
        );

        return (
            <div>
                {isMobileView ? mobileDrawerUi : desktopDrawerUi}
            </div>
        );


    };

    const renderAppBar = () => {
        return (
            <ResAppBar
                openDrawer={() => setIsDrawerOpen(true)}
                handleSettingIconClick={handleSettingIconClick}
                isSettingOptionShow={isSettingOptionShow}
            />
        );
    };

    const renderContent = () => {
        return (
            <div className="w-100 h-100"
                 style={{
                     overflowY: 'auto'
                 }}
                 onClick={() => {
                     setIsSettingOptionShow(false)
                 }}>
                <Suspense fallback={(<DLLoader type={"screen"} isVisible={true}/>)}>
                    <Switch>
                        <PostLoginRoute exact path={"/"} component={HomePage}/>
                        <PostLoginRoute exact path={"/users"} component={UserManagement}/>
                        <PostLoginRoute exact path={"/products"} component={ProductList}/>
                        <PostLoginRoute exact path={"/products/:productId"} component={ProductDetails}/>
                        <PreLoginRoute exact path="/user/login" component={Login}/>
                        <PostLoginRoute exact path="/user/change-password" component={ChangePassword}/>
                        <PreLoginRoute exact path={"/user/forgot-password"} component={ForgetPassword}/>
                        <PreLoginRoute exact path={"/user/reset-password"} component={ResetPassword}/>
                        <PrePostLoginRoute exact path="/session-expired" component={LoggedOutUser}/>
                        <Route exact path={"/access-denied"} component={PageNotAccessible}/>
                        <Route exact path="*" component={''}/>
                    </Switch>
                </Suspense>
                <DLToastMessage/>
            </div>
        );
    };

    const getPageMargin = () => {
        const {isXSView} = props;
        if (isXSView) {
            return "54px"
        }
        return "50px"
    };

    const {profile, isProfileCallFired} = props;

    if (isSiteUnderMaintenance) {
        return <SiteUnderMaintenance {...props}/>
    }

    if (authResponse.status && !isEmpty(authResponse.token)) {
        if (!isProfileCallFired && isEmpty(profile)){
            return
        }

        if (!isProfileSet) {
            return (
                <>
                    <DLToastMessage/>
                </>
            )
        }
    }

    return (
        <div style={{
            height: '100dvh'
        }}>
            {renderAppBar()}
            <div className="h-100"
                 style={{
                     paddingTop: (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) ? getPageMargin() : '0px'
                 }}>
                {isDrawerOpen && renderDrawer()}
                {renderContent()}
                <DLToastMessage/>
            </div>
        </div>
    );
};

AppNavigator.propTypes = {};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile: state.userState.profile,
    isProfileCallFired: state.userState.isProfileCallFired,
    isDrawerOpen: state.appState.isDrawerOpen,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    appRoomJoined: state.appState.socket.appRoomJoined,
    userStatus: state.userState.userStatus,
});

export default connect(mapStateToProps,
    {setUserProfile, setPrevLocation, setUserAuthStatus})(withRouter(AppNavigator));