import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import UserManagementView from "./UserManagementView";
import {APP_CONST} from "../appComponents/AppConstants";
import {
    getAllRoles,
    getAllUserData,
    getUserSummary,
    setLimitCount,
    setPageCount,
    setRowsPerPage,
    setSkipCount,
    setUserListingSearchKeyword,
    setUserListingSearchRole
} from "./UserManagementAction";
import {isEmpty} from '../utils/validations'
import DLLoader from "../core/components/DLLoader/DLLoader";

const UserManagement = (props) => {
    const [userListData, setUserListData] = useState({
        userData: [],
        listingCount: 7,
        isCreateModalOpen: false,
        isDeleteModalOpen: false,
        isDeleteRoleModalOpen: false,
        roleFilterOptions: [],
        isNewUserOpen: false,
        summaryData: [],
        actionOptions : [],
        roleOptions: [],
        deleteData: null,
        modelData: null
    });

    const {page, rowsPerPage, skipCount, limitCount} = props.userManagementFilter;

    useEffect(() => {
        getUserSummaryData();
        getAllRolesData();
    }, []);

    useEffect(() => {
        getAllData();
    }, [props.userManagementFilter]);

    const getAllRolesData = () => {
        setUserListData(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        getAllRoles()
            .then(res => {
                if (res?.success) {
                    setUserListData(prevState => ({
                        ...prevState,
                        roleOptions: formatRolesData(res.data?.roles),
                        isShowLoader: false
                    }));
                } else {
                    setUserListData(prevState => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                }
            })
    };

    const refreshFullScreen = (model) =>{
        setUserListData(prevState => ({
            ...prevState,
            isShowLoader: true,
            [model]: false,
            modelData: null
        }));
        getUserSummaryData();
        getAllRolesData();
        getAllData();
    };

    const getActionOptions = (rolesData) =>{
        let actions = [];

        if (isEmpty(rolesData) || rolesData.length === 0){
            actions.push({label: 'Assign Role', value: 'ASSIGN_ROLE'});
            actions.push({label: 'Remove User', value: 'REMOVE_USER'});
            return actions;
        }

        if (rolesData[0].id !== 'PRODUCT_ADMIN' && rolesData[0].id !== 'PRODUCT_OWNER'){
            actions.push({label: 'Remove Role', value: 'REMOVE_ROLE'});
        }

        actions.push({label: 'Remove User', value: 'REMOVE_USER'});
        return actions;
    };

    const formatRolesData = (rolesData) =>{
        if (isEmpty(rolesData)){
            return null
        }

        let roles = rolesData.map(item => {
            return {label: item.name, value: item.id}
        });

        roles.unshift({label: 'All', value: 'ALL'});
        return roles;
    };

    const getAllData = () => {
        const {userManagementFilter} = props;

        setUserListData(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            skipCount: userManagementFilter.skipCount,
            limitCount: userManagementFilter.limitCount,
            filters:{
                'roleId' : userManagementFilter.role === 'ALL' ? '' : userManagementFilter.role,
                "keyword": userManagementFilter.keyword
            }
        };

        getAllUserData(payload)
            .then(res => {
                if (res?.success) {
                    setUserListData(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        userData: res.data?.users,
                        listingCount: isEmpty(res.data?.count) ? 0 : res.data?.count,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setUserListData(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const getUserSummaryData = () =>{
        getUserSummary()
            .then(res => {
                if (res?.success) {
                    setUserListData(prevState => ({
                        ...prevState,
                        summaryData: res.data,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setUserListData(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const handleRefreshButton = () =>{
        getAllData();
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setUserListingSearchKeyword(value);
            return
        }

        if (name === 'role') {
            setUserListingSearchRole(value);
        }
    };

    const resetPagination = () => {
        setLimitCount(50);
        setRowsPerPage(50);
        setPageCount(0);
        setSkipCount(0);
    };

    const getValueItems = (value) => {
        const {summaryData} = userListData;

        if (isEmpty(summaryData) || isEmpty(summaryData?.items)){
            return '-';
        }
        const item = summaryData.items.find(role => role.key === value);
        return item ? item.count : '-';
    };

    const handleChangeRowsPerPage = (event) => {
        setPageCount(0);
        setSkipCount(0);
        setLimitCount(event.target.value);
        setRowsPerPage(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        const isForward = newPage > page;
        if (isForward) {
            setSkipCount(skipCount + rowsPerPage);
            setLimitCount(rowsPerPage);
        } else {
            setSkipCount(skipCount - rowsPerPage);
            setLimitCount(rowsPerPage);
        }
        setPageCount(newPage);
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount} = userListData;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    const openAssignModel = () => {
        setUserListData(prevState => ({
            ...prevState,
            isCreateModalOpen: true
        }));
    };

    const handleMenuClick = (value, data) => {
        switch (value) {
            case "ASSIGN_ROLE":
                openModel("isAssignRoleModalOpen", data);
                break;
            case "REMOVE_ROLE":
                openModel("isDeleteRoleModalOpen", data);
                break;
            case "REMOVE_USER":
                openModel("isDeleteModalOpen",data);
                break;
            default:
        }
    };

    const openModel = (model, data) => {
        setUserListData(prevState => ({
            ...prevState,
            [model]: true,
            modelData: data
        }));
    };

    const handleCloseModal = (model) => {
        setUserListData((prevState) => ({
            ...prevState,
            [model]: false,
            modelData: null
        }));
    };

    const handleCloseNewUser = () => {
        setUserListData((prevState) => ({
            ...prevState,
            isNewUserOpen: false
        }))
    };

    if (isEmpty(userListData.UIState)){
        return <DLLoader isVisible={true} type={'screen'}/>
    }

    return (
            <UserManagementView
                {...props}
                {...userListData}
                {...props.userManagementFilter}
                onChangeFilter={onChangeFilter}
                handleRefreshButton={handleRefreshButton}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                page={page}
                getValueItems={getValueItems}
                openAssignModel={openAssignModel}
                setUserListData={setUserListData}
                handleCloseModal={handleCloseModal}
                handleCloseNewUser={handleCloseNewUser}
                getPaginationNextButtonStatus={getPaginationNextButtonStatus}
                refreshFullScreen={refreshFullScreen}
                getActionOptions={getActionOptions}
                handleMenuClick={handleMenuClick}
                openModel={openModel}
            />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    userManagementFilter: state.userManagementState.userManagementFilter,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(UserManagement);
