import React, {useState} from 'react';
import {connect} from "react-redux";

import {isEmpty} from "../../../../utils/validations";

import ComplexityModelView from "./ComplexityModelView";
import {updateProductComplexity} from "../../../productManagementAction";
import {showToastMessage} from "../../../../appComponents/AppActions";
import store from "../../../../store";

const ComplexityModel = (props) => {

    const {handleCloseModal, getAllData} = props;

    const [complexityState, setComplexityState] = useState({
        isModalOpen: false,
        selectedComplexity: '',
        errorObj: {
            selectedComplexityError: "",
        },
        responseError: ''
    });

    const validateChanges = () => {
        const {errorObj} = complexityState;
        if (isEmpty(complexityState.selectedComplexity)) {
            errorObj.selectedComplexityError = 'Please enter the complexity value';
        } else if ((complexityState.selectedComplexity) > 10 || (complexityState.selectedComplexity) <= 0) {
            errorObj.selectedComplexityError = 'Please enter value between 1-10';
        } else {
            errorObj.selectedComplexityError =''
        }
        setComplexityState((prevState) => ({
            ...prevState,
            errorObj: {
                ...prevState.errorObj,
                ...errorObj,
            }
        }));
        return errorObj;
    };

    const submitComplexity = () => {
        const {selectedComplexity} = complexityState;
        let errors = validateChanges();

        if (errors && errors.selectedComplexityError !== '') {
            return
        }

        setComplexityState(prevState => ({...prevState, isShowLoader: true}));

        const payload = {
            "id": props.productDetails.id,
            "complexity": parseInt(selectedComplexity)
        };

        updateProductComplexity(payload)
            .then((res) => {
                if (res.success) {
                    setComplexityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                    getAllData(props.productDetails.id);
                    handleCloseModal('isComplexityModalOpen');
                    store.dispatch(showToastMessage('success', 'Product complexity is updated'))
                } else {
                    setComplexityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        errorObj: {
                            ...prevState.errorObj,
                            responseError: res?.__error
                        }
                    }));
                }
            });
    };

    const onSelectComplexity = (complexityValue) => {
        setComplexityState((prevState) => ({
            ...prevState,
            selectedComplexity: complexityValue,
            responseError: '',
            errorObj: {
                ...prevState.errorObj,
                selectedComplexityError: "",
                responseError: '',
            }
        }));
    };

    return (
        <ComplexityModelView
            {...props}
            {...complexityState}
            submitComplexity={submitComplexity}
            onSelectComplexity={onSelectComplexity}
            validateChanges={validateChanges}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(ComplexityModel);
