import React, {Component} from 'react';
import PageNotAccessibleView from "./PageNotAccessibleView";
import {connect} from "react-redux";
import {setPrevLocation, setPrevRoutes} from "../../../appComponents/AppActions";
import {withRouter} from "react-router-dom";

class PageNotAccessible extends Component {

  handleClick = () =>{
    this.props.history.push('/');
  };

  render() {
    return (
      <PageNotAccessibleView
        {...this.props}
        handleClick={this.handleClick}
      />
    );
  }
}


PageNotAccessible.propTypes = {};
const mapStateToProps = (state) => ({
  userAuthStatus: state.userState.userAuthStatus,
  profile: state.userState.profile,
});

export default connect(mapStateToProps, {
  setPrevLocation,
  setPrevRoutes
})(withRouter(PageNotAccessible));