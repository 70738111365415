import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import ProductDetailsView from "./ProductDetailsView";
import {APP_CONST} from "../../appComponents/AppConstants";
import {isEmpty} from "../../utils/validations";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {getProductDetailsData} from "../productManagementAction";


const ProductDetails = (props) => {
    const [productDetailsState, setProductDetailsState] = useState({
        isReAssignOwnerModalOpen: false,
        isReAssignAdminModalOpen: false,
        isComplexityModalOpen: false,
        isQASamplingModalOpen: false,
        lowerQASampling: '-',
        upperQASampling: '-',
        productDetails: null,
        isShowLoader: false,
        UIState: '',
    });

    useEffect(() => {
        let id = props.match?.params?.productId;
        !isEmpty(id) && getAllData(id);
    }, []);


    const getAllData = (productId) => {

        setProductDetailsState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        getProductDetailsData(productId)
            .then(res => {
                if (res?.success) {
                    if(isEmpty(res.data)) {
                        setProductDetailsState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_UNAVAILABLE,
                        }));
                    } else {
                        setProductDetailsState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            productDetails: res.data,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                        }));
                    }
                } else {
                    setProductDetailsState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const openModel = (model, data) => {
        setProductDetailsState(prevState => ({
            ...prevState,
            [model]: true,
            modelData: data
        }));
    };

    const handleCloseModal = (model) => {
        setProductDetailsState((prevState) => ({
            ...prevState,
            [model]: false,
            modelData: null
        }));
    };

    if (isEmpty(productDetailsState.UIState)){
        return <DLLoader isVisible={true} type={'screen'}/>
    }

    if (productDetailsState.UIState === APP_CONST.CONTENT_UNAVAILABLE){
        return ;
    }

    return (
        <ProductDetailsView
            {...props}
            {...productDetailsState}
            openModel={openModel}
            handleCloseModal={handleCloseModal}
            getAllData={getAllData}
        />
    );
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(ProductDetails);