import React from 'react';
import DLButton from "../../core/components/DLButton/DLButton";
import DLModal from "../../core/components/DLModal/DLModal";
import DLModalTitle from "../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../core/components/DLModal/DLModalActions/DLModalActions";
import DLText from "../../core/components/DLText/DLText";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {isEmpty} from "../../utils/validations";
import DLRadioGroup from "../../core/components/DLRadioGroup/DLRadioGroup";

const DeleteDocumentView = (props) => {
    const {history, isDeleteRoleModalOpen, handleCloseModal, deleteRole} = props;

    const renderErrorUI = () => {
        const {responseError} = props;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'py-2'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    const renderDeleteDocumentModal = () => {
        const {modelData, handleChange, roleValue, roleOptions} = props;

        if (isEmpty(roleOptions)) {
            return <></>
        }

        return (
            <DLModal
                history={history}
                maxWidth={"xs"}
                onClose={() => handleCloseModal("isDeleteRoleModalOpen")}
                open={isDeleteRoleModalOpen}
                id={'delete-modal'}>
                <DLModalTitle
                    id={'delete-title'}
                    onClose={() => handleCloseModal("isDeleteRoleModalOpen")}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'delete-container'}>
                    <div>
                        {renderErrorUI()}
                        <div className=''>
                            <div className='py-2'>
                                <DLText id={'delete-message'}
                                        text={`Are you sure you want to remove role for ${modelData.userName}?`}
                                        fontSize={"sm"}
                                        marginBottom={'xs'}
                                        fontWeight={"normal"}
                                />
                            </div>
                            <DLRadioGroup id={'assign-radio-btn'}
                                          options={roleOptions}
                                          onChangeValue={(value) => {
                                              handleChange('roleValue', value)
                                          }}
                                          label={''}
                                          value={roleValue}
                                          type={'block'}
                                          labelFontSize={'sm'}
                                          labelFontColor={'black'}
                            />
                        </div>
                    </div>
                </DLModalContainer>
                <DLModalActions id={'delete-action'}>
                    <div className='d-flex gap-3 mt-3 mb-3'>
                        <DLButton
                            id={'delete-cancel-btn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"none"}
                            onClick={() => {
                                handleCloseModal("isDeleteRoleModalOpen")
                            }}/>
                        <DLButton
                            id={'delete-assign-btn'}
                            history={props.history}
                            label={'Remove'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"none"}
                            type={"danger"}
                            onClick={() => {
                                deleteRole()
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    const renderLoader = () => {
        const {isShowLoader} = props;
        return (
            <DLLoader type={"screen"} isVisible={isShowLoader}/>
        )
    };

    return (
        <div>
            {renderDeleteDocumentModal()}
            {renderLoader()}
        </div>
    );
};

export default DeleteDocumentView;