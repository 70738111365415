import Select, {components} from "react-select";
import React, {Component} from "react";
import {getTheme, isEmpty} from "../../DLComponentHelper";


const DLDropDownEditViewStyle = {
  container: (base) => ({
    ...base,
    display: 'inline-block',
    width: '100%',
    fontWeight: 500,
    color: 'gray',
  }),

  singleValue: (base) => ({
    ...base,
    color: 'grey',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '170px!important',

  }),
  menu: base => ({
    ...base,
    marginTop: '0 !important',
    zIndex: 9999,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: (
      state.isSelected ? '#e0e0e0 !important' : '#ffffff !important'
      && state.isFocused ? '#eaeaea !important' : '#ffffff'),
    color: '#212529',

  }),

  placeholder: () => ({
    fontWeight: 400,
    color: 'inherit',
    fontSize: '16px',
    marginRight: '4px',
  }),

  control: (base, state) => ({
    ...base,
    boxShadow: 'none !important',
    // color: 'grey',
    fontSize: '14px',

    paddingLeft: state.isFocused ? "5px" : "5px",
    paddingRight: state.isFocused ? "5px" : "5px",
    borderColor: state.isSelected ? "#c4bebe" : "#ffffff" && state.isFocused ? "#c4bebe" : "#ffffff",
    "&:hover": {
      borderColor: state.isFocused ? "#c4bebe" : "#ffffff" && state.isSelected ? "#c4bebe" : "#ffffff"
    }
  }),

};

class DLDropDownEditView extends Component {


  render() {

    const {label, value, options, onSelect} = this.props;

    let theme = getTheme();

    const DropdownIndicator = (props) => {
      return components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="fas fa-pencil-alt pl-2" style={{position: 'initial', color: 'black'}}/>
        </components.DropdownIndicator>
      );
    };

    return (
      <>
        <div className=' d-flex flex-wrap align-items-baseline justify-content-between w-100'>
          {
            isEmpty(label) ?
              null :
              <div className='font-weight-bold ' style={{
                fontSize: theme?.default?.fontSize,
              }}>
                {label}:
              </div>}
          <div className={'flex-grow-1'}>
            <Select
              isMulti={false}
              styles={DLDropDownEditViewStyle}
              isSearchable={false}
              isClearable={this.props.isClearable}
              value={options.find((opt) => opt.value === value).label}
              placeholder={options.find((opt) => opt.value === value).label}
              options={options}
              className={this.props.options.find((option) => option.value === this.props.value)?.color}
              components={{DropdownIndicator, IndicatorSeparator: () => null}}
              onChange={(e) => {
                onSelect(e.value)
                document.activeElement.blur();
              }}
            />
          </div>
        </div>
      </>
    )
  }
}


export default DLDropDownEditView;
