const appTheme = {
    appColor: {
        primary: '#009374',
        secondary: '#FF9800',
        tertiary: '#FFF6E8',
        success: '#029C48',
        successLight: '#E1FFE5',
        danger: '#D72F2F',
        darkBlue:'#4F46E5',
        lightBlue: '#FAFAFA',
        grayLight: '#F4F4F4',
        grayDark: '#666666',
        gray: '#c5c5c5',
        disable: '#999999',
        black: '#282828',
        white: '#fff',
        info: '#E7EFFF',
        yellow: '#FFF500',
        tableBorder :'#e0e0e0'
    },
    linkStyle: {
        color: '#0073ff'
    },
    fontSizes: {
        'xxs': 10,
        'xs': 12,
        'sm': 14,
        'md': 16,
        'lg': 20,
        'xl': 24,
        'xxl' : 28,
        'xxxl' : 32
    },
    marginBottoms: {
        'none': 0,
        'xs': '0.25rem',
        'sm': '0.50rem',
        'md': '1rem',
        'lg': '1.5rem',
        'xl': '3rem'
    },
    default: {
        fontSize: 14,
        fontFamily: "'Poppins', sans-serif",
        btnBorderType: 'square',
        color: '#282828'
    },
};

export default appTheme;


