import React from 'react';

import DLModalTitle from "../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModal from "../../../../core/components/DLModal/DLModal";
import DLModalContainer from "../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLSearchDropdown from "../../../../core/components/DLSearchDropdown/DLSearchDropdown";
import DLText from "../../../../core/components/DLText/DLText";
import appTheme from "../../../../assets/appTheme";
import {isEmpty} from "../../../../utils/validations";

const ReAssignAdminModelView = (props) => {

    const {
        handleCloseModal,
        isReAssignAdminModalOpen,
        onReAssignAdmin,
        isShowInlineLoader,
        userSearchData,
        searchUser,
        onChangeFilter,
        searchKeyword,
        addSearchId,
        isAdminSeleted,
        selectedAdmin,
        getChangeAdmin
    } = props;


    const renderSearchField = () => {
        return (
            <DLSearchDropdown
                placeholder={"Search..."}
                isShowInlineLoader={isShowInlineLoader}
                data={userSearchData}
                onChangeData={(e) => searchUser(e)}
                value={searchKeyword}
                onChangeValue={(value) => onChangeFilter(value)}
                onClickItem={(id) => addSearchId(id)}
                helperMessage={'Please select scheme'}
                disableHelperMessageHeight={true}
                dropdownHeight={'130px'}
            />
        )
    };

    const renderAssignAdminField = () => {
        return (
            <div className='d-flex justify-content-between'>
                <DLText id={'selected-admin-field'}
                        text={selectedAdmin.userName}
                        fontSize={"sm"}
                        marginBottom={'sm'}
                        fontWeight={"normal"}
                />
                <div className='cursor'
                     onClick={()=>getChangeAdmin()}>
                    <DLText id={'admin-field'}
                            text={'Change Admin'}
                            fontSize={"sm"}
                            marginBottom={'sm'}
                            fontWeight={"normal"}
                            customTextStyle={{color: appTheme.linkStyle.color}}
                />
                </div>
            </div>
        )
    };


    const renderErrorUI = () => {
        const {errorObj} = props;
        const {responseError} = errorObj;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'mt-1'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    const renderReAssignAdminModal = () => {
        return (
            <div>
                <DLModal
                    history={props.history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseModal('isReAssignAdminModalOpen')}
                    open={isReAssignAdminModalOpen}
                    id={'assign-admin-modal'}>
                    <DLModalTitle
                        id={'assign-admin-title'}
                        onClose={() => handleCloseModal('isReAssignAdminModalOpen')}
                        title={'Re-assign Admin'}/>
                    <DLModalContainer
                        id={'assign-admin-container'}>
                        {renderErrorUI()}
                        <div className='my-2' style={{minHeight: '200px'}}>
                            <DLText id={'admin-title'}
                                    text={'Admin'}
                                    fontSize={"xs"}
                                    fontColor={'grayDark'}
                                    marginBottom={'xs'}
                                    fontWeight={"normal"}
                            />
                            {!isAdminSeleted && renderSearchField()}
                            {isAdminSeleted && renderAssignAdminField()}
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'admin-assign-action'}>
                        <div className='d-flex gap-3 mt-3 mb-3'>
                            <DLButton
                                id={'assign-admin-cancelBtn'}
                                history={props.history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"none"}
                                onClick={() => handleCloseModal('isReAssignAdminModalOpen')}/>
                            <DLButton
                                id={'assign-admin-assignBtn'}
                                history={props.history}
                                label={'Assign'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"none"}
                                onClick={() => onReAssignAdmin()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    return (renderReAssignAdminModal());
};

export default ReAssignAdminModelView;