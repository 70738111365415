export const headingData = ['Product Name', 'Owner', 'Admin', 'Last updated', 'Complexity', 'QA Sampling %']

export const cellConfig = [
    {
        paddingLeft: '15px',
        paddingRight: '15px',
        minWidth: '180px',
    },
    {
        marginLeft: '0px',
        paddingLeft: '0px',
        minWidth: '180px',
    },
    {
        marginLeft: '0px',
        paddingLeft: '0px',
        minWidth: '180px',
    },
    {
        marginLeft: '20px',
        paddingLeft: '0px',
        minWidth: '180px',
    },
    {
        marginLeft: '20px',
        paddingLeft: '0px',
        textAlign: 'center',
        minWidth: '180px',
    },
    {
        marginLeft: '30px',
        paddingLeft: '0px',
        textAlign: 'center',
        minWidth: '180px',
    },
];


