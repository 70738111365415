import {
    httpGet,
    httpPost, httpPut,
    removeAuthorizationToken,
    setAuthorizationToken
} from "../utils/httpHelper";
import store from "../store";
import {
    GetMyProfileData,
    profileCallFired,
    userLogoutData
} from "./userReducer";
import {showSystemErrorBlock} from "../appComponents/AppActions";

// let base_api = 'http://localhost:10217'

export const userLogin = (data) => {
    return httpPost('/service/provisioning/signIn', data, true);
};

export const sendForgetPasswordLink = (payload) => {
    return httpPost('/service/provisioning/user/password/sendResetLink', payload, true)
};

export const onChangePassword = (payload) => {
    let url = '/service/provisioning/user/password';

    return httpPut(url, payload, true).then(res => {
        return res;
    });
};

export const resetPassword = (payload) => {
    return httpPost('/service/provisioning/user/password/', payload);
};

export const validateResetLink = (id, handleErrorInComponent) => {
    return httpPost('/service/provisioning/user/password/validateResetLink', {code: id}, handleErrorInComponent)
};

export const setTokenAfterLogin = async (res, rememberMe) => {
    let authResponse = await setAuthorizationToken(res.token, rememberMe);
    return !!authResponse.status;
};

export const getMyProfile = () => {
    return httpGet('/service/provisioning/user/profile')
        .then(res => {
                store.dispatch(profileCallFired());
                if (res.success){
                    store.dispatch(GetMyProfileData(res.data));
                }
                return res;
            }
        ).catch(()=>{
            store.dispatch(showSystemErrorBlock())
        })
};

export const logoutUser = () => () => {
    removeAuthorizationToken();
    userLogoutData();
};

