import React, {useEffect, useState} from "react"
import {connect} from "react-redux";

import ProductListView from "./ProductListView";
import {APP_CONST} from "../../appComponents/AppConstants";
import {setLimitCount, setPageCount, setRowsPerPage, setSkipCount, getAllProductData, setSearchKeyword} from "../productManagementAction";
import {isEmpty} from "../../utils/validations";
import DLLoader from "../../core/components/DLLoader/DLLoader";

const ProductList = (props) => {
    const [productListState, setProductListState] = useState({
        listingCount: 0,
        productsDetails: [],
        isShowLoader: false,
        UIState: ''
    });


    useEffect(() => {
        getAllData();
    }, [props.productManagementFilter]);
    const {page, rowsPerPage, skipCount, limitCount} = props.productManagementFilter;

    const getAllData = () => {
        const {productManagementFilter} = props;

        setProductListState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            skipCount: productManagementFilter.skipCount,
            limitCount: productManagementFilter.limitCount,
            filters:{
                "keyword": productManagementFilter.keyword
            }
        };

        getAllProductData(payload)
            .then(res => {
                if (res?.success) {
                    setProductListState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        productsDetails: res.data?.products,
                        listingCount: isEmpty(res.data?.count) ? 0 : res.data?.count,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setProductListState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const onChangeFilter = (name, value) => {
        resetPagination();
        if (name === 'keyword') {
            setSearchKeyword(value);
        }
    };

    const resetPagination = () => {
        setLimitCount(50);
        setRowsPerPage(50);
        setPageCount(0);
        setSkipCount(0);
    };

    const handleChangeRowsPerPage = (event) => {
        setPageCount(0);
        setSkipCount(0);
        setLimitCount(event.target.value);
        setRowsPerPage(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        const isForward = newPage > page;
        if (isForward) {
            setSkipCount(skipCount + rowsPerPage);
            setLimitCount(rowsPerPage);
        } else {
            setSkipCount(skipCount - rowsPerPage);
            setLimitCount(rowsPerPage);
        }
        setPageCount(newPage);
    };

    const getPaginationNextButtonStatus = () => {
        const {listingCount} = productListState;
        let val = false;
        if (listingCount <= rowsPerPage) {
            val = true;
        } else if ((skipCount + limitCount) >= listingCount) {
            val = true;
        }
        return val;
    };

    if (isEmpty(productListState.UIState)){
        return <DLLoader isVisible={true} type={'screen'}/>
    }

    return (
        <ProductListView
            {...props}
            {...productListState}
            {...props.productManagementFilter}
            onChangeFilter={onChangeFilter}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            getPaginationNextButtonStatus={getPaginationNextButtonStatus}
        />
    )
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile,
    productManagementFilter: state.productManagementState.productManagementFilter,
});

export default connect(mapStateToProps, {})(ProductList);