import React from 'react';
import {connect} from 'react-redux';
import {Route, withRouter} from 'react-router-dom';

const PrePostLoginRoute = ({component: Component,profile,userAuthStatus, prevLocation, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            let uiContent;
            uiContent = (<Component {...props} />);

            return uiContent
        }

        }
    />
);

PrePostLoginRoute.propTypes = {
  // userAuthStatus: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userAuthStatus: state.userState.userAuthStatus,
  profile: state.userState.profile,
  prevLocation: state.appState.prevLocation
});

export default connect(mapStateToProps)(withRouter(PrePostLoginRoute));