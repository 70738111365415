import React, {Component} from 'react';
import {isEmpty} from "../../../utils/validations";
import {Link, withRouter} from 'react-router-dom';
import {getWebRouteInfoFromUrl} from "../../../utils/deepLinkHelper";
import {userConst} from "../../../userAuth/userConsts";


class PostLoginRedirectView extends Component {

  componentDidMount() {

    if(this.props.openDialogOnMount){
      this.handleRoute();
    }
  }

  handleRoute = (event) => {

    let {onClick, userAuthStatus, setPostLoginUrl, afterLoginUrl,afterLoginUrlProps, stopEventPropagation} = this.props;

    if(stopEventPropagation) {
      event.stopPropagation();
      document.activeElement.blur();
    }

    if(onClick){
      onClick();
      return;
    }

    if(userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
      if(isEmpty(afterLoginUrl)){
        this.props.history.push('/');
      } else {
        this.props.history.push({
          pathname: afterLoginUrl,
          ...afterLoginUrlProps
        });
      }

    } else {

      if(isEmpty(afterLoginUrl)){
        setPostLoginUrl('/',{
          ...afterLoginUrlProps,
        });
      } else {

        let webRouteInfo = getWebRouteInfoFromUrl(afterLoginUrl);
       if(isEmpty(webRouteInfo)){
         setPostLoginUrl(afterLoginUrl,{
           ...afterLoginUrlProps,
         });
       } else {
         if(webRouteInfo.type === 'postLogin' || afterLoginUrlProps?.openAsPopUp ){
           setPostLoginUrl(afterLoginUrl,{...afterLoginUrlProps});
         } else {
           this.props.history.push({
             pathname: afterLoginUrl,
             ...afterLoginUrlProps
           });
         }
       }
      }

    }

  };


  getUrl = () => {

    let {afterLoginUrl,afterLoginUrlProps} = this.props;

    return {
      pathname: afterLoginUrl,
      ...afterLoginUrlProps
    };

  };

  render() {

    const {children} = this.props;

    return (
      <Link className="font-black" to={this.getUrl()} onClick={e => {
        e.preventDefault();
        this.handleRoute(e)
      }}>
        {children}
      </Link>
    );


    // return (
    //   <div className="d-inline cursor" onClick={ isEmpty(onClick) ?  this.handleRoute : onClick}>
    //     {children}
    //   </div>
    // );
  }
}

PostLoginRedirectView.propTypes = {};


export default withRouter(PostLoginRedirectView);
