import React from 'react';

import DLButton from "../../core/components/DLButton/DLButton";
import DLModal from "../../core/components/DLModal/DLModal";
import DLModalTitle from "../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../core/components/DLModal/DLModalActions/DLModalActions";
import DLText from "../../core/components/DLText/DLText";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLInputTextField from "../../core/components/DLInputTextField/DLInputTextField";
import DLCheckbox from "../../core/components/DLCheckbox/DLCheckbox";
import appTheme from "../../assets/appTheme";
import DLSearchDropdown from "../../core/components/DLSearchDropdown/DLSearchDropdown";
import {isEmpty} from "../../utils/validations";

const CreateTaskView = (props) => {

    const renderModalUI = () =>{
        const {searchKeyword, searchUser, userSearchData, userName, personId, isTrainee, firstName, lastName,
            email, onChangeValue, addSearchId, onChangeFilter, isShowInlineLoader,} = props;

        const {firstNameError, lastNameError, emailError, userNameError} = props.errorObj;

        return (
            <div>
                {renderErrorUI()}
                <div className='mt-2 mb-3'>
                    <DLSearchDropdown
                        placeholder={'Search by User Name, Email'}
                        isShowInlineLoader={isShowInlineLoader}
                        data={userSearchData}
                        onChangeData={(e) => searchUser(e)}
                        value={searchKeyword}
                        onChangeValue={(value) => onChangeFilter(value)}
                        onClickItem={(id) => addSearchId(id)}
                        helperMessage={'Please select scheme'}
                        disableHelperMessageHeight={true}
                    />
                </div>
                <div className='d-flex flex-row gap-3'>
                    <div className={'filter-md'} style={{minWidth: '48%'}}>
                        <DLInputTextField id={'first-name'}
                                          label={'First Name'}
                                          value={firstName}
                                          placeholder={''}
                                          isRequired={true}
                                          isDisabled={!isEmpty(personId)}
                                          disableHelperMessageHeight={true}
                                          error={firstNameError !== ''}
                                          maxCharLength={51}
                                          helperMessage={firstNameError}
                                          onChangeValue={(value) => {
                                              onChangeValue('firstName', value)
                                          }}/>
                    </div>
                    <div className={'filter-md'} style={{minWidth: '48%'}}>
                        <DLInputTextField id={'last-name'}
                                          label={'Last Name'}
                                          value={lastName}
                                          placeholder={''}
                                          maxCharLength={51}
                                          isRequired={true}
                                          isDisabled={!isEmpty(personId)}
                                          error={lastNameError !== ''}
                                          helperMessage={lastNameError}
                                          disableHelperMessageHeight={true}
                                          onChangeValue={(value) => {
                                              onChangeValue('lastName', value)
                                          }}/>
                    </div>
                </div>
                <div className='d-flex gap-2'>
                    <div style={{flex: 1}}>
                        <DLInputTextField id={'email'}
                                          label={'Email'}
                                          value={email}
                                          placeholder={''}
                                          isRequired={true}
                                          maxCharLength={51}
                                          error={emailError !== ''}
                                          helperMessage={emailError}
                                          isDisabled={!isEmpty(personId)}
                                          disableHelperMessageHeight={true}
                                          onChangeValue={(value) => {
                                              onChangeValue('email', value)
                                          }}/>
                    </div>
                    <div>
                        <div>
                            <DLText id={'delete-message'}
                                    text={'Is Trainee?'}
                                    fontSize={"xs"}
                                    fontColor={'grayDark'}
                                    marginBottom={'sm'}
                                    fontWeight={"normal"}
                            />
                        </div>

                        <DLCheckbox
                            id={'mark-as-reviewed-dayCheck'}
                            fontSize={"sm"}
                            disableHelperMessageHeight={true}
                            onChangeValue={() => {
                                onChangeValue('isTrainee', !isTrainee)
                            }}
                            labelPlacement={'top'}
                            checkboxcolor={appTheme.appColor.primary}
                            marginBottom={'none'}
                            isChecked={isTrainee}
                        />
                    </div>
                </div>
                <div>
                    <DLInputTextField id={'username'}
                                      label={'User Name'}
                                      value={userName}
                                      placeholder={''}
                                      isRequired={true}
                                      disableHelperMessageHeight={true}
                                      error={userNameError !== ""}
                                      helperMessage={userNameError}
                                      onChangeValue={(value) => {
                                          onChangeValue('userName', value)
                                      }}/>
                </div>
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseError} = props.errorObj;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'py-2'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    const renderCreateTaskModal = () => {
        const {isNewUserOpen, handleCloseNewUser, createUser, keyword, onChangeFilter} = props;
        return (
            <div>
                <DLModal
                    history={props.history}
                    maxWidth={"xs"}
                    onClose={() => handleCloseNewUser()}
                    open={isNewUserOpen}
                    id={'assign-modal'}>
                    <DLModalTitle
                        id={'assign-title'}
                        onClose={() => handleCloseNewUser()}
                        title={'Add New User'}/>
                    <DLModalContainer
                        id={'assign-container'}>
                        {renderModalUI()}
                    </DLModalContainer>
                    <DLModalActions id={'assign-action'}>
                        <div className='d-flex gap-3 mt-3 mb-3'>
                            <DLButton
                                id={'assign-cancel-btn'}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"none"}
                                onClick={() => handleCloseNewUser()}/>
                            <DLButton
                                id={'assign-assign-btn'}
                                label={'Add User'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"none"}
                                onClick={() => createUser()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        const {isShowLoader} = props;
        return (
            <DLLoader type={"screen"} isVisible={isShowLoader}/>
        )
    };

    return (
        <div>
            {renderLoader()}
            {renderCreateTaskModal()}
        </div>
    );
};

export default CreateTaskView;