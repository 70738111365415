import React from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import DLInputTextField from "../../core/components/DLInputTextField/DLInputTextField";
import DLButton from "../../core/components/DLButton/DLButton";
import DLText from "../../core/components/DLText/DLText";

import appTheme from "../../assets/appTheme";
import {isEmpty} from "../../utils/validations";

const ChangePasswordView = (props) => {
    const {onChangeFormData, onBlurInputText, ChangePasswordData, setAutoFocusOn} = props;
    const {
        currentPassword, currentPasswordError, showCurrentPassword, password, passwordError, showPassword, confirmPassword, showConfirmPassword, confirmPasswordError
    } = ChangePasswordData.formData;
    const {autoFocusOn, responseError} = ChangePasswordData;

    const submitForm = (e) => {
        if (e) {
            e.preventDefault();
        }
        props.onSubmit();
    };

    const checkKey = (event) => {
        if (event?.key === 'Enter') {
            event.preventDefault();
            setAutoFocusOn();
            props.onSubmit();
        }
    };

    return (
        <div className={'page-container'} style={{minHeight: 'calc(100dvh - 225px)'}}>
            <div className="d-flex align-items-center pt-3">
                <i className="fa-solid fa-chevron-left pe-2 cursor header-icon"
                   onClick={() => props.history.goBack()}/>
                <DLText id={'change-password-title'}
                        text={'Change Password'}
                        fontSize={"xl"}
                        fontColor={'primary'}
                        marginBottom={'none'}
                        fontWeight={"semi-bold"}
                />
            </div>
            <div className='py-4 mt-2 d-flex justify-content-center'>
                <div className='row '>
                    <form onSubmit={(e) => submitForm(e)}
                          onKeyPress={(e) => checkKey(e)}
                          style={{width: '519px'}}>
                        <div className='panel'>
                            <div className=' py-4 px-4' id={'form'}>
                                {!(isEmpty(responseError)) &&
                                <div className='pb-4'>
                                    <span style={{
                                        color: appTheme.appColor.danger,
                                        fontSize: 16,
                                        fontWeight: 500
                                    }}>{responseError}</span>
                                </div>
                                }
                                <DLInputTextField id={'changePassword-currentPassword'}
                                                  label={"Current Password"}
                                                  value={currentPassword}
                                                  isRequired={true}
                                                  onChangeValue={(value) => onChangeFormData('currentPassword', value)}
                                                  isClearable={false}
                                                  error={currentPasswordError !== ''}
                                                  helperMessage={currentPasswordError}
                                                  disableHelperMessageHeight={true}
                                                  marginBottom={'sm'}
                                                  inputType={showCurrentPassword ? 'text' : 'password'}
                                                  onBlur={() => onBlurInputText('currentPassword')}
                                                  onPaste={(e) => {
                                                      e.preventDefault();
                                                      return false;
                                                  }}
                                                  autoFocus={autoFocusOn === 'currentPassword'}
                                                  InputProps={{
                                                      endAdornment:
                                                          <InputAdornment position="end">
                                                              <IconButton
                                                                  className="p-0 m-0"
                                                                  id={'ChangePassword-showCurrentPassword'}
                                                                  onClick={() => onChangeFormData('showCurrentPassword', !showCurrentPassword)}
                                                                  aria-label="">
                                                                  {showCurrentPassword
                                                                      ?
                                                                      (<i className="fa-regular fa-eye"
                                                                          style={{
                                                                              fontSize: 18,
                                                                              color: appTheme.appColor.grayDark
                                                                          }}/>)
                                                                      :
                                                                      (
                                                                          <i className="fa-regular fa-eye-slash"
                                                                             style={{
                                                                                 fontSize: 18,
                                                                                 color: appTheme.appColor.grayDark
                                                                             }}/>)}
                                                              </IconButton>
                                                          </InputAdornment>
                                                  }}
                                />

                                <DLInputTextField id={'changePassword-password'}
                                                  label={('New password')}
                                                  value={password}
                                                  isRequired={true}
                                                  onChangeValue={(value) => onChangeFormData('password', value)}
                                                  isClearable={false}
                                                  error={passwordError !== ''}
                                                  helperMessage={passwordError}
                                                  disableHelperMessageHeight={true}
                                                  marginBottom={'sm'}
                                                  inputType={showPassword ? 'text' : 'password'}
                                                  onBlur={() => onBlurInputText('password')}
                                                  onPaste={(e) => {
                                                      e.preventDefault();
                                                      return false;
                                                  }}
                                                  autoFocus={autoFocusOn === 'password'}
                                                  InputProps={{
                                                      endAdornment:
                                                          <InputAdornment position="end">
                                                              <IconButton
                                                                  className="p-0 m-0"
                                                                  id={'ChangePassword-showPassword'}
                                                                  onClick={() => onChangeFormData('showPassword', !showPassword)}
                                                                  aria-label="">
                                                                  {showPassword
                                                                      ? (<i className="fa-regular fa-eye "
                                                                            style={{
                                                                                fontSize: 18,
                                                                                color: appTheme.appColor.grayDark
                                                                            }}/>)
                                                                      : (
                                                                          <i className="fa-regular fa-eye-slash"
                                                                             style={{
                                                                                 fontSize: 18,
                                                                                 color: appTheme.appColor.grayDark
                                                                             }}/>)
                                                                  }
                                                              </IconButton>
                                                          </InputAdornment>
                                                  }}
                                />

                                <DLInputTextField id={'changePassword-confirmPassword'}
                                                  label={('Confirm New Password')}
                                                  value={confirmPassword}
                                                  isRequired={true}
                                                  onChangeValue={(value) => onChangeFormData('confirmPassword', value)}
                                                  isClearable={false}
                                                  error={confirmPasswordError !== ''}
                                                  helperMessage={confirmPasswordError}
                                                  disableHelperMessageHeight={true}
                                                  inputType={showConfirmPassword ? 'text' : 'password'}
                                                  onBlur={() => onBlurInputText('confirmPassword')}
                                                  onPaste={(e) => {
                                                      e.preventDefault();
                                                      return false;
                                                  }}
                                                  autoFocus={autoFocusOn === 'confirmPassword'}
                                                  InputProps={{
                                                      endAdornment:
                                                          <InputAdornment position="end">
                                                              <IconButton
                                                                  id={'ChangePassword-showConfirmPassword'}
                                                                  className="p-0 m-0"
                                                                  onClick={() => onChangeFormData('showConfirmPassword', !showConfirmPassword)}
                                                                  aria-label="">
                                                                  {showConfirmPassword
                                                                      ? (<i className="fa-regular fa-eye"
                                                                            style={{
                                                                                fontSize: 18,
                                                                                color: appTheme.appColor.grayDark
                                                                            }}/>)
                                                                      : (<i className="fa-regular fa-eye-slash"
                                                                            style={{
                                                                                fontSize: 18,
                                                                                color: appTheme.appColor.grayDark
                                                                            }}/>)
                                                                  }
                                                              </IconButton>
                                                          </InputAdornment>
                                                  }}
                                />

                                <div className='d-flex gap-3 pt-3'>
                                    <DLButton id={'changePassword-cancel'}
                                              styleType={"outlined"}
                                              label={'Cancel'}
                                              sideMargin={"none"}
                                              buttonSize={"sm"}
                                              btnType={'button'}
                                              onClick={() => props.history.goBack()
                                              }/>
                                    <DLButton id={"changePassword-Submit-ChangePassword"}
                                              label={'Change Password'}
                                              sideMargin={"none"}
                                              btnType={'submit'}
                                              onClick={() => {
                                              }}
                                              buttonSize={"sm"}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordView;