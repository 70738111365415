import React from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {resetPasswordConst} from "../userConsts";
import DLInputTextField from "../../core/components/DLInputTextField/DLInputTextField";
import DLText from "../../core/components/DLText/DLText";
import DLButton from "../../core/components/DLButton/DLButton";
import appTheme from "../../assets/appTheme";

const ResetPasswordView = (props) => {
    const {resetPasswordState, isMobileView, isXSView, isApiInProgress} = props;
    const {UIState} = resetPasswordState;

    const resetPasswordUI = () => {
        const {resetPasswordState, onChangeFormData, handleResetPassword, onBlurInputText} = props;

        const {password, passwordError, confirmPassword, confirmPasswordError, showPassword, showConfirmPassword} = resetPasswordState;

        return (
            <div className="h-100 d-flex flex-column justify-content-center">
                <div>

                    <div className="mb-4 pb-2">
                        <DLText id={'reset-password-text-create-pw'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                text={'Create a New Password'}
                                fontSize={"xxl"}/>
                    </div>

                    <div className='mb-3'>
                        <DLInputTextField id={'set-new-password'}
                                          onChangeValue={(value) => onChangeFormData('password', value)}
                                          onBlur={() => onBlurInputText('password')}
                                          marginBottom={"none"}
                                          label={'Type new password'}
                                          value={password}
                                          isRequired={true}
                                          style={{fontSize: "16px"}}
                                          isClearable={false}
                                          inputType={showPassword ? 'text' : 'password'}
                                          error={passwordError !== ''}
                                          helperMessage={passwordError}
                                          onPaste={(e) => {
                                              e.preventDefault();
                                              return false;
                                          }}
                                          disableHelperMessageHeight={true}
                                          InputProps={{
                                              endAdornment:
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          className="p-0 m-0"
                                                          onClick={() => onChangeFormData('showPassword', !showPassword)}
                                                          aria-label="">
                                                          {showPassword
                                                              ? (<i className="fa-regular fa-eye"
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: appTheme.appColor.grayDark
                                                                    }}/>)
                                                              : (<i className="fa-regular fa-eye-slash"
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: appTheme.appColor.grayDark
                                                                    }}/>)
                                                          }
                                                      </IconButton>
                                                  </InputAdornment>
                                          }}
                        />
                    </div>

                    <div className='pb-3'>
                        <DLInputTextField id={'set-confirm-password'}
                                          onChangeValue={(value) => onChangeFormData('confirmPassword', value)}
                                          onBlur={() => onBlurInputText('confirmPassword')}
                                          isRequired={true}
                                          label={'Confirm new password'}
                                          value={confirmPassword}
                                          isClearable={false}
                                          style={{fontSize: "16px"}}
                                          inputType={showConfirmPassword ? 'text' : 'password'}
                                          error={confirmPasswordError !== ''}
                                          helperMessage={confirmPasswordError}
                                          disableHelperMessageHeight={true}
                                          onPaste={(e) => {
                                              e.preventDefault();
                                              return false;
                                          }}
                                          InputProps={{
                                              endAdornment:
                                                  <InputAdornment position="end">
                                                      <IconButton
                                                          className="p-0 m-0"
                                                          onClick={() => onChangeFormData('showConfirmPassword', !showConfirmPassword)}
                                                          aria-label="">
                                                          {showConfirmPassword
                                                              ? (<i className="fa-regular fa-eye "
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: appTheme.appColor.grayDark
                                                                    }}/>)
                                                              : (<i className="fa-regular fa-eye-slash "
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: appTheme.appColor.grayDark
                                                                    }}/>)
                                                          }
                                                      </IconButton>
                                                  </InputAdornment>
                                          }}
                        />
                    </div>

                    <div className='text-center mx-auto d-grid'>
                        <DLButton id={'set-new-password'}
                                  type={"primary"}
                                  onClick={() => handleResetPassword()}
                                  sideMargin={"none"}
                                  isLoaderButton={true}
                                  isApiInProgress={isApiInProgress}
                                  style={{padding: '0px 16px'}}
                                  buttonSize={"sm"}
                                  label={'Set New Password'}
                        />
                    </div>
                </div>
            </div>

        )
    };

    const renderResetPasswordSuccessUI = () => {
        const {t} = props;
        return (
            <div className="h-100 d-flex flex-column justify-content-center">
                <div>
                    <div className="text-center"
                         style={{marginBottom: 50}}>
                        <div className='mb-3'>
                            <i className="fa-solid fa-circle-check fa-2x" style={{color: appTheme.appColor.success}}/>
                        </div>
                        <DLText id={'pwd-success-text'}
                                marginBottom={"none"}
                                text={'Password Set Successfully'}
                                fontWeight={"semi-bold"}
                                fontColor={"success"}
                                fontSize={"lg"}/>
                    </div>
                    <div className='d-grid mx-auto text-center'>
                        <DLButton id={'pwd-success-login'}
                                  label={'Sign In'}
                                  onClick={() => props.history.push('/user/login')}
                                  fontSize={'md'}/>
                    </div>

                </div>
            </div>
        )
    };

    const renderUI = (UIState) => {

        switch (UIState) {
            case resetPasswordConst.NEW_PASSWORD:
                return resetPasswordUI();
            case resetPasswordConst.RESET_PASSWORD_SUCCESS:
                return renderResetPasswordSuccessUI();
            default:
                return (<></>);
        }
    };

    return (
        <div className={isXSView ? 'w-100 d-flex flex-column justify-content-center' : 'w-100 d-flex flex-column align-items-center justify-content-center'}
            style={{backgroundColor: "#F0F0F0", height: '100vh'}}>
            <div className="text-center mb-2">
                <DLText id={''}
                        text={'CM App'}
                        fontSize={"xxxl"}
                        fontWeight={"semi-bold"}
                />
            </div>
            <div className={isXSView ? "bg-white" : "panel bg-white"}
                 style={{width: isMobileView ? '' : "450px", minHeight: 515}}>
                {renderUI(UIState)}
            </div>
        </div>

    );
};

export default ResetPasswordView;
