import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {APP_CONST} from "../../../../../appComponents/AppConstants";
import {isEmpty} from "../../../../../utils/validations";
import store from "../../../../../store";
import {showToastMessage} from "../../../../../appComponents/AppActions";
import AddErrorView from "./AddErrorView";
import {addError, getProductCategoryErrorData, updateError} from "../../../../productManagementAction";

const AddError = (props) => {
    const [addErrorState, setAddErrorState] = useState({
        reason: "",
        categoryOptions: [
            {
                label: "Low",
                value: "LOW"
            },
            {
                label: "Medium",
                value: "MEDIUM"
            },
            {
                label: "High",
                value: "HIGH"
            }
        ],
        category: "",
        errorObj: {
            reasonError: "",
            categoryError: ""
        },
        isShowLoader: false
    });

    useEffect(() => {
        let {modelData}  = props;
        getProductCategoryError(modelData)
    }, []);

    const getProductCategoryError = (modelData) =>{
        if (isEmpty(modelData?.edit) || !modelData?.edit){
            return
        }

        getProductCategoryErrorData(modelData?.id, true)
            .then(res => {
                if (res?.success) {
                    setAddErrorState((prev) => ({
                        ...prev,
                        reason: res.data?.reason,
                        category: res.data?.category,
                        isShowLoader: false,
                        errorObj: {
                            ...prev.errorObj,
                            reasonError: "",
                            categoryError: ""
                        }
                    }));
                } else {
                    setAddErrorState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        errorObj: {
                            ...prevState.errorObj,
                            responseError: res?.__error
                        },
                    }));
                }
            });
    };

    const onChangeValue = (name, value) => {
        setAddErrorState((prev) => ({
            ...prev,
            [name]: value,
            errorObj: {
                ...prev.errorObj,
                [name + 'Error']: '',
                responseError: '',
            }
        }));
    };

    const onBlurInputTextField = (field) => {
        let errors = validateChanges(field, addPeptideState);
        setAddErrorState((prev) => ({
            ...prev,
            errorObj: {
                ...prev.errorObj,
                ...errors
            }
        }));
    };

    const validateChanges = (title, compState) => {
        const {reason, category} = addErrorState;
        let errorObj = {...compState};

        if (title === "reason" || title === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(reason)) {
                errorObj.reasonError = "Please enter a reason";
            } else if (reason.length > 250) {
                errorObj.reasonError = "Maximum 250 characters allowed";
            } else {
                errorObj.reasonError = "";
            }
            if (title === "reason") return errorObj;
        }
        if (title === "category" || title === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(category)) {
                errorObj.categoryError = "Please select a category";
            } else {
                errorObj.categoryError = "";
            }
            if (title === "category") return errorObj;
        }

        return errorObj;
    };

    const checkIfValidateObj = (validateObj) => {
        const {categoryError, reasonError} = validateObj;

        return !(categoryError || reasonError);
    };

    const addErrorData = () => {
        const {category, reason} = addErrorState;
        const {modelData} = props;
        let errorObj = validateChanges(APP_CONST.FORM_SUBMISSION, addErrorState.errorObj);

        setAddErrorState(prevStep => ({
            ...prevStep,
            errorObj: {
                ...errorObj
            },
        }));

        if (!checkIfValidateObj(errorObj)) {
            return;
        }

        setAddErrorState(prevStep => ({
            ...prevStep,
            isShowLoader: true,
        }));

        if (isEmpty(modelData?.edit) || !modelData?.edit){
            let payload = {
                productId: modelData?.productId,
                reason: reason,
                category: category
            };

            addError(payload, true)
                .then(res => {
                    if (res?.success) {
                        setAddErrorState(prevState => ({
                            ...prevState,
                            isShowLoader: false
                        }));
                        store.dispatch(showToastMessage('success', `Error added successfully`));
                        props.refreshFullScreen("openErrorModel");
                    } else {
                        setAddErrorState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            errorObj: {
                                ...prevState.errorObj,
                                responseError: res?.__error
                            },
                        }));
                    }
                });
        }else{
            let payload = {
                id: modelData?.id,
                reason: reason,
                category: category
            };

            updateError(payload, true)
                .then(res => {
                    if (res?.success) {
                        setAddErrorState(prevState => ({
                            ...prevState,
                            isShowLoader: false
                        }));
                        store.dispatch(showToastMessage('success', `Error added successfully`));
                        props.refreshFullScreen("openErrorModel");
                    } else {
                        setAddErrorState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            errorObj: {
                                ...prevState.errorObj,
                                responseError: res?.__error
                            },
                        }));
                    }
                });

        }
    };

    return (
        <AddErrorView
            {...props}
            {...addErrorState}
            addErrorData={addErrorData}
            onChangeValue={onChangeValue}
            onBlurInputTextField={onBlurInputTextField}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(AddError);
