import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'UserManagementReducer',
    initialState: {
        userManagementFilter: {
            keyword: '',
            skipCount: 0,
            limitCount: 25,
            page: 0,
            rowsPerPage: 50,
            title: '',
            role: 'ALL',
        },
    },
    reducers: {
        setUserSearchKeywordData(state, action) {
            state.userManagementFilter.keyword = action.payload;
        },
        setUserListingSearchKeywordData(state, action) {
            state.userManagementFilter.keyword = action.payload;
        },
        setUserListingSearchRoleData(state, action) {
            state.userManagementFilter.role = action.payload;
        },
        setSkipCountValue(state, action) {
            state.userManagementFilter.skipCount = action.payload;
        },
        setLimitCountValue(state, action) {
            state.userManagementFilter.limitCount = action.payload;
        },
        setPageValue(state, action) {
            state.userManagementFilter.page = action.payload;
        },
        setRowsPerPageValue(state, action) {
            state.userManagementFilter.rowsPerPage = action.payload;
        },

        resetAllHomePageFilters(state) {
            state.userManagementFilter = {
                keyword: '',
                skipCount: 0,
                limitCount: 50,
                page: 0,
                rowsPerPage: 50,
                title: '',
                role: 'ALL',
            };
        },
    }
});

export const {
    setUserSearchKeywordData, setUserListingSearchKeywordData, setUserListingSearchRoleData, setSkipCountValue, setLimitCountValue, setPageValue, setRowsPerPageValue, setSearchDateValue, setSearchRoleValue,
    resetAllHomePageFilters
} = appSlice.actions;
export default appSlice.reducer
