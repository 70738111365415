import React from 'react';

import DLModalTitle from "../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModal from "../../../../core/components/DLModal/DLModal";
import DLModalContainer from "../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLInputTextField from "../../../../core/components/DLInputTextField/DLInputTextField";
import {isEmpty} from "../../../../utils/validations";

const ComplexityModelView = (props) => {

    const {
        handleCloseModal,
        isComplexityModalOpen,
        validateChanges,
        onSelectComplexity,
        selectedComplexity,
        errorObj,
        submitComplexity
    } = props;

    const renderErrorUI = () => {
        const {errorObj} = props;
        const {responseError} = errorObj;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'mt-1'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    const renderComplexityModal = () => {

        return (
            <div>
                <DLModal
                    history={props.history}
                    maxWidth={"sm"}
                    onClose={() => handleCloseModal('isComplexityModalOpen')}
                    open={isComplexityModalOpen}
                    id={'complexity-modal'}>
                    <DLModalTitle
                        id={'complexity-title'}
                        onClose={() =>  handleCloseModal('isComplexityModalOpen')}
                        title={'Complexity'}/>
                    <DLModalContainer
                        id={'complexity-container'}>
                        {renderErrorUI()}
                        <div>
                            <div className='mt-2 mb-4 w-50' style={{minHeight: '100px'}}>

                                <DLInputTextField
                                    id={'complexity-input'}
                                    label={'Complexity'}
                                    labelPlacement={'top'}
                                    marginBottom={'none'}
                                    value={selectedComplexity}
                                    isSearchable={false}
                                    isRequired={true}
                                    helperMessage={errorObj.selectedComplexityError}
                                    error={errorObj.selectedComplexityError !== ''}
                                    disableHelperMessageHeight={true}
                                    isClearable={true}
                                    onChangeValue={(complexityValue) =>
                                        onSelectComplexity(complexityValue)
                                    }
                                    onBlur={() =>
                                        validateChanges()
                                    }
                                    placeholder={'Enter complexity value'}
                                    maxCharLength={2}
                                />

                            </div>
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'complexity-action'}>
                        <div className='d-flex gap-3 mt-3 mb-3'>
                            <DLButton
                                id={'complexity-cancelBtn'}
                                history={history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"none"}
                                onClick={() =>  handleCloseModal('isComplexityModalOpen')}/>
                            <DLButton
                                id={'complexity-saveBtn'}
                                history={props.history}
                                label={'Save'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"none"}
                                onClick={() => submitComplexity()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    return (
        <div>
            {renderComplexityModal()}
        </div>
    );
};

export default ComplexityModelView;