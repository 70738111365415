import store from '../store'
import {isEmpty} from './validations';
import React from "react";
import {getAppEnvironmentVariables, getAuthorizationToken} from "./httpHelper";
import {setAppLoader, showToastMessage} from "../appComponents/AppActions";
import {APP_CONST} from "../appComponents/AppConstants";
import moment from "moment";

const {REACT_APP_API_URL} = getAppEnvironmentVariables();

export const downloadFilesV2 = (accountId, attachment, filename, action,urlToFetchDoc) => {

    let url = urlToFetchDoc + attachment;

    const {REACT_APP_API_URL} = getAppEnvironmentVariables();

    let API_URL = REACT_APP_API_URL + url;

    let cloneData, flag;
    return fetch(API_URL, {
        method: "GET",
        headers: {
            Accept: "application/*",
            Authorization: "Bearer " + getAuthorizationToken().token,
            'x-account-id': accountId,
        },
        responseType: 'arraybuffer',
        dataType: 'blob'
    }).then(async (response) => {
            cloneData = response.clone();
            await response.json()
                .then(res => {
                    // store.dispatch(setAppLoader(false));
                    if (res?.message) {
                        store.dispatch(showToastMessage('warning', res?.message))
                    }
                    if (res?.__error) {
                        store.dispatch(showToastMessage('warning', res?.__error))
                    }
                    flag = false;
                })
                .catch(() => {
                    flag = true;
                });
        }).then(result => {
        if (flag) {
            cloneData.blob().then(blob => {
                if (blob) {
                    let tempName = filename.split('.');
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    if (action === 'Download') {
                        a.download = tempName[0] + '-' + "." + tempName[1];
                        a.click();
                    }
                    if (action === 'View') {
                        // a.target = ' __blank';
                        // let param = 'width=1100,height=600,toolbar=no,menubar=no,left=100,top=100'
                        let params  = 'width='+((window.innerWidth/2)*1.4);
                        params += ', height='+((window.innerHeight/2)*1.5);
                        params += ', left='+(window.innerWidth/5);
                        params += ', top='+(window.innerHeight/5);
                        params += ', fullscreen=yes';

                        window.open(url,'test',params);
                    }


                } else {
                    store.dispatch(showToastMessage('warning', result.message));
                }
            });
        }
    });
};

export const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const getRandomId = (idLength) => {
    let stringList = '0123456789abcdefghijklmnopqrstuvwxyz';
    return stringList.split('').map(function (v, i, a) {
        return i > idLength ? null : a[Math.floor(Math.random() * stringList.length)]
    }).join('');
};

export const generateRandomNumber = (min, max) => {
    return Math.floor((Math.random() * max) + min)
};


export const getUserAgent = () => {
    return (window.navigator.userAgent) ? window.navigator.userAgent : 'userAuth agent not available';
};

export const isIosDevice = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
};

export const isAndroidDevice = () => {
    let platform = window.navigator.platform.toLowerCase();
    return /linux/.test(platform);
};

export const isWindowsSystem = () => {
    let platform = window.navigator.platform.toLowerCase();
    return /win32/.test(platform);
};

export const isChromeBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    let isChrome = /chrome/.test(userAgent);
    let isVender = (window.navigator.vendor === 'Google Inc.');
    return (isChrome && isVender && !isOperaBrowser());
};

export const isSafariBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    let isSafari = /safari/.test(userAgent);
    let isVender = (window.navigator.vendor === 'Apple Computer, Inc.');
    return (isSafari && isVender);
};

export const isOperaBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /opr/.test(userAgent);
};

export const isFirefoxBrowser = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /firefox/.test(userAgent);
};

export const isSafariOnIphoneDevice = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone/.test(userAgent) && /safari/.test(userAgent) && !(/crios/.test(userAgent));
};

export const isInStandaloneMode = () => {
    return (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');
};

export const sleepThread = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms || 1000));
};


export const isValidEmail = (email, excludeSpecialCharValidation) => {
    let flag = false;
    let localEmail = email?.trim();

    if (isEmpty(localEmail)) {
        return false;
    }

    if (localEmail.includes(" ")) {
        flag = true;
    }

    if (excludeSpecialCharValidation) {
        // ignore special char validation
    } else {
        if (!(/^[a-zA-Z\d0-9@._-]*$/.test(localEmail))) {
            flag = true;
        }
    }

    if (localEmail.includes('@')) {
        //do nothing
    } else {
        return false
    }


    let domainType = localEmail.split('@');

    if (isEmpty(domainType[0])) {
        return false
    }

    if (isEmpty(domainType[1])) {
        return false
    }
    return !flag;
};


export const getDropdownSelectedValues = (value, isMulti) => {
    if (isMulti) {
        return value.map(item => {
            return item.value
        })
    } else {
        return value.value
    }
};

export const formatValueForDropdown = (prefix, value, isMulti) => {
    if (isMulti) {
        if (isEmpty(value)) {
            return [];
        }
        return value.map(item => {
            return {
                label: prefix + item,
                value: item
            }
        })
    } else {
        if (isEmpty(value)) {
            return null;
        }
        return {
            label: prefix + value,
            value: value
        }
    }
};


export const getBookmarkValue = (value) => {
    if (value) {
        return "myBookmark=true"
    } else {
        return "";
    }
};

export const getKeywordValue = (value) => {
    if (isEmpty(value)) {
        return ""
    } else {
        return 'keyword=' + encodeURIComponent(value);
    }
};

export const getSortByValue = (value) => {
    if (isEmpty(value)) {
        return ""
    } else {
        return 'sortBy=' + value;
    }
};


export const getSortedObjectArray = (arr, sortBy) => {
    if (isEmpty(arr)) {
        return arr;
    }
    return arr.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
            return -1;
        }
        if (a[sortBy] > b[sortBy]) {
            return 1;
        }
        return 0;
    });
};


export const generateUrlEndpointsForListing = (isFilterDrawerOpen, filterUrl, keywordFilter, sortBy, bookmarkFilter, prevUrlEndpoints) => {

    let urlBuilder = [];

    if (isFilterDrawerOpen) {

        return prevUrlEndpoints;

    }

    if (isEmpty(filterUrl)) {
        // do nothing
    } else {
        urlBuilder.push(filterUrl);
    }

    if (bookmarkFilter) {
        urlBuilder.push('myBookmark=true');
    }

    if (isEmpty(keywordFilter)) {
        // do nothing
    } else {
        urlBuilder.push('keyword=' + encodeURIComponent(keywordFilter));
    }

    if (isEmpty(sortBy)) {
        // do nothing
    } else {
        urlBuilder.push('sortBy=' + sortBy);
    }

    return "" + urlBuilder.join('&');

};


export const htmlTextLength = (html) => {
    if (isEmpty(html))
        return 0;
    return html.replace(/(<([^>]+)>)/g, "").replace('&nbsp;', ' ').length;
};

export const parseHtmlToText = (html) => {
    if (isEmpty(html))
        return '';
    return html.replace(/(<([^>]+)>)/g, "").replace('&nbsp;', ' ');

};

export const isValidTitle = (title) => {
    // return !(/[*|\":<>[\]/!%=\+_{}`\\()';\^$@]/.test(title));
    return !(/[\?\<>]/.test(title));
    // return (/^[a-zA-Z 0-9\&\-\#\_\@\(\)\ \"\:\,\']*$/.test(title));
};


export const getMappedCategories = (categories, allCategories) => {
    let mappedCategories = [];
    if (allCategories) {
        for (let value of categories) {
            for (let mainCategories of allCategories) {
                if (mainCategories.value === value) {
                    let obj = {
                        value: mainCategories.value,
                        label: mainCategories.title
                    };
                    mappedCategories.push(obj);
                }
            }
        }
    }
    return mappedCategories;
};


export const isGroupAdmin = groupId => {
    let myManagedGroups = store.getState().groupState.myManagedGroups;
    let groupDetails = myManagedGroups.find(group => group.id === groupId);
    return !isEmpty(groupDetails);

};

export const getFormattedUserName = (user) => {

    if (isEmpty(user)) {
        return "";
    }

    let userName = "";

    if (isEmpty(user?.firstName)) {
        return userName;
    }

    userName = userName + (user?.firstName).trim();

    if (isEmpty(user?.lastName)) {
        return userName;
    }

    userName = userName + " " + (user?.lastName).trim();

    return userName;

};

export const getCurrencySymbol = (currency) => {
    let symbol;
    switch (currency) {
        case 'eur':
        case 'EUR':
            symbol = '€';
            break;
        case 'usd':
        case 'USD':
            symbol = '$';
            break;
        case 'gbp':
        case 'GBP':
            symbol = '£';
            break;
        case 'chf':
        case 'CHF':
            symbol = 'CHF';
            break;
        default:
            symbol = '';
            break;
    }
    return symbol;
};

export const isValidPassword = (password) => {
    let flag = true;
    if (/^([^0-9]*)$/.test(password)) {
        flag = false;
    }
    if (!(/[^a-zA-Z\d]/.test(password))) {
        flag = false;
    }
    if (!/[A-Za-z]+/.test(password)) {
        flag = false;
    }
    return flag;
    // return !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8}$/.test(password);
    ///^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
};

export const getExternalUrl = (url) => {
    // let urlLink = '';
    if (!("" + url).toLowerCase().startsWith('http')) {
        url = 'http://' + url
    }
    return url;
};

export const isInvalidPhone = (code) => {
    return !(/^[0-9]*$/.test(code))
};


export const getAppRouteFromUrl = () => {


};

export const getCommaSeperatedString = (array) => {
    let string = "";
    let length = (array.length - 1);
    array.forEach((value, index) => {
        string = string + value;
        if (index < length) {
            string = string + ',' + " ";
        }
    });
    return string;
};

export const trimFormValues = (dataObj) => {

    if (isEmpty(dataObj)) {
        return dataObj;
    }

    if (typeof dataObj === 'string') {
        return dataObj.trim();
    }

    if (typeof dataObj === 'object') {

        let newObj = dataObj;

        Object.keys(dataObj).forEach(function (key) {
            let oldString = dataObj[key];
            if (typeof oldString === 'string') {
                if (isEmpty(oldString)) {
                    newObj[key] = oldString;
                } else {
                    newObj[key] = oldString.trim();
                }
            }
        });

        return newObj;
    }

    return dataObj;

};

export const trimStringAttributes = (dataObj) => {

    if (isEmpty(dataObj)) {
        return dataObj;
    }

    if (typeof dataObj === 'string') {
        return dataObj.trim();
    }


    if (typeof dataObj === 'object') {

        Object.keys(dataObj).forEach(function (key) {
            if (typeof dataObj[key] === 'string' && !isEmpty(dataObj[key])) {
                dataObj[key] = dataObj[key].trim();
            }
        });

    }

    return dataObj;

};

export const customDelay = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
export const getSplittedCountryCode = (value) => {
    if (isEmpty(value)) {
        return ""
    }
    if (typeof value !== 'string') {
        return ""
    }
    return value.split('###')[1];
};

export const getFileExtension = (fileNameOnServer) => {
    if (isEmpty(fileNameOnServer)) {
        return "";
    }
    return fileNameOnServer.split('.').pop().toLowerCase();
};


export const getValidText = (string) => {
    if (isEmpty(string)) {
        return "";
    }
    return /^[A-z]+$/.test(string)
};


export const isShowAppbar = (pathname) => {
    if (pathname?.includes("/login") ||
        pathname?.includes("/emailVerification") ||
        pathname?.includes("/forgot-password") ||
        pathname?.includes("/reset-password") ||
        pathname?.includes("/register")) {
        return false;
    } else {
        return true
    }
};

export const searchData = (input, options) => {
    console.log(options);
    let foundData = [];
    for (let i = 0; i < options.length; i++) {
        let label = options[i];
        console.log(label)
        let labelArr = label.split(" ");

        if(label.toLowerCase().startsWith(input.trim().toLowerCase())){
            foundData.push(options[i])
        }
        else {
            let data = labelArr.find((element) => {
                return (element.toLowerCase().startsWith(input.trim().toLowerCase()))
            });
            if (!isEmpty(data)) {
                foundData.push(options[i])
            }
        }
    }
    return foundData
};


export const getRandomColors = (name) => {

    if (isEmpty(name)) {
        return "#ffdb99"
    }

    let firstLetter = name?.substring(0, 1).toLowerCase();

    switch (firstLetter) {
        case "a":
        case "o":
            return "#4080bf";
        case "b":
        case "p":
            return "#0099cc";
        case "c":
        case "q":
            return "#33cccc";
        case "d":
        case "r":
            return "#44B892";
        case "e":
        case "s":
            return "#B89244";
        case "f":
        case "t":
            return "#6C98FB";
        case "g":
        case "u":
            return "#FB6C98";
        case "h":
        case "v":
            return "#1DBD3E";
        case "i":
        case "w":
            return "#BD1D4C";
        case "j":
        case "x":
            return "#4E8BBE";
        case "k":
        case "y":
            return "#4EBEB9";
        case "l":
        case "z":
            return "#9A3499";
        case "m":
            return "#9A3466";
        case "n":
            return "#BA8156";
        default:
            return "#cda5f3";
    }
};


export const handleExternalAppNavigation = (route) => {
    let {REACT_APP_HOST_URL} = getAppEnvironmentVariables();
    window.open(REACT_APP_HOST_URL + route, "_self");
}

export const makeRandomName = () => {
    let text;

    let possible = [
        "Lorem ipsum dolor amet",
        "Lorem ipsum sit",
        "Lorem ipsum dolor",
        "Lorem ipsum dolor sit",
        "Loremipsum",
        "Lorem ipsum dolor amet",
        "Lorem ipsum dolor consectetur",
        "Lorem dolor sit amet",
        "Lorem ipsum",
        "Ipsum dolor sit amet",
    ];
    const random = Math.floor(Math.random() * possible.length);
    text = possible[random];
    return text;
};

export const priceWithCommas = (x) => {
    if (isEmpty(x)) {
        return "";
    }
    x = x.toString().split('.');
    let rsString = '';
    rsString += x[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (x[1]) {
        rsString += '.' + x[1].toString();
    } else {
        rsString += '.00'
    }
    return rsString;
};

export const downloadList = async (apiUrl, ext, fileName, data) => {
    store.dispatch(setAppLoader(true));

    let url = REACT_APP_API_URL +apiUrl;

    let rawResponse = await fetch(url, {
        method: "POST",
        body: isEmpty(data) ? '' : JSON.stringify(data),
        headers: {
            Accept: "application/*",
            'Content-Type': 'application/json',
            Authorization: "Bearer " + getAuthorizationToken().token,
        },
    });
    let clonedResponse = await rawResponse.clone();

    //TO-DO: coudnt convert into json.. need to check
    // let jsonResponse = await rawResponse.json();

    let blobResponse = await rawResponse.blob();

    if (blobResponse) {
        let url = window.URL.createObjectURL(blobResponse);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName + "." + ext;
        store.dispatch(setAppLoader(false));
        a.click();
    } else {
        store.dispatch(setAppLoader(false));
        if (clonedResponse?.__error) {
            store.dispatch(showToastMessage('danger', clonedResponse?.__error));
        }
        return {};
    }

    return clonedResponse;
};

export const numberWithCommas = (x) => {
    if (x === '0' || x === 0){
        return x
    }

    if(!x){
        return "";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const generateInboxUrlEndPoint = (inboxFilter) => {
    // const {handleFilterCount} = this.props
    let urlBuilder = [];
    let filterCount = 0;
    if (isEmpty(inboxFilter.account)) {


    } else {
        urlBuilder.push('accountId=' + inboxFilter.account.value);
        filterCount++
    }
    if (isEmpty(inboxFilter.toFilter)) {

    } else {
        urlBuilder.push('to=' + inboxFilter.toFilter);
        filterCount++
    }
    if (isEmpty(inboxFilter.fromFilter)) {

    } else {
        urlBuilder.push('from=' + inboxFilter.fromFilter);
        filterCount++
    }
    if (isEmpty(inboxFilter.startDate)) {
        urlBuilder.push('startDate=' + APP_CONST.FILTER_MIN_LIMIT_DATE);

    } else {

        let formatterSD = moment(inboxFilter.startDate).format('YYYY-MM-DD');
        urlBuilder.push('startDate=' + formatterSD);
        filterCount++
    }
    if (isEmpty(inboxFilter.endDate)) {

    } else {
        let formatterED = moment(inboxFilter.endDate).format('YYYY-MM-DD');
        urlBuilder.push('endDate=' + formatterED);
        filterCount++
    }
    return {
        filterCount: filterCount,
        endpointUrl: urlBuilder.join('&')
    }
};

export const capitalizeFirstLetter = (str) => {
    if(isEmpty(str)){
        return
    }
    return((str.charAt(0).toUpperCase())+(str.slice(1).toLowerCase()))
};

export const getTrimmedValue = (value, trimChars) =>{
    if (isEmpty(value)){
        return ''
    }
    if (value.length > trimChars){
        return value.slice(0,trimChars)+'...'
    }
    return value
};


export const searchOnWordBoundary = (input, options) => {
    let foundData = [];
    for (let i = 0; i < options.length; i++) {
        let label = options[i].label;
        if (isEmpty(label)){
            continue
        }
        let labelArr = label?.split(" ");

        if(label.toLowerCase().startsWith(input.trim().toLowerCase())){
            foundData.push(options[i])
        }
        else {
            let data = labelArr.find((element) => {
                return (element.toLowerCase().startsWith(input.trim().toLowerCase()))
            });
            if (!isEmpty(data)) {
                foundData.push(options[i])
            }
        }
    }

    return foundData
};

export const getFormattedCounts=(inputValue)=>{

    let formattedValue = inputValue;

    if (inputValue === '0') {
        return '0'
    }

    if (inputValue >= 1000000) {
        let simulatedValue = (inputValue / 1000000) + '';

        formattedValue = parseFloat(priceWithCommas(simulatedValue)).toFixed(2) + 'M';

    } else if (inputValue >= 1000) {
        let simulatedValue = (inputValue / 1000) + '';

        formattedValue =  parseFloat(priceWithCommas(simulatedValue)).toFixed(2)  + 'K';
    } else {
        formattedValue = inputValue;
    }
    return formattedValue

};

export const getMaxValueForGraph=(data)=>{

    let maxValueFromArray = Math.max.apply(Math,data);

    let maxValueForAxis = maxValueFromArray * 1.1;

    if(isEmpty(maxValueForAxis)){
        return;
    }
    if (maxValueForAxis <= 10) {
        return Math.ceil(maxValueForAxis / 10) * 10;
    } else {
        const magnitude = Math.pow(10, Math.floor(Math.log10(maxValueForAxis)));
        const rounded = Math.ceil(maxValueForAxis / magnitude) * magnitude;
        return rounded === maxValueForAxis ? rounded + magnitude : rounded;
    }

};

export const checkBlankDataConditionForGraphs =(data)=>{
    let foundElement = data.find((item)=>{
        return item.toString() !== '0'
    })
    return isEmpty(foundElement);
}
export const getTrimVal = (name, isLGView, isXLView, isMobileView, isXSView) => {

    let value = name;
    if (isEmpty(name)) {
        return ''
    }

    if (isXSView){
        if (name.length > 15) {
            value = name.slice(0, 15) + '...'
        }
        return value
    }

    if (isMobileView){
        if (name.length > 20) {
            value = name.slice(0, 20) + '...'
        }
        return value
    }

    if (isLGView){
        if (name.length > 20) {
            value = name.slice(0, 20) + '...'
        }
        return value
    }

    if (isXLView){
        if (name.length > 30) {
            value = name.slice(0, 30) + '...'
        }
        return value
    }

    if (name.length > 10) {
        value = name.slice(0, 10) + '...'
    }

    return value
};


export const getNextRoundedNumber=(input)=> {
    // input - to nearest tenth
    //  275 - 300 300/5 = 60 - 50
    if (input === 0){
        return 0
    }

    if (input <= 5) {
        return 5;
    } else if (input <= 25) {
        return 25;
    } else if (input <= 50) {
        return 50;
    } else if (input <= 100) {
        return 100;
    }else if (input <= 250) {
        return 250;
    } else if (input <= 500) {
        return 500;
    } else if (input <= 1000) {
        return 1000;
    }  else if (input <= 2500) {
        return 2500;
    }  else if (input <= 5000) {
        return 5000;
    } else if (input <= 10000) {
        return 10000;
    } else if (input <= 25000) {
        return 25000;
    } else if (input <= 50000) {
        return 50000;
    }  else if (input <= 100000) {
        return 100000;
    } else if (input <= 250000) {
        return 250000;
    }  else if (input <= 500000) {
        return 500000;
    }  else if (input <= 1000000) {
        return 1000000;
    }   else if (input <= 2500000) {
        return 2500000;
    }  else if (input <= 5000000) {
        return 5000000;
    } else if (input <= 10000000) {
        return 10000000;
    } else if (input <= 25000000) {
        return 25000000;
    } else if (input <= 50000000) {
        return 50000000;
    } else if (input <= 100000000) {
        return 100000000;
    }  else if (input <= 250000000) {
        return 250000000;
    } else if (input <= 500000000) {
        return 500000000;
    }  else if (input <= 1000000000) {
        return 1000000000;
    }  else {
        const powerOfTen = Math.pow(10, Math.floor(Math.log10(input)));
        const nextPowerOfTen = powerOfTen * 10;
        return nextPowerOfTen;
    }
};

const compareByMarketValueChangeMoM = (a, b) => {
    if (a.net_buying_selling < b.net_buying_selling) {
        return 1;
    }
    if (a.net_buying_selling > b.net_buying_selling) {
        return -1;
    }
    return 0;
};


const compareByMarketValueChangePercent = (a, b) => {
    if (a.net_buying_selling_percentage < b.net_buying_selling_percentage) {
        return 1;
    }
    if (a.net_buying_selling_percentage > b.net_buying_selling_percentage) {
        return -1;
    }
    return 0;
};

const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

export const getIndexArr = (chartType, tableDataArr) => {
    let indexArr = [];

    if(chartType === 'changeMoM'){
        let changeMoMData = deepCopy(tableDataArr);
        const positiveNumbers = changeMoMData.filter(number => number?.net_buying_selling > 0.001);
        positiveNumbers.sort(compareByMarketValueChangeMoM);
        indexArr= positiveNumbers;
    }else if( chartType === 'changeSellingMoM'){
        let changeMoMData = deepCopy(tableDataArr);
        const negativeNumbers = changeMoMData.filter(number => number?.net_buying_selling < -0.001);
        negativeNumbers.sort(compareByMarketValueChangeMoM);
        indexArr= negativeNumbers.reverse();
    }else if(chartType === 'changePercent'){
        let changeMoMPercentage = deepCopy(tableDataArr);
        const positiveNumbers = changeMoMPercentage.filter(number => number?.net_buying_selling_percentage > 0.001);
        positiveNumbers.sort(compareByMarketValueChangePercent);
        indexArr= positiveNumbers;
    }else if(chartType === 'changeSellingPercent'){
        let changeMoMPercentage = deepCopy(tableDataArr);
        const negativeNumbers = changeMoMPercentage.filter(number => number?.net_buying_selling_percentage < -0.001);
        negativeNumbers.sort(compareByMarketValueChangePercent);
        indexArr= negativeNumbers.reverse();
    }else {
        indexArr= tableDataArr;
    }

    return indexArr
};

export const getChartData = (chartType, tableDataArr, name , isLGView, isXLView, isMobileView) =>{
    let newChartData = [];
    let newLabelData = [];

    let length = tableDataArr?.length;

    if(chartType === 'marketValue'){
        for (let i = 0; i < length; i++) {
            let labelData = getTrimVal(tableDataArr[i][name], isLGView, isXLView, isMobileView);
            let chartData = (tableDataArr[i].total_market_value).toFixed(2);
            newChartData.push(chartData);
            newLabelData.push(labelData);
        }
    }else if(chartType === 'changeMoM'){
        let positiveNumbers = tableDataArr.filter(number => number?.net_buying_selling > 0.001);
        positiveNumbers.sort(compareByMarketValueChangeMoM);
        for (let i = 0; i < positiveNumbers?.length; i++) {
            let labelData = getTrimVal(positiveNumbers[i][name], isLGView, isXLView, isMobileView);
            let chartData = (positiveNumbers[i].net_buying_selling).toFixed(2);
            newChartData.push(chartData);
            newLabelData.push(labelData);
        }
    }else if(chartType === 'changeSellingMoM'){
        let negativeNumbers = tableDataArr.filter(number => number?.net_buying_selling < -0.001);
        negativeNumbers.sort(compareByMarketValueChangeMoM);
        for (let i = negativeNumbers?.length - 1; i >= 0; i--) {
            let labelData = getTrimVal(negativeNumbers[i][name], isLGView, isXLView, isMobileView);
            let chartData = (negativeNumbers[i].net_buying_selling).toFixed(2);
            newChartData.push(chartData);
            newLabelData.push(labelData);
        }
    }else if(chartType === 'changePercent'){
        let positiveNumbers = tableDataArr.filter(number => number?.net_buying_selling_percentage > 0.001);
        positiveNumbers.sort(compareByMarketValueChangePercent);
        for (let i = 0; i < positiveNumbers?.length; i++) {
            let labelData = getTrimVal(positiveNumbers[i][name], isLGView, isXLView, isMobileView);
            let chartData = (positiveNumbers[i].net_buying_selling_percentage).toFixed(2);
            newChartData.push(chartData);
            newLabelData.push(labelData);
        }
    }else if(chartType === 'changeSellingPercent'){
        let negativeNumbers = tableDataArr.filter(number => number?.net_buying_selling_percentage < -0.001);
        negativeNumbers.sort(compareByMarketValueChangePercent);
        for (let i = negativeNumbers?.length - 1; i >= 0; i--) {
            let labelData = getTrimVal(negativeNumbers[i][name], isLGView, isXLView, isMobileView);
            let chartData = (negativeNumbers[i].net_buying_selling_percentage).toFixed(2);
            newChartData.push(chartData);
            newLabelData.push(labelData);
        }
    }

    return {
        newChartData: newChartData,
        newLabelData: newLabelData
    }
};

export const convertUTCToLocalTime = (date, format) =>{
    if(isEmpty(format)){
        return moment.utc(date).local().format()
    }
    return moment.utc(date).local().format(format)
};

export const isIncludeSpecialCharacters = (inputString) => {
    let flag = false;

    if(isEmpty(inputString)){
        return flag
    }
    // Define a regex pattern for special characters
    var pattern = /[^a-zA-Z0-9\s]/;

    // Test the pattern against the input string
    flag =  pattern.test(inputString);
    return flag
};
export const isAlphabetic = (inputString) => {
    let flag = false;

    if(isEmpty(inputString)){
        return flag
    }
    // Regular expression to check for any digit
    const regex = /\d/;
    // Test the string against the regex
    flag = regex.test(inputString);
    return flag

};

export const validateAge = (dateString, age) => {
    // Parse the input date string
    const inputDate = new Date(dateString);
    if (!isNaN(inputDate) && !isEmpty(age)) {
        // Get the current date
        const currentDate = new Date();

        // Calculate the age in milliseconds
        const ageInMilliseconds = currentDate - inputDate;

        // Calculate the number of milliseconds in 18 years
        const eighteenYearsInMilliseconds = age * 365.25 * 24 * 60 * 60 * 1000;

        // Check if the age is less than 18 years
        if (ageInMilliseconds < eighteenYearsInMilliseconds) {
            return true
        }

        // If no error is thrown, the age is valid
        return false;
    }
}


export const getDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let device = '';

    if (/android/i.test(userAgent)) {
        device = 'Android';
    } else if (/iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document) {
        device = 'iPad';
    } else if (/iPhone|iPod/i.test(userAgent) && !window.MSStream) {
        device = 'iOS';
    } else {
        device = 'unknown';
    }

    return device;
};

export const refreshWindowScrollElement = () => {
    window.scrollTo(0, 0)
};

export const isMobileInLandscape = () => {
    const isMobile = store.getState().appState.deviceInfo.isMobileDevice;
    if (isMobile) {
        return window.innerWidth > (1.5 * window.innerHeight);
    }

    return false;
};

export const validatePAN = (PANNumber) => {
    // const panRegex = /^[A-Z]{3}[AOPBCHFLTJGP]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(PANNumber.toUpperCase());
};

export const getTimeFromNowWithDate = (inputDate) => {
    // for showing date after 7 days and respective time from now before 7 days

    if(isEmpty(inputDate)){
        return '-'
    }
    const date = new Date(inputDate);
    const time = new Date(inputDate); // Ensure the input is a Date object
    const now = new Date();
    const secondsPast = (now.getTime() - time.getTime()) / 1000;

    if (secondsPast < 60) {
        return 'just now';
    }
    if (secondsPast < 3600) {
        const minutes = Math.floor(secondsPast / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    if (secondsPast <= 86400) {
        const hours = Math.floor(secondsPast / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    if (secondsPast < 691200) {
        const days = Math.floor(secondsPast / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    const formatedDate =('on '+(date.getDate() <10 ? ('0'+date.getDate()) : date.getDate())+'-'+(date.getMonth() <9 ? ('0'+(date.getMonth()+1)) : (date.getMonth()+1))+'-'+(date.getFullYear()))
    return formatedDate;
};

export const getTimeFromNow = (inputDate) => {
    if(isEmpty(inputDate)){
        return '-'
    }
    const time = new Date(inputDate); // Ensure the input is a Date object
    const now = new Date();
    const secondsPast = (now.getTime() - time.getTime()) / 1000;

    if (secondsPast < 60) {
        return 'just now';
    }
    if (secondsPast < 3600) {
        const minutes = Math.floor(secondsPast / 60);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    if (secondsPast <= 86400) {
        const hours = Math.floor(secondsPast / 3600);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    if (secondsPast <= 2592000) {
        const days = Math.floor(secondsPast / 86400);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    }
    if (secondsPast <= 31536000) {
        const months = Math.floor(secondsPast / 2592000);
        return `${months} month${months > 1 ? 's' : ''} ago`;
    }
    const years = Math.floor(secondsPast / 31536000);
    return `${years} year${years > 1 ? 's' : ''} ago`;
};

export const trimValues = (data) => {
    if (typeof data === 'string') {
        return data.trim();
    } else if (Array.isArray(data)) {
        return data.map(trimValues);
    } else if (typeof data === 'object' && data !== null) {
        const trimmedData = {};
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                trimmedData[key] = trimValues(data[key]);
            }
        }
        return trimmedData;
    }
    return data;
};

export const toSentenceCase = (input) => {
    if(isEmpty(input)){
        return '-'
    }

    // Split the string into words
    const words = input.toLowerCase().split(' ');

    // Capitalize the first letter of each word
    const formattedWords = words.map(word =>
        word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words back into a single string
    return formattedWords.join(' ');
}

export const isCorporateUser = (accountType) => {
    if(accountType === 'CORPORATE' || accountType === 'HUF' || accountType === 'PARTNERSHIP_FIRM_LLP' || accountType === 'TRUST'){
        return true;
    }
    return false;
};

export const getCountText = (text, count) =>{
    if (!count){
        return text
    }

    if (count === 1 || count === 0){
        return text
    }
    return text + 's'
};


export const capitalizeString = (str) =>{
    if (!str){
        return str
    }

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
};

export const getLabelByValue = (options,value) => {
    // you need to pass label and option object
    if(isEmpty(options) || isEmpty(value)) {
        return '';
    }
    const selectedField = options.find(item => item.value === value);
    return !isEmpty(selectedField) ? selectedField.label : null; // Return the label or null if not found
};

