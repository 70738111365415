import React from 'react';

import DLModalTitle from "../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModal from "../../../../core/components/DLModal/DLModal";
import DLModalContainer from "../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLSearchDropdown from "../../../../core/components/DLSearchDropdown/DLSearchDropdown";
import DLText from "../../../../core/components/DLText/DLText";
import appTheme from "../../../../assets/appTheme";
import {isEmpty} from "../../../../utils/validations";

const ReAssignOwnerModelView = (props) => {

    const {
        handleCloseModal, isReAssignOwnerModalOpen, onAssignOwner,
        isShowInlineLoader,
        userSearchData,
        searchUser,
        onChangeFilter,
        searchKeyword,
        addSearchId,
        isOwnerSeleted,
        selectedOwner,
        getChangeOwner,
        errorObj,
    } = props;


    const renderSearchField = () => {
        return (
            <DLSearchDropdown
                placeholder={"Search..."}
                isShowInlineLoader={isShowInlineLoader}
                data={userSearchData}
                onChangeData={(e) => searchUser(e)}
                value={searchKeyword}
                onChangeValue={(value) => onChangeFilter(value)}
                onClickItem={(id) => addSearchId(id)}
                disableHelperMessageHeight={true}
                dropdownHeight={'130px'}

            />
        )
    };

    const renderAssignOwnerField = () => {
        return (
            <div className='d-flex justify-content-between'>
                <DLText id={'selected-admin-field'}
                        text={selectedOwner?.userName}
                        fontSize={"sm"}
                        marginBottom={'sm'}
                        fontWeight={"normal"}
                />
                <div className='cursor'
                     onClick={()=>getChangeOwner()}>
                    <DLText id={'admin-field'}
                            text={'Change Owner'}
                            fontSize={"sm"}
                            marginBottom={'sm'}
                            fontWeight={"normal"}
                            customTextStyle={{color: appTheme.linkStyle.color}}
                    />
                </div>
            </div>
        )
    };

    const renderErrorUI = () => {
        const {errorObj} = props;
        const {responseError} = errorObj;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'mt-1'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    const renderReAssignOwnerModal = () => {
        return (
            <div>
                <DLModal
                    history={props.history}
                    maxWidth={"sm"}
                    onClose={() =>handleCloseModal('isReAssignOwnerModalOpen')}
                    open={isReAssignOwnerModalOpen}
                    id={'assign-owner-modal'}>
                    <DLModalTitle
                        id={'assign-owner-title'}
                        onClose={() => handleCloseModal('isReAssignOwnerModalOpen')}
                        title={'Re-assign Owner'}/>
                    <DLModalContainer
                        id={'assign-owner-container'}>
                        {renderErrorUI()}
                        <div className='my-2' style={{minHeight: '200px'}}>
                            <DLText id={'admin-title'}
                                    text={'Owner'}
                                    fontSize={"xs"}
                                    fontColor={'grayDark'}
                                    marginBottom={'xs'}
                                    fontWeight={"normal"}
                            />
                            {!isOwnerSeleted && renderSearchField()}
                            {isOwnerSeleted && renderAssignOwnerField()}
                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'owner-assign-action'}>
                        <div className='d-flex gap-3 mt-3 mb-3'>
                            <DLButton
                                id={'assign-owner-cancelBtn'}
                                history={props.history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"none"}
                                onClick={() => handleCloseModal('isReAssignOwnerModalOpen')}/>
                            <DLButton
                                id={'assign-owner-assignBtn'}
                                history={props.history}
                                label={'Assign'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"none"}
                                onClick={() => onAssignOwner()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    return (renderReAssignOwnerModal());
};

export default ReAssignOwnerModelView;