import React, {useState} from 'react';
import {connect} from "react-redux";


import NewUserView from "./addUserView";
import {addUser, getAllUserData} from "../UserManagementAction";
import {APP_CONST} from "../../appComponents/AppConstants";
import {isEmpty} from "../../utils/validations";
import store from "../../store";
import {showToastMessage} from "../../appComponents/AppActions";

const CreateTask = (props) => {
    const [createUserState, setCreateUserState] = useState({
        searchKeyword: "",
        personId: "",
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        isTrainee: false,

        errorObj:{
            firstNameError: "",
            lastNameError: "",
            emailError: "",
            userNameError: "",
            responseError: "",
        },

        isShowInlineLoader: false,
        isShowLoader: false,
        selectedPersonData: null,
        userSearchData: []
    });

    const {isNewUserOpen, handleCloseNewUser} = props;

    const onChangeFilter = (value) => {
        setCreateUserState((prev) => ({
            ...prev,
            searchKeyword: value
        }));
    };

    const onChangeValue = (name, value) => {
        setCreateUserState((prev) => ({
            ...prev,
            [name]: value,
            errorObj: {
                ...prev.errorObj,
                [name + 'Error']: '',
                responseError: '',
            }
        }));
    };

    const onBlurInputTextField = (field) => {
        let errors = validateChanges(field, addPeptideState);
        setCreateUserState((prev) => ({
            ...prev,
            errorObj: {
                ...prev.errorObj,
                ...errors
            }
        }));
    };

    const validateChanges = (title, compState) => {
        const {patentNumber, personId, firstName, lastName, email, userName} = createUserState;
        let errorObj = {...compState};

        if (isEmpty(personId)){
            if (title === "firstName" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(firstName)) {
                    errorObj.firstNameError = "Please enter a first name";
                }else if(firstName.length > 50){
                    errorObj.firstNameError = "Maximum 50 characters allowed";
                }else {
                    errorObj.firstNameError = "";
                }
                if (title === "firstName") return errorObj;
            }
            if (title === "lastName" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(lastName)) {
                    errorObj.lastNameError = "Please enter a last name";
                }else if(lastName.length > 50){
                    errorObj.lastNameError = "Maximum 50 characters allowed";
                } else {
                    errorObj.lastNameError = "";
                }
                if (title === "lastName") return errorObj;
            }
            if (title === "email" || title === APP_CONST.FORM_SUBMISSION) {
                if (isEmpty(email)) {
                    errorObj.emailError = "Please enter a email";
                }else if(email.length > 50){
                    errorObj.emailError = "Maximum 50 characters allowed";
                } else {
                    errorObj.emailError = "";
                }
                if (title === "email") return errorObj;
            }
        }

        if (title === "userName" || title === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(userName)) {
                errorObj.userNameError = "Please enter a user name";
            } else {
                errorObj.userNameError = "";
            }
            if (title === "userName") return errorObj;
        }
        return errorObj;
    };

    const searchUser = (keyword) => {
        let payload = {
            filters:{
                "keyword": keyword
            }
        };
        setCreateUserState(prevState => ({
            ...prevState,
            isShowInlineLoader: true
        }));

        getAllUserData(payload)
            .then(res => {
                if (res?.success) {
                    setCreateUserState(prevState => ({
                        ...prevState,
                        userSearchData: formatUserData(res.data?.users),
                        isShowInlineLoader: false
                    }));
                } else {
                    setCreateUserState(prevState => ({
                        ...prevState,
                        isShowInlineLoader: false
                    }));
                }
            })
    };

    const formatUserData = (userData) =>{
        if (isEmpty(userData)){
            return null
        }

        return userData.map(item => {
            return {...item, label: item.userName, value: item.userId}
        });
    };

    const addSearchId = (id) =>{
        const {userSearchData} = createUserState;
        let selectedData = userSearchData.find((item) => item.value === id);

        setCreateUserState(prevState => ({
            ...prevState,
            userSearchData: [],
            firstName: selectedData?.firstName,
            lastName: selectedData?.lastName,
            email: selectedData?.email,
            errorObj:{
                ...prevState.errorObj,
                firstNameError: "",
                lastNameError: "",
                emailError: "",
                responseError: "",
            }
        }));
    };

    const checkIfValidateObj = (validateObj) => {
        const {firstNameError, lastNameError, emailError, userNameError} = validateObj;

        return !(firstNameError || lastNameError || emailError || userNameError);
    };

    const createUser = () =>{
        const {firstName, lastName, email, userName, isTrainee, personId} = createUserState;
        let errorObj = validateChanges(APP_CONST.FORM_SUBMISSION, createUserState.errorObj);

        setCreateUserState(prevStep => ({
            ...prevStep,
            errorObj: {
                ...errorObj
            },
        }));

        if (!checkIfValidateObj(errorObj)) {
            return;
        }

        setCreateUserState(prevStep => ({
            ...prevStep,
            isShowLoader: true,
        }));

        let payload = {
            personId: personId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            userName: userName,
            isTrainee:  isTrainee
        };

        addUser(payload, true)
            .then(res => {
                if (res?.success) {
                    setCreateUserState(prevState => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    store.dispatch(showToastMessage('success', `User ${userName} added successfully`));
                    props.refreshFullScreen("isNewUserOpen");
                } else {
                    setCreateUserState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        errorObj: {
                            ...prevState.errorObj,
                            responseError: res?.__error
                        },
                    }));
                }
            });
    };

    return (
        <NewUserView
            {...props}
            {...createUserState}
            handleCloseNewUser={handleCloseNewUser}
            isNewUserOpen={isNewUserOpen}
            createUser={createUser}
            onChangeFilter={onChangeFilter}
            onChangeValue={onChangeValue}
            searchUser={searchUser}
            onBlurInputTextField={onBlurInputTextField}
            addSearchId={addSearchId}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(CreateTask);
