import React, {useEffect, useState} from "react";
import LoginView from "./LoginView";
import {connect} from "react-redux";
import {clearPostLoginUrl, showToastMessage} from "../../appComponents/AppActions";
import {withTranslation} from "react-i18next";
import {loginConst} from "../userConsts";
import {APP_CONST} from "../../appComponents/AppConstants";
import {isEmpty} from "../../utils/validations";
import {setTokenAfterLogin, userLogin} from "../userActions";
import store from "../../store";
import {loginFormData} from "./loginModal";
import {GetMyProfileData} from "../userReducer";

let loginErrorConst = Object.keys(loginFormData).reduce((acc, item) => {
    return {
        ...acc,
        [item + 'Error']: '',
    };
}, {});


const Login = (props) => {
    const [loginData, setLoginData] = useState({
        formData: {
            ...loginFormData,
            ...loginErrorConst
        },
        isLoginInProgress: false,
        scrollToElement: null,
        UIState: loginConst.LOGIN,
        wrongPasswordError:''
    });

    const signInNavigation = () => {
        setLoginData(loginData => ({
            ...loginData,
            UIState: loginConst.LOGIN
        }));
    };

    const changeValue = (name, value) => {
        if (name === 'showPassword' || name === 'rememberMe'){
            setLoginData(loginData => ({
                ...loginData,
                formData: {
                    ...loginData.formData,
                    [name]:value,
                }
            }));
            return
        }
        validateChanges(name,value)
    };


    useEffect(() => {
        return () => {
            props.clearPostLoginUrl();
        }
    }, []);


    const validateChanges = (key, value) => {
        const {userName, password} = loginData.formData;

        if (key === "userName" ) {
            if (isEmpty(value)) {
                setLoginData(loginData => ({
                    ...loginData,
                    formData: {
                        ...loginData.formData,
                        [key]:value,
                        userNameError: 'Please enter username'
                    }
                }));
                return false;
            }

            if (value.length > 50) {
                setLoginData(loginData => ({
                    ...loginData,
                    formData: {
                        ...loginData.formData,
                        [key]:value,
                        userNameError: 'Please enter upto 50 characters'
                    }
                }));
                return false;
            }

            setLoginData(loginData => ({
                ...loginData,
                wrongPasswordError:'',
                formData: {
                    ...loginData.formData,
                    [key]:value,
                    [key + 'Error']:'',
                }
            }));
            return true;
        }

        if (key === 'password') {
            if (isEmpty(value)) {
                setLoginData(loginData => ({
                    ...loginData,
                    formData: {
                        ...loginData.formData,
                        [key]:value,
                        passwordError: 'Enter a password'
                    }
                }));
                return false;
            }
            setLoginData(loginData => ({
                ...loginData,
                formData: {
                    ...loginData.formData,
                    [key]:value,
                    [key + 'Error']:'',
                }
            }));
            return true;
        }

        if (key === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(userName)) {
                setLoginData(loginData => ({
                    ...loginData,
                    formData: {
                        ...loginData.formData,
                        userNameError: 'Please enter usename'
                    }
                }));
                return false;
            }

            if (userName.length > 50) {
                setLoginData(loginData => ({
                    ...loginData,
                    formData: {
                        ...loginData.formData,
                        userNameError: 'Please enter upto 50 characters'
                    }
                }));
                return false;
            }
        }

        if (key === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(password)) {
                setLoginData(loginData => ({
                    ...loginData,
                    formData: {
                        ...loginData.formData,
                        passwordError: 'Enter a password'
                    }
                }));
                return false;
            }
        }
        return true;
    };

    const handleNavigation = () => {
        const {history, isRedirect, postLoginUrl, postLoginUrlProps} = props;

        if (isRedirect) {
            if (isEmpty(postLoginUrl)) {
                history.replace('/');
            } else {
                if (postLoginUrl.includes('?')) {
                    history.replace(postLoginUrl, postLoginUrlProps);
                } else {
                    history.replace(postLoginUrl, postLoginUrlProps);
                }
            }
        } else {
            history.replace('/');
        }
    };

    const submitFormWithDelay = (e) =>{
        e.preventDefault();

        setTimeout(()=>{
            submitForm();
        },500)
    };

    const submitForm = () => {
        const {userName, password} = loginData.formData;

        const { isLoginInProgress} = props;

        if (isLoginInProgress) {
            return;
        }

        if (!validateChanges(APP_CONST.FORM_SUBMISSION)) {
            return;
        }

        let userData = {
            userName: userName.trim().toLowerCase(),
            password: password,
        };

        setLoginData(loginData => ({
            ...loginData,
            isLoginInProgress: true
        }));


        userLogin(userData)
            .then(res => {
                setLoginData(loginData => ({
                    ...loginData,
                    isLoginInProgress: false
                }));

                if (res.success){
                    setTokenAfterLogin(res.data, userData.rememberMe)
                        .then((isTokenSet) => {
                            if (isTokenSet) {
                                store.dispatch(GetMyProfileData(res.data?.user));
                                handleNavigation();
                            } else {
                                dispatch(showToastMessage('danger', 'Failed to login'));
                            }
                        });
                }
                if (res.statusCode === 400){
                    if (res.__error === 'Username not found') {
                        setLoginData(loginData => ({
                            ...loginData,
                            wrongPasswordError: 'Username does not exist'
                        }));
                    }else {
                        setLoginData(loginData => ({
                            ...loginData,
                            wrongPasswordError: 'Username / Password combination is not matching.'
                        }));

                    }
                }
                if(res.statusCode === 403){
                    setLoginData(loginData => ({
                        ...loginData,
                        wrongPasswordError: res?.__error
                    }));
                }
                if (res.statusCode === 500){
                    setLoginData(loginData => ({
                        ...loginData,
                        wrongPasswordError: 'Username / Password combination is not matching.'
                    }));
                }
            })

    };

    const checkKey = (event) => {
        if (event?.key === 'Enter') {
            submitFormWithDelay();
        }
    };

    const clearFormData = () => {
        let loginErrorConst = Object.keys(loginFormData).reduce((acc, item) => {
            return {
                ...acc,
                [item + 'Error']: '',
            };
        }, {});
        setLoginData(loginData => ({
            ...loginData,
            formData: {
                ...loginFormData,
                ...loginErrorConst
            }
        }));


    };

    const onChangeUiState = (uiState) => {
        setLoginData(loginData => ({
            ...loginData,
            formData: {
                ...loginData.formData,
                password: '',
            },
            UIState: uiState
        }));
    };

    const tryAgain = () => {
        setLoginData(loginData => ({
            ...loginData,
            formData: {
                ...loginData.formData,
                password: '',
            },
            UIState: loginConst.LOGIN
        }));
    };


    return (
        <LoginView
            {...props}
            loginData={loginData}
            onFormDataChange={changeValue}
            submitFormWithDelay={submitFormWithDelay}
            clearFormData={clearFormData}
            onChangeUiState={onChangeUiState}
            signInNavigation={signInNavigation}
            tryAgain={tryAgain}
            checkKey={checkKey}
        />
    );
};

Login.propTypes = {};

const mapStateToProps = (state) => ({
    isRedirect: state.appState.isRedirect,
    postLoginUrl: state.appState.postLoginUrl,
    postLoginUrlProps: state.appState.postLoginUrlProps,
    userAuthStatus: state.userState.userAuthStatus,
    windowHeight: state.appState.deviceInfo.windowHeight,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView
});

export default connect(mapStateToProps, {
    clearPostLoginUrl
})(withTranslation('translations')(Login));
