import { configureStore } from '@reduxjs/toolkit'
import appReducer from "../src/appComponents/AppReducer";
import UserManagementReducer from "../src/UserManagement/userManagementReducer";
import productManagementReducer from "../src/products/productManagementReducer";
import userReducer from "./userAuth/userReducer";

const store = configureStore({
  reducer: {
    appState: appReducer,
    userState: userReducer,
    userManagementState: UserManagementReducer,
    productManagementState: productManagementReducer
  }
});
export default store;
