import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import {
    getAllProductCategoriesError
} from "../../../productManagementAction";
import ErrorCategoriesView from "./ErrorCategoriesView";

const ErrorCategories = (props) => {
    const [errorCategoriesState, setErrorCategoriesState] = useState({
        listingCount: 0,
        isShowLoader: false,
        openErrorModel: false,
        modelData: null
    });

    useEffect(() => {
        getAllProductCategoriesErrorData();
    }, []);

    const getAllProductCategoriesErrorData = () => {
        const {productDetails} = props;

        setErrorCategoriesState(prevState => ({
            ...prevState,
            isShowLoader: true,
        }));

        let payload = {
            productId: productDetails?.id
        };

        getAllProductCategoriesError(payload)
            .then(res => {
                if (res?.success) {
                    setErrorCategoriesState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        productsErrorDetails: res.data?.errorCategories,
                        listingCount: res.data?.count
                    }));
                } else {
                    setErrorCategoriesState(prevState => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                }
            })
    };

    const refreshFullScreen = (model) =>{
        setErrorCategoriesState(prevState => ({
            ...prevState,
            isShowLoader: true,
            [model]: false,
            modelData: null
        }));
        getAllProductCategoriesErrorData();
    };

    const openModel = (model, data) => {
        setErrorCategoriesState(prevState => ({
            ...prevState,
            [model]: true,
            modelData: data
        }));
    };

    const handleCloseModal = (model) => {
        setErrorCategoriesState((prevState) => ({
            ...prevState,
            [model]: false,
            modelData: null
        }));
    };

    const getColor = (category) =>{
        if (!category){
            return
        }

        if (category === 'HIGH'){
            return '#FC0000';
        }
        if (category === 'LOW'){
            return '#FFDFDF';
        }
        if (category === 'MEDIUM'){
            return '#FF8B8B';
        }
    };

    return (
        <ErrorCategoriesView
            {...props}
            {...errorCategoriesState}
            openModel={openModel}
            handleCloseModal={handleCloseModal}
            refreshFullScreen={refreshFullScreen}
            getColor={getColor}
        />
    )
};

const mapStateToProps = (state) => ({
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(ErrorCategories);