import React, {useState} from 'react';
import {connect} from "react-redux";
import store from "../../../../store";

import {showToastMessage} from "../../../../appComponents/AppActions";
import {isEmpty} from "../../../../utils/validations";

import QASamplingModelView from "./QASamplingModelView";

const QASamplingModel = (props) => {

    const {handleCloseModal} = props;

    const [qaSamplingState, setQASamplingState] = useState({
        isModalOpen: false,
        selectedLowerThreshold: '',
        selectedUpperThreshold: ''
    });

    const onQASamplingSave = () => {

        if (isEmpty(qaSamplingState.selectedLowerThreshold) || isEmpty(qaSamplingState.selectedUpperThreshold)) {
            store.dispatch(showToastMessage('warning', 'Please enter the QA Sampling value'))
        } else if ((qaSamplingState.selectedUpperThreshold) <= (qaSamplingState.selectedLowerThreshold)) {
            store.dispatch(showToastMessage('warning', 'Upper threshold value should be greater than lower threshold value'))
        } else {
            store.dispatch(showToastMessage('success', 'QA Sampling value is saved'))
            handleCloseModal('isQASamplingModalOpen');
        }

    };

    const onSelectThresholdValue = (name, thresholdValue) => {
        (name === 'lowerThreshold') ?
            setQASamplingState((prevState) => ({
                ...prevState,
                selectedLowerThreshold: thresholdValue
            })) :
            setQASamplingState((prevState) => ({
                ...prevState,
                selectedUpperThreshold: thresholdValue
            }))
    };

    return (
        <QASamplingModelView
            {...props}
            {...qaSamplingState}
            onQASamplingSave={onQASamplingSave}
            onSelectThresholdValue={onSelectThresholdValue}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(QASamplingModel);
