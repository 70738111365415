import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import store from "../../../../store";

import {showToastMessage} from "../../../../appComponents/AppActions";
import {isEmpty} from "../../../../utils/validations";

import ReAssignOwnerModelView from "./ReAssignOwnerModelView";
import {getAllUserData} from "../../../../UserManagement/UserManagementAction";
import {APP_CONST} from "../../../../appComponents/AppConstants";
import {setAssignOwner, updateAssignOwner} from "../../../productManagementAction";

const ReAssignOwnerModel = (props) => {

    const {handleCloseModal, getAllData} = props;

    const [reAssignOwnerState, setReAssignOwnerState] = useState({
        isModalOpen: false,
        selectedOwner: '',
        searchKeyword: '',
        userSearchData: [],
        isOwnerSeleted: false,
        isShowInlineLoader: false,
        isEdit: false,
        errorObj: {
            responseError: "",
        }
    });

    useEffect(() => {
        let ownerDetails = '';
        if (!isEmpty(props?.productDetails) && props?.productDetails?.productAssignments?.length !== 0) {
            ownerDetails = props?.productDetails?.productAssignments.find(item => item.roleId === "PRODUCT_OWNER");
            if (!isEmpty(ownerDetails)) {
                setReAssignOwnerState(prevState => ({
                    ...prevState,
                    userSearchData: [],
                    selectedOwner: ownerDetails,
                    isOwnerSeleted: true,
                    isEdit: true,
                    errorObj: {
                        ...prevState.errorObj,
                        responseError: "",
                    }
                }));
            }
        }
    }, []);

    const formatUserData = (userData) => {
        if (isEmpty(userData)) {
            return null
        }

        return userData.map(item => {
            return {...item, label: item.userName, value: item.userId}
        });
    };

    const searchUser = (keyword) => {

        setReAssignOwnerState(prevState => ({
            ...prevState,
            isShowInlineLoader: true,
        }));

        let payload = {
            filters: {
                // 'roleId': 'PRODUCT_OWNER',
                "keyword": keyword
            }
        };

        getAllUserData(payload)
            .then(res => {
                if (res?.success) {
                    setReAssignOwnerState(prevState => ({
                        ...prevState,
                        isShowInlineLoader: false,
                        userSearchData: formatUserData(res.data?.users),
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setReAssignOwnerState(prevState => ({
                        ...prevState,
                        isShowInlineLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const onAssignOwner = () => {
        if (!isEmpty(reAssignOwnerState.selectedOwner)) {
            setReAssignOwnerState(prevState => ({
                ...prevState,
                isShowLoader: true,
            }));

            let payload = {
                "roleId": "PRODUCT_OWNER",
                "productId": props.productDetails.id,
                "targetUserId": reAssignOwnerState.selectedOwner.userId
            };

            if (reAssignOwnerState.isEdit) {
                updateAssignOwner(payload)
                    .then(res => {
                        if (res?.success) {
                            setReAssignOwnerState(prevState => ({
                                ...prevState,
                                isShowLoader: false,
                                selectedOwner: '',
                                searchKeyword: '',
                            }));
                            getAllData(props.productDetails.id);
                            handleCloseModal('isReAssignOwnerModalOpen');
                            store.dispatch(showToastMessage('success', 'Owner is assigned'))
                        } else {
                            setReAssignOwnerState(prevState => ({
                                ...prevState,
                                isShowLoader: false,
                                errorObj: {
                                    ...prevState.errorObj,
                                    responseError: res?.__error
                                }
                            }));
                        }
                    });
            } else {
                setAssignOwner(payload)
                    .then(res => {
                        if (res?.success) {
                            setReAssignOwnerState(prevState => ({
                                ...prevState,
                                isShowLoader: false,
                                selectedOwner: '',
                                searchKeyword: '',
                            }));
                            getAllData(props.productDetails.id);
                            handleCloseModal('isReAssignOwnerModalOpen');
                            store.dispatch(showToastMessage('success', 'Owner is assigned'))
                        } else {
                            setReAssignOwnerState(prevState => ({
                                ...prevState,
                                isShowLoader: false,
                                errorObj: {
                                    ...prevState.errorObj,
                                    responseError: res?.__error
                                }
                            }));
                        }
                    });
            }
        } else {
            setReAssignOwnerState(prevState => ({
                ...prevState,
                isShowLoader: false,
                errorObj: {
                    ...prevState.errorObj,
                    responseError: 'Please select the owner'
                }
            }));
        }
    };

    const onChangeFilter = (value) => {
        setReAssignOwnerState((prev) => ({
            ...prev,
            searchKeyword: value,
        }));
    };

    const getChangeOwner = () => {
        setReAssignOwnerState((prev) => ({
            ...prev,
            isOwnerSeleted: false,
            selectedOwner: '',
        }));
    };

    const addSearchId = (id) => {
        const {userSearchData} = reAssignOwnerState;
        let selectedData = userSearchData.find((item) => item.value === id);

        setReAssignOwnerState(prevState => ({
            ...prevState,
            userSearchData: [],
            selectedOwner: selectedData,
            isOwnerSeleted: true,
            errorObj: {
                ...prevState.errorObj,
                responseError: "",
            }
        }));
    };

    return (
        <ReAssignOwnerModelView
            {...props}
            {...reAssignOwnerState}
            searchUser={searchUser}
            addSearchId={addSearchId}
            getChangeOwner={getChangeOwner}
            onAssignOwner={onAssignOwner}
            onChangeFilter={onChangeFilter}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(ReAssignOwnerModel);