import React from 'react';

import DLButton from "../../core/components/DLButton/DLButton";
import DLModal from "../../core/components/DLModal/DLModal";
import DLModalTitle from "../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../core/components/DLModal/DLModalActions/DLModalActions";
import DLText from "../../core/components/DLText/DLText";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {isEmpty} from "../../utils/validations";

const DeleteDocumentView = (props) => {
    const {
        history,
        isDeleteModalOpen,
        handleCloseModal,
        deleteUser
    } = props;

    const renderErrorUI = () => {
        const {responseError} = props;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'py-2'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    const renderDeleteDocumentModal = () => {
        const {modelData} = props;
        return (
            <DLModal
                history={history}
                maxWidth={"xs"}
                onClose={() => handleCloseModal("isDeleteModalOpen")}
                open={isDeleteModalOpen}
                id={'delete-modal'}>
                <DLModalTitle
                    id={'delete-title'}
                    onClose={() => handleCloseModal("isDeleteModalOpen")}
                    title={'Confirmation'}/>
                <DLModalContainer
                    id={'delete-container'}>
                    <div>
                        {renderErrorUI()}
                    <div className='mt-4'>
                        <div className='text-center'>
                            <DLText id={'delete-message'}
                                    text={`Are you sure you want to remove the user ${modelData?.userName}?`}
                                    fontSize={"sm"}
                                    fontWeight={"normal"}
                            />
                        </div>
                    </div>
                    </div>
                </DLModalContainer>
                <DLModalActions id={'delete-action'}>
                    <div className='d-flex gap-3 mt-3 mb-3'>
                        <DLButton
                            id={'delete-cancel-btn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"none"}
                            onClick={() => {
                                handleCloseModal("isDeleteModalOpen")
                            }}/>
                        <DLButton
                            id={'delete-assign-btn'}
                            history={props.history}
                            label={'Remove'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"none"}
                            type={"danger"}
                            onClick={() => {
                                deleteUser()
                            }}
                        />
                    </div>
                </DLModalActions>
            </DLModal>
        );
    };

    const renderLoader = () => {
        const {isShowLoader} = props;
        return (
            <DLLoader type={"screen"} isVisible={isShowLoader}/>
        )
    };

    return (
        <div>
            {renderDeleteDocumentModal()}
            {renderLoader()}
        </div>
    );
};

export default DeleteDocumentView;