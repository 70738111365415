export const homepageTableHeaders = ['User Name', 'Email', 'Role', 'Start Date', 'Action'];

export const roleSummary = [
    {
        label: 'Curator',
        value: 'CURATOR',
    },
    {
        label: 'QA Personnel',
        value: 'QA',
    }
];

export const cellConfig = [
    {
        textAlign: 'left',
        width: '250px',
        minWidth: '250px',


    },
    {
        width: '110px',
        minWidth: '300px',
        textAlign: 'left',

    },
    {
        textAlign: 'left',
        width: '300px',
        minWidth: '300px',

    },
    {
        textAlign: 'left',
        width: '90px',
        minWidth: '200px',


    },
    {
        textAlign: 'center',
        width: '50px',
        minWidth: '100px',

    }
]