import {connect} from "react-redux";
import HomePageView from "./HomePageView";
import React from "react";

const HomePage = (props) => {

    return (
        <HomePageView
            {...props}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(HomePage);
