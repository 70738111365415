import Dropdown from "react-bootstrap/Dropdown";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import {getRandomId, isEmpty} from "../DLComponentHelper";
import DLLoader from "../DLLoader/DLLoader";
import DLText from "../DLText/DLText";
import appTheme from "../../../assets/appTheme";

const DLSearchDropdownView = (props) => {
    const {
        value,
        placeholder,
        onChangeValue,
        data,
        isShowInlineLoader,
        dropdownHeight,
        loader,
        helperMessage,
        disableHelperMessageHeight,
        error,
        onClickItem,
        renderCustomItemUI,
        inputMarginBottom,
        noMatchMessage
    } = props;

    const getDropdownUI = () => {
        if (isShowInlineLoader || loader) {
            return (
                <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{height: dropdownHeight}}
                >
                    <DLLoader isVisible={isShowInlineLoader || loader} type="inline"/>
                </div>
            );
        }
        if (isEmpty(data) && !isShowInlineLoader) {
            return (
                <div className="w-100" style={{height: dropdownHeight}}>
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <DLText
                            id=""
                            text={noMatchMessage}
                            marginBottom="none"
                        />
                    </div>
                </div>
            );
        }

        return (
            <div>
                {data?.map((item, index) => (
                    <Dropdown.Item
                        onClick={() =>{
                            onChangeValue("");
                            onClickItem(item?.value)
                       } }
                        key={getRandomId()}
                    >
                        <div className={index === 0 ? "p-2" : "p-2 border-top"}>
                            <div
                                style={{
                                    whiteSpace: "normal",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                {renderCustomItemUI ? (
                                    renderCustomItemUI(item)
                                ) : (
                                    <DLText
                                        id=""
                                        text={item?.label}
                                        fontWeight="normal"
                                        fontSize="xs"
                                        isInline={true}
                                        marginBottom="none"
                                    />
                                )}
                            </div>
                        </div>
                    </Dropdown.Item>
                ))}
            </div>
        );
    };

    return (
        <div>
            <div className={"w-100"} style={{position: 'relative'}}>
                <div className="resappbar-search-box-container" style={{flex: 1}}>
                    <Dropdown className='w-100' autoClose="outside" style={{borderColor: error && isEmpty(value) ? appTheme.appColor.danger : ''}}>
                        <Dropdown.Toggle id="dropdown" className='py-1'>
                            <div className="w-100">
                                <Paper
                                    className="res-app-paper"
                                    component=""
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        boxShadow: "none",
                                        p: 0,
                                    }}
                                >
                                    <InputBase
                                        sx={{flex: 1, fontSize: '14px', fontWeight: 500, p: '0px 0px', marginBottom: inputMarginBottom,}}
                                        fullWidth
                                        autoFocus={true}
                                        placeholder={placeholder}
                                        value={value}
                                        onChange={(e) => {
                                            onChangeValue(e.target.value)
                                        }}
                                    />
                                    {
                                        <SearchIcon style={{color: error && isEmpty(value) ? appTheme.appColor.danger : ''}}/>
                                    }
                                </Paper>
                            </div>
                        </Dropdown.Toggle>
                    </Dropdown>
                    {
                        (!isEmpty(value) &&
                            <div className='resappbar-search-box-container-options mt-1'
                                 style={{
                                     position: "absolute",
                                     zIndex: 10,
                                     width: "100%",
                                     maxHeight: 'auto',
                                     overflowY: "auto",
                                 }}>
                                <div style={{maxHeight: dropdownHeight, overflowY: "auto"}}>
                                    {getDropdownUI()}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div style={{paddingLeft: '14px',minHeight: disableHelperMessageHeight ? '0' : '19px'}}>
                {error && isEmpty(value) && (
                    <DLText
                        id="helper-message"
                        text={helperMessage}
                        fontColor="danger"
                        fontSize="xs"
                        marginBottom="none"
                    />
                )}
            </div>
        </div>
    )
}

export default DLSearchDropdownView;