import React from 'react';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from '@mui/material/MenuItem';

import appTheme from "../../assets/appTheme";
import {userConst} from "../../userAuth/userConsts";
import {isEmpty} from "../../utils/validations";
import {getRandomColors} from "../../utils/helper";
import "../../assets/css/headerFooter.css"
import DLText from "../../core/components/DLText/DLText";
import productMenuIcon from "../../assets/img/productMenuIcon.png"
import userMenuIcon from "../../assets/img/userMenuIcon.png"
import changePassword from "../../assets/img/appIcons/changePassword.svg"

AppResAppBarView.propTypes = {};

function AppResAppBarView(props) {
    const {userAuthStatus} = props;

    const renderAppLogo = () => {
        return (
            <div className='d-flex align-items-center'>
                <div id={"logo"} className='cursor'
                     style={{color: appTheme.appColor.primary, fontSize: 24, fontWeight: 600}}
                     onClick={() => props.history.push('/')}>
                    CM App
                </div>
            </div>
        );
    };

    const renderCuratorMenu = () => {
        const {handleSettingIconClick, anchorSettings, handleMenuItemsClose, handleMenuItemsOpen} = props;

        let menuOptions = [
            {
                label: 'Tasks',
                isDisabled: false,
                onClick: () => {
                    props.history.push('/content-curation/tasks')
                },
                isVisible: true,
            }
        ];

        let icon = <div
            onClick={handleMenuItemsOpen}>
            Curation
        </div>;

        let menu = (
            <Menu
                id="items-menu"
                anchorEl={anchorSettings}
                getcontentanchorel={null}
                anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                transformOrigin={{vertical: "top", horizontal: "left"}}
                open={Boolean(anchorSettings)}
                onClose={handleMenuItemsClose}
                className="p-2"
                style={{
                    top: '12px',
                    bottom: '500px'
                }}
                disableAutoFocusItem
                PaperProps={{
                    style: {
                        marginLeft: '0px',
                    }
                }}
                MenuListProps={{
                    style: {
                        paddingTop: 6,
                        paddingBottom: 6,
                        color: '#0B407D'
                    },
                }}>

                {menuOptions.map((item, index) => {

                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'profile-menuItem-' + index}
                                      className={'cursor dropdownMenu'}
                                      onClick={(event) => {
                                          handleMenuItemsClose();
                                          item?.onClick()
                                      }}
                                      sx={{fontFamily: 'Poppins, sans-serif'}}
                            >
                                {item?.label}
                            </MenuItem>
                        )
                    }

                    return <span key={'profile-menuItem-' + index}/>

                })}
            </Menu>
        );


        return (
            <div onClick={() => {
                handleSettingIconClick(false)
            }}>
                {icon}
                {menu}
            </div>
        );

    };

    const getMenubarStatus = () => {
        return (
            location.pathname === '/users'
            || location.pathname === '/'
            || location.pathname === '/products'
            || location.pathname === '/content-curation/tasks')
    };

    const renderMobileDrawerBtn = () => {
        const {isXSView, isSMView} = props;
        let isVisibleMenuBar = getMenubarStatus();

        if ((isXSView || isSMView) && isVisibleMenuBar) {
            return (
                <div className="position-fixed end-0 bottom-0 bg-white border-top w-100 px-3 "
                     style={{height: 54}}>
                </div>
            )
        }

    };

    const renderNavigatorWithOptions = (role) => {
        if (isEmpty(role)) {
            return;
        }

        if (role === 'SYSTEM_ADMIN' || role === 'PRODUCT_OWNER' || role === 'PRODUCT_ADMIN') {
            return (<div className='ms-5'>
                <nav id='app-header-nav' className="d-flex align-items-center" style={{gap: 40}}>
                    <div onClick={() => props.history.push('/')}
                         className='text-black fs-6 cursor'>
                        Home
                    </div>
                    {/*<div className='text-black fs-6 cursor'>*/}
                    {/*    {renderAcquisitionUI()}*/}
                    {/*</div>*/}
                    {/*<div onClick={() => {*/}
                    {/*}}*/}
                    {/*     className='text-black fs-6 cursor'>*/}
                    {/*    {renderCuratorMenu()}*/}
                    {/*</div>*/}
                    {/*<div className='text-black fs-6'>*/}
                    {/*    Publishing*/}
                    {/*</div>*/}
                    {/*<div className='text-black fs-6'>*/}
                    {/*    Distribution*/}
                    {/*</div>*/}
                </nav>
            </div>)
        }

        return (
            <div className='ms-5'>
                <nav id='app-header-nav' className="d-flex align-items-center" style={{gap: 40}}>
                    <div onClick={() => {
                    }}
                         className='text-black fs-6 cursor'>
                        {/*{renderCuratorMenu()}*/}
                    </div>
                </nav>
            </div>)
    };

    const renderLogoutField = () => {
        return (
            <div className='d-flex gap-2 align-items-center flex-grow-1'
                 style={{color: appTheme.appColor.grayDark}}>
                <div>
                    <i className="fa-solid fa-arrow-right-from-bracket "/>
                </div>
                <DLText id={'profile-name-field'}
                        isInline={true}
                        fontSize={'sm'}
                        fontWeight={'normal'}
                        text={'Logout'}
                />
            </div>
        );
    };

    const renderChangePasswordField = () => {
        return (
            <div className='d-flex gap-2 align-items-center flex-grow-1 mb-1'
                 style={{color: appTheme.appColor.grayDark}}>
                <div>
                    <img
                        src={changePassword}
                        alt={'img'}
                        width={'16px'}
                        height={'16.8px'}/>
                </div>
                <DLText id={'Change-Password-field'}
                        isInline={true}
                        fontSize={'sm'}
                        marginBottom={'none'}
                        fontWeight={'normal'}
                        text={'Change Password'}
                />
            </div>
        );
    };

    const renderNameAndIcon = () => {
        const {profile} = props;
        let profileImage;

        if (!isEmpty(profile)) {
            profileImage = props.profile?.userName?.slice(0, 1)
        }

        return (
            <div className='d-flex mx-3 pb-2 align-items-center border-bottom mb-2'>
                <Avatar className='profile-img p-3 '
                        style={{
                            backgroundColor: getRandomColors(props.profile?.userName)
                        }}>
                    {isEmpty(profileImage) ? <></> :
                        profileImage?.toUpperCase()
                    }
                </Avatar>
                <div className='ms-2 mb-1'>
                    <div>
                        <DLText id={'profile-name-field'}
                                isInline={true}
                                fontSize={'sm'}
                                marginBottom={"none"}
                                fontWeight={'semi-bold'}
                                text={' ' + props.profile?.userName}/>
                    </div>
                   {!isEmpty(profile?.roles) && <div style={{lineHeight: '20px'}}>
                        <DLText id={'profile-name-field'}
                                isInline={true}
                                fontSize={'xs'}
                                fontWeight={'normal'}
                                fontColor={"grayDark"}
                                text={' ' + profile?.roles[0].name}
                        />
                    </div>}
                </div>
            </div>
        )
    };

    const renderSettingMenu = (role) => {
        const {
            handleSettingModelOpen, profile, handleUserManagementClick, settingAnchor, handleSettingIconClick, handleSettingModelClose,
            handleMenuItemsClose, isLoggedOutScreen, handleProductManagementClick
        } = props;

        if (isEmpty(role)) {
            return;
        }

        if (role !== 'SYSTEM_ADMIN' || isLoggedOutScreen || isEmpty(profile.roles)) {
            return;
        }

        let menuOptions = [];

        if (role === 'SYSTEM_ADMIN') {
            menuOptions.push({
                label: "User Management",
                isDisabled: false,
                icon: <img
                    src={userMenuIcon}
                    alt={'img'}
                    className='me-2'
                    width={'16px'}
                    height={'12px'}/>,
                onClick: () => {
                    handleUserManagementClick();
                },
                isVisible: true,
            });

            menuOptions.push({
                label: "Product Management",
                isDisabled: false,
                icon: <img
                    src={productMenuIcon}
                    alt={'img'}
                    className='me-2'
                    width={'16px'}
                    height={'12px'}/>,
                onClick: () => {
                    handleProductManagementClick();
                },
                isVisible: true,
            })
        }

        let icon = (
            <div className="d-flex align-items-center me-2">
                <div className='cursor'>
                    <Avatar className='profile-img p-3 cursor'
                            onClick={handleSettingModelOpen}
                            style={{backgroundColor: appTheme.appColor.white, color: appTheme.appColor.grayDark}}
                    >
                        <i className="fa-solid fa-gear fa-lg"/>
                    </Avatar>
                </div>
            </div>
        );

        let menu = (
            <Menu
                id="items-menu"
                anchorEl={settingAnchor}
                getcontentanchorel={null}
                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={Boolean(settingAnchor)}
                onClose={handleSettingModelClose}
                className="p-2"
                style={{
                    top: '12px'
                }}
                disableAutoFocusItem
                PaperProps={{
                    style: {
                        marginLeft: '0px'
                    }
                }}
                MenuListProps={{
                    style: {
                        paddingTop: 6,
                        paddingBottom: 6,
                        color: appTheme.appColor.black
                    },
                }}>

                {menuOptions.map((item, index) => {

                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'profile-menuItem-' + index}
                                      className={item?.isDisabled ? 'dropdownMenuDisable' : 'cursor dropdownMenu'}
                                      onClick={() => {
                                          if (!item?.isDisabled) {
                                              handleMenuItemsClose();
                                              item?.onClick()
                                          }
                                      }}>
                                {item?.icon}
                                <DLText id={'product-Management-field'}
                                        text={item.label}
                                        type={"normal"}
                                        marginBottom={"none"}
                                        fontSize={"sm"}
                                />
                            </MenuItem>
                        )
                    }

                    return <span key={'profile-menuItem-' + index}/>
                })}
            </Menu>
        );

        return (
            <div onClick={() => {
                handleSettingIconClick(false)
            }}>
                {icon}
                {menu}
            </div>
        );
    };

    const renderProfileMenu = () => {
        const {profile, setUserAuthStatus, anchorEl, handleItemClose, handleItemOpen} = props;

        let profileImage;

        if (!isEmpty(profile)) {
            profileImage = props.profile?.userName?.slice(0, 1)
        }

        let menuOptions = [
            {
                label: renderChangePasswordField(),
                onClick: () => {
                    props.history.push('/user/change-password');
                },
                isVisible: true
            },
            {
                label: renderLogoutField(),
                onClick: () => {
                    setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
                    props.logoutUser();
                    props.history.push('/');
                },
                isVisible: true
            }
        ];

        let isIconVisible = false;

        menuOptions.forEach(item => {
            if (item?.isVisible) {
                isIconVisible = true
            }
        });

        if (!isIconVisible) {
            return;
        }

        let icon = (
            <div className="d-flex align-items-center">
                <div className='cursor'>
                    <Avatar className='profile-img p-3 cursor'
                            onClick={handleItemOpen}
                            style={{backgroundColor: getRandomColors(props.profile?.userName)}}>
                        {isEmpty(profileImage) ? <></> :
                            profileImage?.toUpperCase()
                        }
                    </Avatar>
                </div>
            </div>
        );

        let menu = (
            <Menu id="items-menu"
                  anchorEl={anchorEl}
                  getcontentanchorel={null}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                  transformOrigin={{vertical: "top", horizontal: "right"}}
                  open={Boolean(anchorEl)}
                  onClose={() => handleItemClose()}
                  className="p-2 w-100"
                  disableAutoFocusItem
                  PaperProps={{
                      style: {
                          marginTop: '10px',
                      }
                  }}
                  MenuListProps={{
                      style: {
                          minWidth: 200
                      },
                  }}>
                <div>
                    {renderNameAndIcon()}
                </div>
                {menuOptions.map((item, index) => {
                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'menuItem-' + index}
                                      className={'cursor menuItem-custom-css px-3 py-0'}
                                      onClick={() => {
                                          handleItemClose();
                                          item.onClick();
                                      }}>
                                {item?.label}
                            </MenuItem>
                        )
                    }
                    return <span/>
                })}
            </Menu>
        );
        return (
            <span>
                {icon}
                {menu}
            </span>
        );
    };

    const authorizedContentUi = () => {
        const {isXLView, profile} = props;
        if (isEmpty(profile)) {
            return
        }

        let role = '';
        if (!isEmpty(profile.roles) && profile.roles?.length !== 0) {
            role = profile?.roles[0]?.id;
        }

        return (
            <div className='w-100' style={{paddingLeft: 6, paddingRight: 6}}>
                {renderMobileDrawerBtn()}
                <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>
                    <div className='d-flex align-items-center w-100'>
                        <div style={{marginRight: isXLView ? 70 : 20}}>
                            {renderAppLogo()}
                        </div>
                        <div>
                            {renderNavigatorWithOptions(role)}
                        </div>
                    </div>

                    <div className='d-flex align-items-center flex-shrink-0 '>
                        {renderSettingMenu(role)}
                        {renderProfileMenu()}
                    </div>
                </div>
            </div>
        );

    };

    const unauthorizedContentUi = () => {
        const {isXLView} = props;
        return (
            <div className='w-100'>
                <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>
                    <div className='d-flex align-items-center w-100'>
                        <div style={{marginRight: isXLView ? 70 : 20}}>
                            <div className='d-flex align-items-center'>
                                <div id={"logo"}
                                     style={{color: appTheme.appColor.primary, fontSize: 24, fontWeight: 500}}>
                                    CM App
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if (props.location.pathname === '/session-expired') {
        return (
            <div className="">
                <AppBar drawerwidth={0}
                        style={{backgroundColor: appTheme.appColor.white, borderBottom: '2px solid lightGray'}}
                        position="fixed"
                        open={true}
                        className='shadow-none d-flex'>
                    <Toolbar className='' style={{height: '50px', minHeight: '50px '}}>
                        {unauthorizedContentUi()}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

    if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
        return (
            <div className="">
                <AppBar drawerwidth={0}
                        style={{
                            backgroundColor: appTheme.appColor.white,
                            borderBottom: '2px solid lightGray',
                            paddingLeft: '0px !important'
                        }}
                        position="fixed"
                        open={true}
                        className='shadow-none d-flex'>
                    <Toolbar className='' style={{height: '50px', minHeight: '50px ', paddingLeft: '0px !important'}}>
                        {authorizedContentUi()}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
    return <></>
}

export default AppResAppBarView;