import React, {useEffect, useState} from "react";
import ForgetPasswordView from "./ForgetPasswordView";
import {connect} from "react-redux";
import {clearPostLoginUrl, showToastMessage} from "../../appComponents/AppActions";
import {withTranslation} from "react-i18next";
import {loginConst} from "../userConsts";
import {APP_CONST} from "../../appComponents/AppConstants";
import {isEmpty} from "../../utils/validations";
import {sendForgetPasswordLink} from "../userActions";
import store from "../../store";


const ForgetPassword = (props) => {
    const [loginData, setLoginData] = useState({
        formData: {
            userName: '',
            userNameError: ''
        },
        isLoginInProgress: false,
        scrollToElement: null,
        responseError:'',
        UIState: loginConst.LOGIN,
    });

    const onFormDataChange = (name, value) => {
        setLoginData(loginData => ({
            ...loginData,
            formData: {
                ...loginData.formData,
                [name]: value,
                [name + 'Error']: '',
            }
        }));
    };

    const validateChanges = (key) => {
        const {userName} = loginData.formData;

        if (key === "userName" || key === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(userName)) {
                setLoginData(loginData => ({
                    ...loginData,
                    formData: {
                        ...loginData.formData,
                        userNameError: 'Please enter user name'
                    }
                }));
                return false;
            }

            if (userName.length > 50) {
                setLoginData(loginData => ({
                    ...loginData,
                    formData: {
                        ...loginData.formData,
                        userNameError: 'Maximum 50 characters allowed'
                    }
                }));
                return false;
            }
            return true;
        }
        return true;
    };

    const submitFormWithDelay = (e) =>{
        e.preventDefault();

        setTimeout(()=>{
            submitForm();
        },500)
    };

    const submitForm = () => {
        const {userName} = loginData.formData;
        const { isLoginInProgress} = props;

        if (isLoginInProgress) {
            return;
        }

        if (!validateChanges(APP_CONST.FORM_SUBMISSION)) {
            return;
        }

        setLoginData(loginData => ({
            ...loginData,
            isLoginInProgress: true
        }));

        let payload = {
            userName: userName.trim().toLowerCase(),
        };

        sendForgetPasswordLink(payload)
            .then(res => {
                if (res.success){
                    switch (res?.statusCode) {
                        case 204 :
                        case 200 :
                        case 201 :
                            setLoginData(prevforgotPasswordState => ({
                                ...prevforgotPasswordState,
                                UIState: "RESET_LINK_SENT",
                                isLoginInProgress: false,
                                responseError:''
                            }));
                            break;
                        case 400:
                            setLoginData(prevforgotPasswordState => ({
                                ...prevforgotPasswordState,
                                UIState: "EMAIL_NOT_EXISTS",
                                isLoginInProgress: false
                            }));
                            store.dispatch(showToastMessage('warning', res.__error));
                            break
                    }
                }
                else {
                    setLoginData(loginData => ({
                        ...loginData,
                        isLoginInProgress: false
                    }));
                    store.dispatch(showToastMessage('warning', res.__error));
                }
            })
    };

    const checkKey = (event) => {
        if (event?.key === 'Enter') {
            submitFormWithDelay();
        }
    };

    return (
        <ForgetPasswordView
            {...props}
            loginData={loginData}
            onFormDataChange={onFormDataChange}
            submitForm={submitForm}
            submitFormWithDelay={submitFormWithDelay}
            checkKey={checkKey}
        />
    );
};

ForgetPassword.propTypes = {};

const mapStateToProps = (state) => ({
    isRedirect: state.appState.isRedirect,
    postLoginUrl: state.appState.postLoginUrl,
    postLoginUrlProps: state.appState.postLoginUrlProps,
    userAuthStatus: state.userState.userAuthStatus,
    windowHeight: state.appState.deviceInfo.windowHeight,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView
});

export default connect(mapStateToProps, {
    clearPostLoginUrl
})(withTranslation('translations')(ForgetPassword));
