import appTheme from "../../../assets/appTheme";

export const tableConfig = {
    id: '',
    tableName: '',
    tableMinHeight: '100dvh',
    tableMaxHeight: '100dvh',
    isDynamicHeight: false,
    tableWidth: '100%',
    headerConfig: {
        verticalAlign: 'center',
        height: '48px',
        padding: '0 16px'
    },
    isRowClickable: false,
    pagination: {
        totalPages: 2,
        rowsPerPage: 10,
        currentPage: 0,
        onPageChange: {},
        onRowPerPageChange: {},
        enableNextButton: true,
        enablePrevButton: true
    },
    defaultCellConfig: {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px',
        height: '44px',
        padding: '0 16px',
        fontSize: appTheme.fontSizes.xs,
        fontFamily: appTheme.default.fontFamily,
        fontColor: appTheme.default.color,
    },
    defaultHeaderCellConfig: {
        textAlign: 'left',
        width: 'auto',
        minWidth: '200px',
        height: '44px',
        padding: '0 16px',
        fontWeight: 500,
        fontFamily: appTheme.default.fontFamily,
        fontColor: appTheme.default.color,
    },
    cellConfig: [],
    //accept from parent component
    headerData: ['id'],
    tableData: [
        {
            id: '',
            data: ['1', 'Ottilie']
        },
        {
            id: '',
            data: ['2', 'Nomiley']
        }
    ],
    isShowPagination: true
};