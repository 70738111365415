import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './assets/css/dlComponent.css'
import './assets/css/headerFooter.css'
import './assets/css/table.css'
import './assets/css/app.css';
import './assets/css/designToken.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
        <App />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
