import React, {useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ChangePasswordView from "./ChangePasswordView";
import {isEmpty} from "../../utils/validations";
import {isValidPassword} from "../../utils/helper";
import {showToastMessage} from "../../appComponents/AppActions";
import {logoutUser, onChangePassword} from "../userActions";

import {passwordData} from "./ChangePasswordModal";
import {setUserAuthStatus} from "../userReducer";
import {userConst} from "../userConsts";
import {APP_CONST} from "../../appComponents/AppConstants";
import store from "../../store";


const ChangePassword = (props) => {

    let signUpErrorConst = Object.keys(passwordData).reduce((acc, item) => {
        return {
            ...acc,
            [item + 'Error']: '',
        };
    }, {});

    const [ChangePasswordData, setChangePasswordData] = useState({
        formData: {
            ...passwordData,
            ...signUpErrorConst
        },
        autoFocusOn: '',
        scrollToElement: null,
        responseError: ''
    });


    const onBlurInputText = (name) => {
        validateChanges(name);
    };

    const validateChanges = (name) => {
        const {t} = props;
        const {password, confirmPassword, currentPassword} = ChangePasswordData.formData;
        if (name === "currentPassword" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(currentPassword)) {
                setChangePasswordData(prevChangePasswordData => ({
                    ...prevChangePasswordData,
                    formData: {
                        ...prevChangePasswordData.formData,
                        currentPasswordError: t('Enter current password')
                    }
                }));
                scrollToErrorElement('password');
                return false;
            }

        }
        if ((name === "password" || name === APP_CONST.FORM_SUBMISSION) || (name === 'confirmPassword' || name === APP_CONST.FORM_SUBMISSION)) {

            if (isEmpty(password)) {
                setChangePasswordData(prevChangePasswordData => ({
                    ...prevChangePasswordData,
                    formData: {
                        ...prevChangePasswordData.formData,
                        passwordError: isEmpty(confirmPassword) ? t('Enter a password') : t('Please enter your password first')
                    }
                }));
                scrollToErrorElement('password');
                return false;
            }


            if (password.length < 8) {
                setChangePasswordData(prevChangePasswordData => ({
                    ...prevChangePasswordData,
                    formData: {
                        ...prevChangePasswordData.formData,
                        passwordError: t('Password should not be less than 8 characters')
                    }
                }));
                scrollToErrorElement('password');

                return false;
            }

            if (!isValidPassword(password)) {
                setChangePasswordData(prevChangePasswordData => ({
                    ...prevChangePasswordData,
                    formData: {
                        ...prevChangePasswordData.formData,
                        passwordError: t('At least one character and one digit and one special character'),
                        confirmPasswordError: ''
                    }
                }));

                scrollToErrorElement('password');

                return false;
            }

            if (password.startsWith(" ") || password.endsWith(" ")) {
                setChangePasswordData(prevChangePasswordData => ({
                    ...prevChangePasswordData,
                    formData: {
                        ...prevChangePasswordData.formData,
                        passwordError: "Your password cannot start or end with a blank space",
                        confirmPasswordError: ''
                    }
                }));
                scrollToErrorElement('password');
                return false;
            }


            if (!isEmpty(confirmPassword)) {
                if (confirmPassword !== password) {
                    setChangePasswordData(prevChangePasswordData => ({
                        ...prevChangePasswordData,
                        formData: {
                            ...prevChangePasswordData.formData,
                            confirmPasswordError: t('Confirm password is not matching')
                        }
                    }));
                    scrollToErrorElement('confirmPassword');

                    return false;
                }
                if (confirmPassword === password) {
                    setChangePasswordData(prevChangePasswordData => ({
                        ...prevChangePasswordData,
                        formData: {
                            ...prevChangePasswordData.formData,
                            confirmPasswordError: ""
                        }
                    }));
                    scrollToErrorElement('confirmPassword');
                    return true;
                }

            }
            if (confirmPassword !== password) {
                setChangePasswordData(prevChangePasswordData => ({
                    ...prevChangePasswordData,
                    formData: {
                        ...prevChangePasswordData.formData,
                        confirmPasswordError: t('Confirm password is not matching')
                    }
                }));
                scrollToErrorElement('confirmPassword');
                return false;

            }
            if (isEmpty(confirmPassword)) {
                setChangePasswordData(prevChangePasswordData => ({
                    ...prevChangePasswordData,
                    formData: {
                        ...prevChangePasswordData.formData,
                        confirmPasswordError: t('Enter a confirmation password')
                    }
                }));
                scrollToErrorElement('confirmPassword');
                return false;
            }
        }
        return true;
    };

    const scrollToErrorElement = (name) => {

        setChangePasswordData(prevChangePasswordData => ({
            ...prevChangePasswordData,
            scrollToElement: name,
        }));
    };

    const onChangeFormData = (name, value) => {

        setChangePasswordData(prevChangePasswordData => ({
            ...prevChangePasswordData,
            currentWorkingItem: name,
            formData: {
                ...prevChangePasswordData.formData,
                [name]: value,
                [name + "Error"]: ''
            }
        }));

    };

    const onSubmit = () => {
        const {setUserAuthStatus} = props;
        if (!validateChanges(APP_CONST.FORM_SUBMISSION)) {
            return;
        }
        const {currentPassword, password} = ChangePasswordData.formData;

        let userData = {
            existingPassword: currentPassword,
            newPassword: password
        };

        onChangePassword(userData).then((res) => {
            if (res.success) {
                store.dispatch(showToastMessage('success', "New password is set"));
                setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
                props.logoutUser();
            } else {
                setChangePasswordData(prev => ({
                    ...prev,
                    responseError: res.__error
                }));
            }
        })
    };

    const clearFormData = () => {
        let signUpErrorConst = Object.keys(passwordData).reduce((acc, item) => {
            return {
                ...acc,
                [item + 'Error']: '',
            };
        }, {});
        setChangePasswordData(prevChangePasswordData => ({
            ...prevChangePasswordData,
            formData: {
                ...passwordData,
                ...signUpErrorConst
            }
        }));

    };

    const setAutoFocusOn = () => {
        const {currentPassword, confirmPassword, password, confirmPasswordError, currentPasswordError, passwordError} = ChangePasswordData.formData;
        const {currentWorkingItem} = ChangePasswordData;

        let autoFocusVariable = '';
        setTimeout(() => {
            switch (currentWorkingItem) {
                case 'currentPassword':
                    if (isEmpty(password) || passwordError) {
                        autoFocusVariable = document.getElementById('changePassword-password-input')
                    } else if (isEmpty(confirmPassword) || confirmPasswordError) {
                        autoFocusVariable = document.getElementById('changePassword-confirmPassword-input')
                    }
                    break;
                case 'password':
                    if (isEmpty(password) || passwordError) {
                        autoFocusVariable = document.getElementById('changePassword-currentPassword-input')
                    } else if (isEmpty(confirmPassword) || confirmPasswordError) {
                        autoFocusVariable = document.getElementById('changePassword-confirmPassword-input')
                    }
                    break;
                case 'confirmPassword':
                    if (isEmpty(password) || passwordError) {
                        autoFocusVariable = document.getElementById('changePassword-currentPassword-input')
                    } else if (isEmpty(confirmPassword) || confirmPasswordError) {
                        autoFocusVariable = document.getElementById('changePassword-password-input')
                    }
                    break;
                default:
                    if (!isEmpty(currentPassword) && (isEmpty(currentPasswordError) || !currentPasswordError)) {
                        autoFocusVariable = document.getElementById('changePassword-currentPassword-input')
                    }
                    if (!isEmpty(password) && (isEmpty(passwordError) || !passwordError)) {
                        autoFocusVariable = document.getElementById('changePassword-password-input')
                    }
                    if (!isEmpty(confirmPassword) && (isEmpty(confirmPasswordError) || !confirmPasswordError)) {

                        autoFocusVariable = document.getElementById('changePassword-confirmPassword-input')
                    }
            }

            if (!isEmpty(autoFocusVariable)) {
                autoFocusVariable.focus()
            }

        }, 100);
    };

    return (
        <ChangePasswordView
            ChangePasswordData={ChangePasswordData}
            {...props}
            onChangeFormData={(name, value) => onChangeFormData(name, value)}
            onSubmit={() => onSubmit()}
            clearFormData={() => clearFormData()}
            onBlurInputText={onBlurInputText}
            setAutoFocusOn={setAutoFocusOn}
        />
    );
};


const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {
    logoutUser, setUserAuthStatus
})(withTranslation('translations')(ChangePassword));