import {createSlice} from "@reduxjs/toolkit";

const appSlice = createSlice({
    name: 'ProductManagementReducer',
    initialState: {
        productManagementFilter: {
            keyword: '',
            skipCount: 0,
            limitCount: 25,
            page: 0,
            rowsPerPage: 50,
        },
    },
    reducers: {
        setSearchKeywordData(state, action) {
            state.productManagementFilter.keyword = action.payload;
        },
        setSkipCountValue(state, action) {
            state.productManagementFilter.skipCount = action.payload;
        },
        setLimitCountValue(state, action) {
            state.productManagementFilter.limitCount = action.payload;
        },
        setPageValue(state, action) {
            state.productManagementFilter.page = action.payload;
        },
        setRowsPerPageValue(state, action) {
            state.productManagementFilter.rowsPerPage = action.payload;
        },

        resetAllFilters(state) {
            state.productManagementFilter = {
                keyword: '',
                skipCount: 0,
                limitCount: 25,
                page: 0,
                rowsPerPage: 50,
            };
        },
    }
});

export const {
    setSearchKeywordData, setSkipCountValue, setLimitCountValue, setPageValue, setRowsPerPageValue, resetAllFilters
} = appSlice.actions;
export default appSlice.reducer
