import React, {createRef, useEffect} from "react";
import appTheme from "../../assets/appTheme";
import {isEmpty} from "../../utils/validations";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import DLInputTextField from "../../core/components/DLInputTextField/DLInputTextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import DLButton from "../../core/components/DLButton/DLButton";
import DLText from "../../core/components/DLText/DLText";

let scrollTo = {
    userName: createRef(),
    password: createRef(),
};

const LoginView = (props) => {
    const {loginData, submitFormWithDelay, isSMView, checkKey, isXSView} = props;

    useEffect(() => {
        if (scrollTo[props.scrollToElement]?.current) {
            let topOffset = 80;
            let element = scrollTo[props.scrollToElement].current;
            if (element) {
                if (element.getBoundingClientRect().top < topOffset + 3) {
                    // dont scroll already on top
                } else {
                    window.scrollTo({
                        top: element.getBoundingClientRect().top + window.scrollY - topOffset,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }, [props.scrollToElement]);

    const renderLoginUI = () => {
        const {onFormDataChange, loginData} = props;
        const {formData,wrongPasswordError} = loginData;

        return (
            <div>
                <div className='mb-4'>
                    <DLText id={'signin-title'}
                            text={'Sign In'}
                            fontSize={"xxl"}
                            fontWeight={"semi-bold"}
                    />
                    { !(isEmpty(wrongPasswordError)) &&
                    <div>
                        <span style={{color: appTheme.appColor.danger, fontSize: 16,fontWeight:500}}>{wrongPasswordError}</span>
                    </div>
                    }

                </div>
                <form onSubmit={(e) => submitFormWithDelay(e)}
                      onKeyPress={() => checkKey()}>
                    <div className=''>
                        <div ref={scrollTo['userName']} >
                            <div className='d-flex flex-column pb-3 pt-2'>
                                <DLInputTextField
                                    id={'user-login-name'}
                                    label={'User Name'}
                                    value={formData?.userName}
                                    isRequired={true}
                                    onChangeValue={(value) => onFormDataChange('userName', value)}
                                    isClearable={true}
                                    error={formData?.userNameError !== ''}
                                    helperMessage={formData?.userNameError}
                                    maxCharLength={51}
                                    disableHelperMessageHeight={true}
                                    autoFocus={true}
                                    marginBottom={"none"}
                                    hideCharLength={true}
                                    debounceTimer={100}
                                />
                            </div>
                        </div>
                        <div ref={scrollTo['password']}>
                            <div className='d-flex flex-column pb-4'>
                                <div className="password-container">
                                    <DLInputTextField
                                        id={'user-login-password'}
                                        label={'Password'}
                                        value={formData?.password}
                                        isRequired={true}
                                        marginBottom={'none'}
                                        disableHelperMessageHeight={true}
                                        onChangeValue={(value) => onFormDataChange('password', value)}
                                        isClearable={false}
                                        error={formData?.passwordError !== ''}
                                        helperMessage={formData?.passwordError}
                                        inputType={formData?.showPassword ? 'text' : 'password'}
                                        onPaste={(e) => {
                                            e.preventDefault();
                                            return false;
                                        }}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className="p-0 m-0"
                                                        id={'user-login-showPassword'}
                                                        onClick={() => onFormDataChange('showPassword', !formData?.showPassword)}
                                                        aria-label="">
                                                        {formData?.showPassword
                                                            ? (<i className="fa-regular fa-eye default-icon"
                                                                  style={{
                                                                      fontSize: 18,
                                                                  }}/>)
                                                            : (<i className="fa-regular fa-eye-slash default-icon"
                                                                  style={{
                                                                      fontSize: 18,
                                                                  }}/>)
                                                        }
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        debounceTimer={100}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='d-grid mx-auto text-center w-100 pb-2 pt-2'>
                            <DLButton
                                id={'sign-in-btn'}
                                history={props.history}
                                isApiInProgress={loginData.isLoginInProgress}
                                isLoaderButton={true}
                                isDisabled={loginData.isLoginInProgress}
                                label={'Sign In'}
                                buttonSize={"sm"}
                                type={'primary'}
                                fontSize={"md"}
                                sideMargin={'none'}
                                onClick={() => {}}
                            />
                        </div>
                        <div className='d-flex justify-content-end mt-1 ' style={{color: appTheme.linkStyle.color}}>
                         <div className='cursor' onClick={()=>props.history.push('/user/forgot-password')}>
                             <u>
                                 <DLText id={'password-title'}
                                         text={'Forgot Password?'}
                                         customTextStyle={{color : appTheme.linkStyle.color}}
                                         fontSize={"xs"}
                                 />
                             </u>
                         </div></div>
                    </div>
                </form>
            </div>

        )
    };

    const renderUI = () => {
        return renderLoginUI();
    };

    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-center'
             style={{backgroundColor: "#F0F0F0", height: '100vh'}}>
            <div className="text-center mb-2">
                <DLText id={''}
                        text={'CM App'}
                        fontSize={"xxxl"}
                        fontWeight={"semi-bold"}
                />
            </div>

            <div className={(isXSView || isSMView) ? "panel bg-white" : "panel bg-white"}
                 style={{
                     width: (isXSView || isSMView) ? '90%' : "450px",
                 }}>
                <div className="d-flex align-items-center">
                    <div className='w-100 d-flex flex-column justify-content-center' style={{minHeight: 'calc(100dvh - 280px)'}}>
                        {renderUI()}
                    </div>
                </div>
            </div>
        </div>
    );
};

LoginView.propTypes = {};

export default LoginView;