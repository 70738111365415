import {httpGet, httpPost, httpPut} from "../utils/httpHelper";
import {
    setLimitCountValue,
    setPageValue, setRowsPerPageValue,
    setSkipCountValue,
    setSearchKeywordData,
    resetAllFilters
} from "./productManagementReducer";
import store from "../store";

export const getAllProductData = (payload) => {
    let url = '/service/provisioning/product/all';

    return httpPost(url, payload).then(res => {
        return res;
    });
};
export const setAssignAdmin = (payload) => {
    let url = '/service/provisioning/product/admin';

    return httpPost(url, payload, true).then(res => {
        return res;
    });
};

export const setAssignOwner = (payload) => {
    let url = '/service/provisioning/product/owner';

    return httpPost(url, payload, true).then(res => {
        return res;
    });
};

export const updateAssignOwner = (payload) => {
    let url = '/service/provisioning/product/owner';

    return httpPut(url, payload, true).then(res => {
        return res;
    });
};

export const updateProductComplexity = (payload) => {
    let url = '/service/provisioning/product';
    return httpPut(url, payload, true).then(res => {
        return res;
    });
};

export const getAllProductCategoriesError = (payload) => {
    let url = '/service/provisioning/product/error/all';

    return httpPost(url,payload).then(res => {
        return res;
    });
};

export const addError = (payload, handleErrorInComponent) => {
    let url = '/service/provisioning/product/error';

    return httpPost(url, payload, handleErrorInComponent).then(res => {
        return res;
    });
};


export const updateError = (payload, handleErrorInComponent) => {
    let url = '/service/provisioning/product/error';

    return httpPut(url, payload, handleErrorInComponent).then(res => {
        return res;
    });
};

export const getProductCategoryErrorData = (id) => {
    let url = '/service/provisioning/product/error?id='+id;

    return httpGet(url).then(res => {
        return res;
    });
};

export const getProductDetailsData = (id) => {
    let url = '/service/provisioning/product?id=' + id;

    return httpGet(url).then(res => {
        return res;
    });
};

export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordData(data))
};

export const setSkipCount = (data) => {
    store.dispatch(setSkipCountValue(data))
};

export const setRowsPerPage = (data) => {
    store.dispatch(setRowsPerPageValue(data))
};

export const setLimitCount = (data) => {
    store.dispatch(setLimitCountValue(data))
};

export const setPageCount = (data) => {
    store.dispatch(setPageValue(data))
};

export const resetAllFilter = (data) => {
    store.dispatch(resetAllFilters(data))
};