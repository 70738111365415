import {httpDelete, httpGet, httpPost} from "../utils/httpHelper";
import {
    resetAllHomePageFilters,
    setLimitCountValue,
    setPageValue, setRowsPerPageValue,
    setSkipCountValue,
    setUserListingSearchKeywordData, setUserListingSearchRoleData,
    setUserSearchKeywordData
} from "./userManagementReducer";
import store from "../store";

export const getAllUserData = (payload) => {
    let url = '/service/provisioning/user/all';

    return httpPost(url, payload).then(res => {
        return res;
    });
};

export const addUser = (payload, handleErrorInComponent) => {
    let url = '/service/provisioning/user';

    return httpPost(url, payload, handleErrorInComponent).then(res => {
        return res;
    });
};

export const getUserSummary = () => {
    let url = '/service/provisioning/user/summary';

    return httpGet(url).then(res => {
        return res;
    });
};

export const deleteUserData = (id, handleErrorInComponent) => {
    let url = '/service/provisioning/user?userId='+id;

    return httpDelete(url, handleErrorInComponent).then(res => {
        return res;
    });
};

export const deleteRoleData = (payload, handleErrorInComponent) => {
    let url = `/service/provisioning/userRole?userRoleProductAssignmentId=${payload.userRoleProductAssignmentId}&&targetUserId=${payload.targetUserId}`;

    return httpDelete(url, handleErrorInComponent).then(res => {
        return res;
    });
};

export const assignUserData = (payload, handleErrorInComponent) => {
    let url = '/service/provisioning/userRole';

    return httpPost(url, payload, handleErrorInComponent).then(res => {
        return res;
    });
};

export const getAllRoles = () => {
    let url = '/service/provisioning/role/all';

    return httpGet(url).then(res => {
        return res;
    });
};

export const setUserSearchKeyword = (data) => {
    store.dispatch(setUserSearchKeywordData(data))
};

export const setUserListingSearchKeyword = (data) => {
    store.dispatch(setUserListingSearchKeywordData(data))
};

export const setUserListingSearchRole = (data) => {
    store.dispatch(setUserListingSearchRoleData(data))
};

export const setSkipCount = (data) => {
    store.dispatch(setSkipCountValue(data))
};

export const setRowsPerPage = (data) => {
    store.dispatch(setRowsPerPageValue(data))
};

export const setLimitCount = (data) => {
    store.dispatch(setLimitCountValue(data))
};

export const setPageCount = (data) => {
    store.dispatch(setPageValue(data))
};

export const resetAllHomePageFilterData = () => {
    store.dispatch(resetAllHomePageFilters())
};