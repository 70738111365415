import React, {useState} from 'react';
import {connect} from "react-redux";
import store from "../../../../store";

import {showToastMessage} from "../../../../appComponents/AppActions";
import {isEmpty} from "../../../../utils/validations";

import ReAssignAdminModelView from "./ReAssignAdminModelView";
import {getAllUserData} from "../../../../UserManagement/UserManagementAction";
import {APP_CONST} from "../../../../appComponents/AppConstants";
import {setAssignAdmin} from "../../../productManagementAction";

const ReAssignAdminModel = (props) => {
    const {handleCloseModal, getAllData} = props;

    const [reAssignAdminState, setReAssignAdminState] = useState({
        isModalOpen: false,
        selectedAdmin: '',
        searchKeyword: '',
        userSearchData: [],
        isAdminSeleted: false,
        isShowInlineLoader: false,
        errorObj: {
            responseError: "",
        }
    });

    const formatUserData = (userData) => {
        if (isEmpty(userData)) {
            return null
        }

        return userData.map(item => {
            return {...item, label: item.userName, value: item.userId}
        });
    };

    const searchUser = (keyword) => {

        setReAssignAdminState(prevState => ({
            ...prevState,
            isShowInlineLoader: true,
        }));

        let payload = {
            filters: {
                // 'roleId': 'PRODUCT_ADMIN',
                "keyword": keyword
            }
        };

        getAllUserData(payload)
            .then(res => {
                if (res?.success) {
                    setReAssignAdminState(prevState => ({
                        ...prevState,
                        isShowInlineLoader: false,
                        userSearchData: formatUserData(res.data?.users),
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setReAssignAdminState(prevState => ({
                        ...prevState,
                        isShowInlineLoader: false,
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    }));
                }
            })
    };

    const onReAssignAdmin = () => {
        if (!isEmpty(reAssignAdminState.selectedAdmin)) {
            setReAssignAdminState(prevState => ({
                ...prevState,
                isShowLoader: true,
            }));
            let payload = {
                "roleId": "PRODUCT_ADMIN",
                "productId": props.productDetails.id,
                "targetUserId": reAssignAdminState.selectedAdmin.userId
            };

            setAssignAdmin(payload)
                .then(res => {
                    if (res?.success) {
                        setReAssignAdminState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            selectedAdmin: '',
                            searchKeyword: '',
                        }));
                        handleCloseModal('isReAssignAdminModalOpen');
                        getAllData(props.productDetails.id);
                        store.dispatch(showToastMessage('success', 'Admin is assigned'))
                    } else {
                        setReAssignAdminState(prevState => ({
                            ...prevState,
                            isShowLoader: false,
                            errorObj: {
                                ...prevState.errorObj,
                                responseError: res?.__error
                            }
                        }));
                    }
                })
        } else {
            setReAssignAdminState(prevState => ({
                ...prevState,
                isShowLoader: false,
                errorObj: {
                    ...prevState.errorObj,
                    responseError: 'Please select the admin'
                }
            }));
        }
    };

    const onChangeFilter = (value) => {
        setReAssignAdminState((prev) => ({
            ...prev,
            searchKeyword: value,
        }));
    };

    const getChangeAdmin = () => {
        setReAssignAdminState((prev) => ({
            ...prev,
            isAdminSeleted: false,
            selectedAdmin: '',
        }));
    };

    const addSearchId = (id) => {
        const {userSearchData} = reAssignAdminState;
        let selectedData = userSearchData.find((item) => item.value === id);

        setReAssignAdminState(prevState => ({
            ...prevState,
            userSearchData: [],
            selectedAdmin: selectedData,
            isAdminSeleted: true,
            errorObj: {
                ...prevState.errorObj,
                responseError: "",
            }
        }));
    };

    return (
        <ReAssignAdminModelView
            {...props}
            {...reAssignAdminState}
            searchUser={searchUser}
            addSearchId={addSearchId}
            getChangeAdmin={getChangeAdmin}
            onReAssignAdmin={onReAssignAdmin}
            onChangeFilter={onChangeFilter}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(ReAssignAdminModel);
