import React from "react"

import ReAssignOwnerModel from "./components/reAssignOwnerModel/ReAssignOwnerModel";
import ReAssignAdminModel from "./components/reAssignAdminModel/ReAssignAdminModel";
import ComplexityModel from "./components/complexityModel/ComplexityModel";
import QASamplingModel from "./components/QASamplingModel/QASamplingModel";

import DLText from "../../core/components/DLText/DLText";
import DLLoader from "../../core/components/DLLoader/DLLoader";
import {isEmpty} from "../../utils/validations";
import {convertUTCToLocalTime} from "../../utils/helper";
import ErrorCategories from "./components/errorCateogories/ErrorCategories";

const ProductDetailsView = (props) => {
    const {
        productDetails, lowerQASampling, upperQASampling, isShowLoader
    } = props;

    const renderHeader = () => {
        return (
            <div className='d-flex align-items-center' style={{height: '60px'}}>
                <DLText
                    id={'Users'}
                    fontSize={'xl'}
                    fontColor={'primary'}
                    fontWeight={'semi-bold'}
                    marginBottom={'none'}
                    text={`Product - ${productDetails?.name}`}/>
            </div>
        )
    };

    const renderProductName = () => {
        return (
            <div>
                <DLText id={'product-title'}
                        marginBottom={"sm"}
                        text={'Product Name'}
                        fontColor={'grayDark'}
                />
                <DLText id={'product-name'}
                        marginBottom={"none"}
                        text={`${productDetails?.name}`}
                />
            </div>
        )
    };

    const renderOwnerName = () => {
        const {openModel} = props;
        let productOwner = productDetails?.productAssignments?.find(user => user.roleId === 'PRODUCT_OWNER');

        return (
            <div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <DLText id={'owner-title'}
                            text={'Owner'}
                            marginBottom={"none"}
                            fontColor={'grayDark'}
                    />
                    <i className="fa-solid fa-pencil cursor"
                       onClick={() => {
                           openModel('isReAssignOwnerModalOpen')
                       }}/>
                </div>
                <DLText id={'owner-name'}
                        marginBottom={"none"}
                        text={`${productOwner?.userName}`}
                />
            </div>
        )
    };

    const getProductAdmin = (productAdmin) => {
        if (isEmpty(productAdmin)) {
            return '-'
        }
        return (
            <div className=''>
                <ul className='unorderedList-class mb-0 pb-0 ms-0' style={{paddingLeft: 15}}>
                    {productAdmin.map((user, index) =>
                        <li className='product-details-subtitle' key={index}>
                            {user.userName}
                        </li>
                    )}
                </ul>
            </div>
        )
    };

    const renderAdminNames = () => {
        const {openModel} = props;
        let productAdmin = productDetails?.productAssignments?.filter(user => user.roleId === 'PRODUCT_ADMIN');

        return (
            <div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <DLText id={'admin-title'}
                            text={'Admin'}
                            marginBottom={"none"}
                            fontColor={'grayDark'}
                    />
                    <i className="fa-solid fa-pencil cursor"
                       onClick={() => {
                           openModel('isReAssignAdminModalOpen')
                       }}/>
                </div>
                {getProductAdmin(productAdmin)}
            </div>
        )
    };

    const renderComplexity = () => {
        const {openModel} = props;

        return (
            <div className='d-block m-auto'>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <DLText id={'complexity-title'}
                            marginBottom={"none"}
                            text={'Complexity'}
                            fontColor={'grayDark'}
                    />
                    <i className="fa-solid fa-pencil cursor"
                       onClick={() => {
                           openModel('isComplexityModalOpen')
                       }}/>
                </div>
                <DLText id={'complexity-value'}
                        marginBottom={"none"}
                        text={`${productDetails?.complexity}`}
                />
            </div>
        )
    };

    const renderQASampling = () => {
        const {openModel} = props;

        return (
            <div>
                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <DLText id={'qa-sampling-title'}
                            text={'QA Sampling %'}
                            marginBottom={'none'}
                    />
                    {/*<i className="fa-solid fa-pencil cursor"*/}
                    {/*   onClick={() => {*/}
                    {/*       openModel('isQASamplingModalOpen')*/}
                    {/*   }}/>*/}
                </div>
                <div className='d-flex justify-content-between'>
                    <div>
                        <DLText id={'lower-threshold-title'}
                                text={`Lower Threshold`}
                                fontColor={'grayDark'}
                        />
                        <DLText id={'lower-threshold-value'}
                                text={`${lowerQASampling}`}
                        />
                    </div>

                    <div>
                        <DLText id={'upper-threshold-title'}
                                text={`Upper Threshold`}
                                fontColor={'grayDark'}
                        />
                        <DLText id={'upper-threshold-value'}
                                text={`${upperQASampling}`}
                        />
                    </div>
                </div>
            </div>
        )
    };

    const renderUpdatedDate = () => {
        return (
            <DLText id={'updated-date'}
                    text={`Last Updated: ${convertUTCToLocalTime(productDetails?.updatedAt, "DD-MM-YYYY")}`}
                    fontColor={'grayDark'}
                    marginBottom={'none'}
                    fontSize={'xs'}
            />
        )
    };

    const renderReAssignOwnerModal = () => {
        const {isReAssignOwnerModalOpen} = props;

        if (isReAssignOwnerModalOpen) {
            return (
                <ReAssignOwnerModel
                    {...props}
                />
            )
        }
    };

    const renderReAssignAdminModal = () => {
        const {isReAssignAdminModalOpen} = props;

        if (isReAssignAdminModalOpen) {
            return (
                <ReAssignAdminModel
                    {...props}
                />
            )
        }
    };

    const renderComplexityModal = () => {
        const {isComplexityModalOpen} = props;

        if (isComplexityModalOpen) {
            return (
                <ComplexityModel
                    {...props}
                />
            )
        }
    };

    const renderQASamplingModal = () => {
        const {isQASamplingModalOpen} = props;

        if (isQASamplingModalOpen) {
            return (
                <QASamplingModel
                    {...props}
                />
            )
        }
    };

    const renderLoader = () => {
        return (
            <DLLoader isVisible={isShowLoader} type={'screen'}/>
        )
    };

    return (
        <div className='page-container'
             style={{minHeight: 'calc(100dvh - 180px)'}}>
            {renderHeader()}
            <div className='row'>
                <div className='col-4 gap-3'>
                    <div className=''>
                        <div className='panel p-3 overflow-y-auto' style={{
                            maxHeight: 'calc(100dvh - 140px)',
                            minHeight: 'calc(100dvh - 140px)',
                        }}>
                            <div className='d-flex flex-column gap-3'>
                                <div className='bg-subpanel' style={{flex: 2, flexGrow: 1, minHeight: '80px'}}>
                                    {renderProductName()}
                                </div>
                                <div className='bg-subpanel' style={{flex: 2, flexGrow: 1, minHeight: '80px'}}>
                                    {renderOwnerName()}
                                </div>
                                <div className='bg-subpanel' style={{flex: 2, flexGrow: 1, minHeight: '80px'}}>
                                    {renderAdminNames()}
                                </div>

                                <div className='bg-subpanel' style={{flex: 2, flexGrow: 1, minHeight: '80px'}}>
                                    {renderComplexity()}
                                </div>
                                <div className='bg-subpanel' style={{flex: 2, flexGrow: 1, minHeight: '80px'}}>
                                    {renderQASampling()}
                                </div>
                                <div className='dl-panel-footer-content pt-1'>
                                    {renderUpdatedDate()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-8'>
                    <ErrorCategories {...props}/>
                </div>
                {renderReAssignOwnerModal()}
                {renderReAssignAdminModal()}
                {renderComplexityModal()}
                {renderQASamplingModal()}
                {renderLoader()}
            </div>
        </div>
    )
};

export default ProductDetailsView;
