import React, {useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import store from "./store";
import {QueryClient, QueryClientProvider} from "react-query";
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {getAppEnvironmentVariables} from "./utils/httpHelper";
import appTheme from "./assets/appTheme";
import {debounce} from "./utils/helper";
import {AppResize} from "./appComponents/AppReducer";
import Navigator from "../src/appComponents/AppNavigator";
import DLLoader from "./core/components/DLLoader/DLLoader";
import DLThemeProvider from "./core/components/DLThemeProvider/DLThemeProvider";

const muiTheme = createTheme({
    typography: {
        fontSize: appTheme.default.fontSize
    },
    palette: {
        primary: {
            main: appTheme.appColor.primary,
        },
        secondary: {
            main: appTheme.appColor.secondary,
        },
        success: {
            main: appTheme.appColor.success,
        },
        danger: {
            main: appTheme.appColor.danger,
        },
        error: {
            main: appTheme.appColor.danger,
        },
        gray: {
            main: appTheme.appColor.gray,
        },
        black: {
            main: appTheme.appColor.black,
        },
        white: {
            main: appTheme.appColor.white,
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '12px',
                color: '#fff',
                backgroundColor: '#000',
            },
        },
        MuiAccordionSummary: {
            root: {
                // backgroundColor: '#f1eded',
                '&$expanded': {
                    boxShadow:
                        '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
                    // backgroundColor: '#f1eded',
                    fontFamily: "'Poppins', sans-serif",
                    color: '#000',
                    // padding:'0 10px !important',
                    minHeight: 'unset',
                    '& .MuiAccordionSummary-expandIcon': {
                        color: '#000',
                    },
                },
            },
            content: {
                '&$expanded': {
                    margin: '0px 0px',
                },
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: '0 !important',
            }
        },
        MuiAccordion: {
            root: {
                '&:before': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiToolbarRegular: {
            minHeight: '44px'
        },
        MuiSelect: {
            select: {
                minWidth: '120px !important',
                padding: '7px 10px !important',
                textAlign: 'left !important',
                fontSize: '14px !important',
            },

        }
    }
});

const App = () => {
    const [isThemeLoaded, setIsThemeLoaded] = useState(false);

    const setMobileView = debounce(() => {
        store.dispatch(AppResize({
            isXLView: window.innerWidth > 1200,
            isLGView: window.innerWidth >= 992 && window?.innerWidth <= 1200,
            isMDView: window.innerWidth >= 768 && window?.innerWidth <= 992,
            isSMView: window.innerWidth >= 576 && window?.innerWidth <= 768,
            isMobileView: window.innerWidth <= 992,
            isXSView: window.innerWidth < 576,
            windowHeight: window.innerHeight
        }));
    }, 300);

    useEffect(() => {
        zoomOutMobile();
        setMobileView();//for the first time
        window.addEventListener('resize', setMobileView);

        const {REACT_APP_ENVIRONMENT} = getAppEnvironmentVariables();

        setTimeout(() => {
            setIsThemeLoaded(true)
        }, 100);

        return () => {
            window.removeEventListener('resize', setMobileView);
        }
    }, []);

    // TODO show actual loader component
    const renderMainContent = () => {
        if (!isThemeLoaded) {
            return <DLLoader type={'screen'} isVisible={true}/>;
        }

        return <Navigator/>
    };

    const zoomOutMobile = () => {
        var viewport = document.querySelector('meta[name="viewport"]');

        if (!viewport) {
            return;
        }

        if (window.innerWidth >= 412) {
            return;
        }

        let initScale = window.innerWidth / 412;
        initScale = Math.round(initScale * 100) / 100;

        viewport.content = "initial-scale=" + initScale;
    };

    return (
        <div style={{...appTheme.default}}>
            <ThemeProvider theme={muiTheme}>
                <DLThemeProvider theme={appTheme}>
                    <QueryClientProvider client={new QueryClient()}>
                        <Provider store={store}>
                            <BrowserRouter>
                                    {renderMainContent()}
                            </BrowserRouter>
                        </Provider>
                    </QueryClientProvider>
                </DLThemeProvider>
            </ThemeProvider>
        </div>
    );
};

export default App;
