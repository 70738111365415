import React, {useEffect, useState} from 'react';
import {resetPassword, validateResetLink} from "../userActions";
import {resetPasswordConst} from "../userConsts";
import {APP_CONST} from "../../appComponents/AppConstants";
import {isEmpty} from "../../utils/validations";
import {isValidPassword} from "../../utils/helper";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import ResetPasswordView from "./ResetPasswordView";

function ResetPassword(props) {
    const [resetPasswordState, setResetPassword] = useState({
        password: '',
        confirmPassword: '',
        passwordError: '',
        confirmPasswordError: '',
        showPassword: false,
        isApiInProgress: false,
        showConfirmPassword: false,
        UIState: ''
    });

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        let code = query.get('code');
        setResetPassword(prev => ({
            ...prev,
            code: code
        }));

        validateResetLink(code, false)
            .then(res => {
                if (res.success) {
                    setResetPassword(prevresetPasswordState => ({
                        ...prevresetPasswordState,
                        UIState: resetPasswordConst.NEW_PASSWORD,
                        userId: res.data?.id,
                    }));
                }

            })
    }, []);

    const handleResetPassword = () => {
        const {password, userId, code} = resetPasswordState;

        if (!validateChanges(APP_CONST.FORM_SUBMISSION)) {
            return;
        }
        setResetPassword(prevresetPasswordState => ({
            ...prevresetPasswordState,
            isApiInProgress: true
        }));

        let payload = {
            password: password,
            userId: userId,
            code: code
        };

        resetPassword(payload)
            .then(res => {
                if (res.success) {
                    setResetPassword(prevresetPasswordState => ({
                        ...prevresetPasswordState,
                        UIState: resetPasswordConst.RESET_PASSWORD_SUCCESS
                    }));
                }
                setResetPassword(prevresetPasswordState => ({
                    ...prevresetPasswordState,
                    isApiInProgress: false
                }));
            })
    };

    const onChangeFormData = (name, value) => {
        setResetPassword(prevresetPasswordState => ({
            ...prevresetPasswordState,
            [name]: value,
            [name + "Error"]: '',
        }));
    };

    const clearFormData = () => {
        setResetPassword(prevresetPasswordState => ({
            ...prevresetPasswordState,
            password: '',
            confirmPassword: '',
            passwordError: '',
            confirmPasswordError: '',
            showPassword: false,
            showConfirmPassword: false,
        }));
    };


    const validateChanges = (name) => {
        const {password, confirmPassword} = resetPasswordState;

        if (name === "password" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(password)) {
                setResetPassword(prevresetPasswordState => ({
                    ...prevresetPasswordState,
                    passwordError: "Please enter your password"
                }));
                return false;
            }

            if (password.length < 8) {
                setResetPassword(prevresetPasswordState => ({
                    ...prevresetPasswordState,
                    passwordError: "Password should not be less than 8 characters"
                }));
                return false;
            }

            if (!isValidPassword(password)) {
                setResetPassword(prevresetPasswordState => ({
                    ...prevresetPasswordState,
                    passwordError: "At least one character and one digit and one special character",
                    confirmPasswordError: ''
                }));
                return false;
            }

            if (password.startsWith(" ") || password.endsWith(" ")) {
                setResetPassword(prevresetPasswordState => ({
                    ...prevresetPasswordState,
                    passwordError: "Your password cannot start or end with a blank space",
                    confirmPasswordError: ''
                }));
            }

        }

        if (name === 'confirmPassword' || name === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(confirmPassword)) {
                setResetPassword(prevresetPasswordState => ({
                    ...prevresetPasswordState,
                    confirmPasswordError: "Please re-enter your password"
                }));
                return false;
            }
            if (isEmpty(password)) {
                setResetPassword(prevresetPasswordState => ({
                    ...prevresetPasswordState,
                    confirmPasswordError: "Please enter your password first"
                }));
            }
            if (!isEmpty(password)) {
                if (confirmPassword !== password) {
                    setResetPassword(prevresetPasswordState => ({
                        ...prevresetPasswordState,
                        confirmPasswordError: "Password is not matching"
                    }));

                    return false;
                }
            }
        }
        return true;
    };

    const onBlurInputText = (name) => {
        validateChanges(name);
    };

    return (
        <ResetPasswordView
            {...props}
            resetPasswordState={resetPasswordState}
            handleResetPassword={handleResetPassword}
            onChangeFormData={onChangeFormData}
            clearFormData={clearFormData}
            onBlurInputText={onBlurInputText}
        />
    );
}

ResetPassword.propTypes = {};

const mapStateToProps = state => ({
    windowHeight: state.appState.deviceInfo.windowHeight,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(ResetPassword));
