import React, {useState} from 'react';
import {withRouter} from "react-router-dom";
import {setUserAuthStatus} from "../../userAuth/userReducer";
import {connect} from "react-redux";
import AppResAppBarView from "./AppResAppBarView";
import {logoutUser} from "../../userAuth/userActions";
import {resetAllHomePageFilterData} from "../../UserManagement/UserManagementAction";
import {resetAllFilter} from "../../products/productManagementAction";

function AppResAppBar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [settingAnchor, setSettingAnchor] = useState(null);
    const [anchorSettings, setAnchorSettings] = useState(false);
    const [anchorAquisitionSettings, setAnchorAquisitionSettings] = useState(false);

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };

    const handleSettingModelOpen = event => {
        setSettingAnchor(event.currentTarget);
    };

    const handleSettingModelClose = () => {
        setSettingAnchor(null);
    };

    const handleMenuItemsOpen = event => {
        setAnchorSettings(event.currentTarget);
    };

    const handleMenuItemsClose = () => {
        setAnchorSettings(null)
    };

    const handleMenuDocItemsOpen = event => {
        setAnchorAquisitionSettings(event.currentTarget);
    };

    const handleMenuDocItemsClose = () => {
        setAnchorAquisitionSettings(null)
    };

    const handleUserManagementClick = () => {
        resetAllHomePageFilterData();
        handleSettingModelClose();
        props.history.push('/users');
    };

    const handleProductManagementClick = () => {
        resetAllFilter();
        handleSettingModelClose();
        props.history.push('/products');
    };

    return (
        <AppResAppBarView
            {...props}
            anchorEl={anchorEl}
            settingAnchor={settingAnchor}
            anchorSettings={anchorSettings}
            anchorAquisitionSettings={anchorAquisitionSettings}
            isDeleteDialogOpen={false}
            handleSettingModelOpen={handleSettingModelOpen}
            handleSettingModelClose={handleSettingModelClose}
            handleItemOpen={handleItemOpen}
            handleUserManagementClick={handleUserManagementClick}
            handleProductManagementClick={handleProductManagementClick}
            handleItemClose={handleItemClose}
            handleMenuItemsOpen={handleMenuItemsOpen}
            handleMenuItemsClose={handleMenuItemsClose}
            handleMenuDocItemsOpen={handleMenuDocItemsOpen}
            handleMenuDocItemsClose={handleMenuDocItemsClose}
        />
    );
}

AppResAppBar.propTypes = {

};

const mapStateToProps = (state) => ({
    userAuthStatus: state.userState.userAuthStatus,
    profile:state.userState.profile,
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isDrawerOpen: state.appState.isDrawerOpen,
    currentPathname: state.appState.currentPathname,
    prevLocation: state.appState.prevLocation
});


export default connect(mapStateToProps, {
    setUserAuthStatus, logoutUser
}) (withRouter(AppResAppBar))
