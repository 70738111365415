import React from 'react';
import {roleSummary} from "../UserManagementModel";
import DLButton from "../../core/components/DLButton/DLButton";
import DLModal from "../../core/components/DLModal/DLModal";
import DLModalTitle from "../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../core/components/DLModal/DLModalActions/DLModalActions";
import DLRadioGroup from "../../core/components/DLRadioGroup/DLRadioGroup";
import {isEmpty} from "../../utils/validations";
import DLText from "../../core/components/DLText/DLText";

const AssignRoleView = (props) => {
    const {
        isAssignRoleModalOpen,
        assignUser,
        handleCloseModal,
        handleChange,
        roleName
    } = props;

    const renderErrorUI = () => {
        const {responseError} = props;

        if (isEmpty(responseError)) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className={'py-2'}>
                <div className='align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseError}
                    </div>
                </div>
            </div>
        );
    };

    const renderCreateTaskModal = () => {
        const {modelData} = props;
        let roleOptions = roleSummary;

        if (modelData.isTrainee) {
            roleOptions = roleOptions.filter(item => item.value !== 'QA')
        }

        return (
            <div>
                <DLModal
                    maxWidth={"xs"}
                    onClose={() => handleCloseModal("isAssignRoleModalOpen")}
                    open={isAssignRoleModalOpen}
                    id={'assign-modal'}>
                    <DLModalTitle
                        id={'assign-title'}
                        onClose={() => handleCloseModal("isAssignRoleModalOpen")}
                        title={'Assign Role'}/>
                    <DLModalContainer
                        id={'assign-crm-container'}>
                        <div>
                            {renderErrorUI()}
                            <div className='' style={{minHeight: '10px'}}>
                                <div className='py-2'>
                                    <DLText id={'delete-message'}
                                            text={`Please assign role for ${modelData.userName}`}
                                            fontSize={"sm"}
                                            fontWeight={"normal"}
                                    />
                                </div>
                                <DLRadioGroup id={'assign-radio-btn'}
                                              options={roleOptions}
                                              onChangeValue={(value) => {
                                                  handleChange('roleName', value)
                                              }}
                                              label={''}
                                              value={roleName}
                                              type={'block'}
                                              labelFontSize={'sm'}
                                              labelFontColor={'black'}
                                />
                            </div>

                        </div>
                    </DLModalContainer>
                    <DLModalActions id={'assign-action'}>
                        <div className='d-flex gap-3 mt-3 mb-3'>
                            <DLButton
                                id={'assign-cancel-btn'}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"none"}
                                onClick={() => handleCloseModal("isAssignRoleModalOpen")}/>
                            <DLButton
                                id={'assign-assign-btn'}
                                label={'Assign'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"none"}
                                onClick={() => assignUser()}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    return (
        <div>
            {renderCreateTaskModal()}
        </div>
    );
};

export default AssignRoleView;