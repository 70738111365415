import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DeleteRoleView from "./DeleteRoleView";
import store from "../../store";
import {showToastMessage} from "../../appComponents/AppActions";
import {deleteRoleData} from "../UserManagementAction";
import {isEmpty} from "../../utils/validations";
import {getLabelByValue} from "../../utils/helper";

const DeleteRole = (props) => {
    const [deleteRoleState, setDeleteRoleState] = useState({
        isShowLoader: false,
        responseError: '',
        roleValue: '',
        roleOptions: [],
        UIState: '',
        userRoleProductAssignmentId: ''
    });

    const {handleCloseDeleteModel, userDeletedModel, modelData} = props;

    useEffect(()=>{
        if (!isEmpty(modelData)){
            let roles = modelData?.roles.map((item)=>{
                return {
                    ...item,
                    label: item.name,
                    value: item.userRoleProductAssignmentId
                }
            });

            if (roles?.length === 1){
                setDeleteRoleState(prevState => ({
                    ...prevState,
                    roleOptions: roles,
                    roleValue: roles[0].userRoleProductAssignmentId
                }));
            }else {
                setDeleteRoleState(prevState => ({
                    ...prevState,
                    roleOptions: roles
                }));
            }
        }
    },[]);

    const deleteRole = () => {
        const {refreshFullScreen} = props;
        const {roleOptions, roleValue} = deleteRoleState;
        if (!modelData){
            return
        }

        setDeleteRoleState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            targetUserId: modelData.userId,
            userRoleProductAssignmentId: roleValue
        };
        let roleName = roleOptions.find(item =>  item.userRoleProductAssignmentId === roleValue);

        deleteRoleData(payload, true)
            .then(res => {
                if (res?.success) {
                    setDeleteRoleState(prevState => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                    store.dispatch(showToastMessage('success', `User role ${roleName.name} removed successfully`));
                    refreshFullScreen("isDeleteRoleModalOpen")
                } else {
                    setDeleteRoleState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseError: res?.__error
                    }));
                }
            })
    };

    const handleChange = (name, value) => {
        setDeleteRoleState((prevState) => ({
            ...prevState,
            [name]: value,
            responseError: ''
        }));
    };

    return (
        <DeleteRoleView
            {...props}
            {...deleteRoleState}
            handleCloseDeleteModel={handleCloseDeleteModel}
            userDeletedModel={userDeletedModel}
            deleteRole={deleteRole}
            handleChange={handleChange}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(DeleteRole);
