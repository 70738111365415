import React, {useState} from 'react';
import {connect} from "react-redux";
import AssignRoleView from "./AssignRoleView";
import {assignUserData} from "../UserManagementAction";
import store from "../../store";
import {showToastMessage} from "../../appComponents/AppActions";
import {isEmpty} from "../../utils/validations";
import {getLabelByValue} from "../../utils/helper";

const AssignRole = (props) => {
    const [assignRoleState, setAssignRoleState] = useState({
        isShowLoader: false,
        responseError: '',
        selectedCurator: '',
        roleName: '',
        openProduct: false
    });


    const {isCreateModalOpen, handleCloseModal, onCreate, radioValueAssign} = props;

    const handleChange = (name, value) => {
        setAssignRoleState((prevState) => ({
            ...prevState,
            [name]: value,
            responseError: ''
        }));
    };

    const assignUser = () => {
        const {roleName} = assignRoleState;
        const {refreshFullScreen, modelData, roleOptions} = props;
        if (!modelData){
            return
        }

        if (isEmpty(roleName)){
            setAssignRoleState(prevState => ({
                ...prevState,
                responseError: "Please select role"
            }));
            return;
        }

        setAssignRoleState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            targetUserId: modelData.userId,
            roleId : roleName,
        };

        assignUserData(payload, true)
            .then(res => {
                if (res?.success) {
                    setAssignRoleState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        roleName: ""
                    }));
                    store.dispatch(showToastMessage('success', `User ${modelData?.userName} has assgined role ${getLabelByValue(roleOptions, roleName)} successfully`));
                    refreshFullScreen("isAssignRoleModalOpen")
                } else {
                    setAssignRoleState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseError: res?.__error
                    }));
                }
            })
    };

    return (
        <AssignRoleView
            {...props}
            {...assignRoleState}
            isCreateModalOpen={isCreateModalOpen}
            onCreate={onCreate}
            handleChange={handleChange}
            handleCloseModal={handleCloseModal}
            radioValueAssign={radioValueAssign}
            assignUser={assignUser}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
});

export default connect(mapStateToProps, {})(AssignRole);